import * as actionTypes from './actionTypes';
import { collection, onSnapshot, addDoc, doc, updateDoc, query, getDoc, increment, getDocs, where, deleteDoc, Timestamp, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';

import { fetchUserCart } from '../../utils/FirebaseUtils';
import { validateStoreCompatibility } from '../../src/utils/cartUtils';
import { setActiveStore } from './storeActions';

const convertTimestamps = (obj) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach(key => {
    if (newObj[key] && typeof newObj[key] === 'object' && newObj[key].seconds) {
      newObj[key] = newObj[key].toDate().toISOString();
    } else if (typeof newObj[key] === 'object') {
      newObj[key] = convertTimestamps(newObj[key]);
    }
  });
  return newObj;
};

export const fetchCart = (userId) => async (dispatch) => {
  try {
    const cartRef = collection(db, 'users', userId, 'cart');
    const cartSnapshot = await getDocs(cartRef);
    const cartItems = cartSnapshot.docs.map(doc => 
      convertTimestamps({
        ...doc.data(),
        cartItemId: doc.id
      })
    );
    dispatch({
      type: actionTypes.SET_CART,
      payload: cartItems
    });
  } catch (error) {
    console.error("Error fetching cart:", error);
    dispatch({
      type: actionTypes.SET_CART,
      payload: []
    });
  }
};

export const fetchOrders = (userId) => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_ORDERS_START });
  try {
    const ordersRef = collection(db, 'orders');
    const q = query(ordersRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const orders = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...convertTimestamps(doc.data())
    }));
    dispatch({ type: actionTypes.FETCH_ORDERS_SUCCESS, payload: orders });
  } catch (error) {
    console.error('Error fetching orders:', error);
    dispatch({ type: actionTypes.FETCH_ORDERS_FAILURE, payload: error.message });
  }
};

export const addProductToCart = (product, confirmationShown = false) => async (dispatch, getState) => {
  const { user, order, store } = getState();
  if (!user?.userId) {
    console.error('No user ID found');
    return;
  }

  if (!product?.id || !product?.name) {
    console.error('Invalid product data');
    return;
  }

  // Get current cart
  const currentCart = order?.cart || [];
  
  // Validate store compatibility
  const validation = validateStoreCompatibility(product, currentCart, store);
  
  // If product is from a different store and confirmation hasn't been shown yet, prompt user
  if (!validation.isValid && !confirmationShown && !store.showedConfirmation) {
    // Return the validation info so the component can show a confirmation dialog
    return {
      needsConfirmation: true,
      validation,
      confirmCallback: () => {
        // This will be called if user confirms switching stores
        dispatch(addProductToCart(product, true));
      }
    };
  }

  const cartRef = collection(db, 'users', user.userId, 'cart');
  const cartSnapshot = await getDocs(cartRef);
  let subtotal = 0;
    
    if (cartSnapshot.empty) {
      subtotal = 0; // Return 0 if cart is empty
    }
    
    // Sum up (price * quantity) for each item
    subtotal = cartSnapshot.docs.reduce((total, doc) => {
      const item = doc.data();
      const price = item.discountedPrice || item.price || 0;
      const quantity = item.quantity || 0;
      return total + (price * quantity);
    }, 0);

    if (subtotal + (product.price * product.quantity) > 500) {
      alert('Your cart subtotal exceeds $500. Please remove some items to proceed.');
      return;
    }


  try {
    // If this is a new store (different from current cart), clear the cart first
    if (!validation.isValid) {      
      // Query all cart items
      const cartSnapshot = await getDocs(cartRef);
      
      // Delete each cart item
      const deletePromises = cartSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);
      
      // Clear cart in Redux
      dispatch({
        type: actionTypes.SET_CART,
        payload: []
      });
    }
    
    // Set the active store in Redux state
    const storeId = product.store_id || (product.store_data?.store_id);
    if (storeId) {
      dispatch(setActiveStore(storeId, true));
    }

    // Check if the product already exists in the cart
    const existingProductQuery = query(cartRef, where('id', '==', product.id));
    const existingProductSnapshot = await getDocs(existingProductQuery);

    if (!existingProductSnapshot.empty) {
      // If product exists, increment the quantity
      const existingDoc = existingProductSnapshot.docs[0];
      const existingQuantity = existingDoc.data().quantity || 0;
      const newQuantity = existingQuantity + 1;
      
      await updateDoc(doc(cartRef, existingDoc.id), { 
        quantity: newQuantity,
        updatedAt: serverTimestamp()
      });

      dispatch({
        type: actionTypes.UPDATE_CART_ITEM_QUANTITY,
        payload: { id: product.id, quantity: newQuantity },
      });
    } else {
      // Create a clean cart item object with all essential fields
      const cartItem = {
        id: product.id,
        store_id: product.store_id,
        name: product.name,
        brand: product.brand || '',
        category_type: product.category_type || '',
        categories: product.categories || [],
        classification: product.classification || '',
        price: product.price || (product.pricing?.price_sell || 0),
        discountedPrice: product.discountedPrice || product.pricing?.discounted_price || null,
        quantity: product.quantity || 1,
        imageUrl: product.imageUrl || '',
        store_data: {
          store_id: product.store_id,
          name: product.store_name || (product.store_data?.name || 'Unknown Store'),
          address: product.store_data?.address || {},
          settings: product.store_data?.settings || {}
        },
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };

      // Directly fetch the store-specific productId from Firebase
      try {
        
        if (storeId) {
          // Query for the document where canonicalKey matches the product.id
          const productsRef = collection(db, 'productsCatalog');
          const q = query(productsRef, where('canonicalKey', '==', product.id));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            // Get the first matching document
            const productDoc = querySnapshot.docs[0];
            const productDocId = productDoc.id;
            
            // Now get the store-specific document from the stores subcollection
            const storeDocRef = doc(db, 'productsCatalog', productDocId, 'stores', storeId);
            const storeDoc = await getDoc(storeDocRef);
            
            if (storeDoc.exists()) {
              const storeData = storeDoc.data();
              // Extract the Treez productId from the store document
              if (storeData.productId) {
                cartItem.product_id = storeData.productId;
              } else {
                cartItem.product_id = product.id;
              }
            } else {
              cartItem.product_id = product.id;
            }
          } else {
            cartItem.product_id = product.id;
          }
        } else {
          cartItem.product_id = product.id;
        }
      } catch (error) {
        console.error('Error fetching store-specific productId:', error);
        cartItem.product_id = product.id;
      }

      // Log the final product_id being stored

      const newCartItemRef = await addDoc(cartRef, cartItem);
      
      // Initialize item with ID
      const newCartItem = {
        ...cartItem,
        cartItemId: newCartItemRef.id,
      };
      
      // Add to cart in Redux
      dispatch({
        type: actionTypes.ADD_PRODUCT_TO_CART,
        payload: newCartItem
      });
    }

    return {
      success: true,
      cartUpdate: 'Item added to cart successfully'
    };
  } catch (error) {
    console.error('Error adding product to cart:', error);
    return {
      success: false,
      error: error.message
    };
  }
};

export const removeFromCart = (userId, productId) => async (dispatch) => {
  try {
    const cartRef = collection(db, 'users', userId, 'cart');
    const q = query(cartRef, where('id', '==', productId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const cartItemDoc = querySnapshot.docs[0];
      await deleteDoc(cartItemDoc.ref);

      dispatch({
        type: actionTypes.REMOVE_PRODUCT_FROM_CART,
        payload: { id: productId },
      });
    } else {
      console.error('Cart item not found');
    }
  } catch (error) {
    console.error('Error removing item from cart:', error);
  }
};

export const createOrder = (orderData, ticketResponse, directPurchaseItems) => async (dispatch, getState) => {
  try {
    const { user } = getState();
    // If directPurchaseItems is provided, use that instead of the Redux state
    // This allows us to pass the proper buyNowItem with the correct Treez product ID
    const itemsToProcess = directPurchaseItems || 
      (orderData.isDirectPurchase ? 
        // Use buyNow state from Redux for direct purchases
        [{ ...getState().order.buyNow.product, quantity: getState().order.buyNow.quantity }] : 
        getState().order.cart);

    // Tax rates
    const TAX_RATES = {
      laCannabisRate: 0.10,
      caExciseRate: 0.15,
      salesTaxRate: 0.0975
    };

    // Process items with detailed pricing
    const itemsWithDetails = itemsToProcess.map(item => {
      // Get the appropriate price based on whether this is a direct purchase or cart item
      const price = orderData.isDirectPurchase ? 
        (item.discountedPrice || item.price || 0) : 
        (item.discountedPrice || item.price || 0);
        
      const regularPrice = orderData.isDirectPurchase ? 
        (item.price || price) : 
        (item.regularPrice || item.price || price);
        
      // Convert to cents for consistency
      const priceInCents = Math.round(price * 100);
      const regularPriceInCents = Math.round(regularPrice * 100);
      const quantity = item.quantity;
      const itemSubtotal = priceInCents * quantity;
      
      // Calculate savings for this item
      const itemSavings = regularPriceInCents > priceInCents
          ? (regularPriceInCents - priceInCents) * quantity
          : 0;
      
      // Calculate taxes for this item
      const laCannabisCity = Math.round(itemSubtotal * TAX_RATES.laCannabisRate);
      const caExcise = Math.round(itemSubtotal * TAX_RATES.caExciseRate);
      const salesTax = Math.round(itemSubtotal * TAX_RATES.salesTaxRate);
      const itemTotalTax = laCannabisCity + caExcise + salesTax;

      return {
        productId: orderData.isDirectPurchase ? item.id : item.id,
        quantity: quantity,
        pricePerUnit: {
          amount: priceInCents,
          regularAmount: regularPriceInCents,
          currency: "USD"
        },
        savings: itemSavings,
        taxes: {
          laCannabisCity,
          caExcise,
          salesTax,
          totalTax: itemTotalTax
        },
        itemSubtotal,
        itemTotal: itemSubtotal + itemTotalTax
      };
    });

    // Calculate order totals
    const orderTotals = itemsWithDetails.reduce((acc, item) => {
      acc.subtotal += item.itemSubtotal;
      acc.savings += item.savings;  
      acc.taxes.laCannabisCity += item.taxes.laCannabisCity;
      acc.taxes.caExcise += item.taxes.caExcise;
      acc.taxes.salesTax += item.taxes.salesTax;
      acc.taxes.totalTax += item.taxes.totalTax;
      return acc;
    }, {
      subtotal: 0,
      savings: 0,  
      taxes: {
        laCannabisCity: 0,
        caExcise: 0,
        salesTax: 0,
        totalTax: 0
      },
      currency: "USD"
    });

    orderTotals.total = orderTotals.subtotal + orderTotals.taxes.totalTax;

    const firestoreOrderData = {
      userId: user.userId,
      orderStatus: "VERIFICATION_PENDING",
      orderTime: Timestamp.now(),
      paymentStatus: "VERIFICATION_PENDING",
      items: itemsWithDetails.reduce((acc, item, index) => {
        acc[index.toString()] = item;
        return acc;
      }, {}),
      totals: orderTotals,
      ticketData: {
        ...orderData.ticketData,
      },
      treezOrderNumber: ticketResponse?.data?.order_number || '',
      treezOrderStatus: ticketResponse?.data?.order_status || "PREVIEW",
      treezPaymentStatus: ticketResponse?.data?.payment_status || "UNPAID",
      treezTicketId: ticketResponse?.data?.ticket_id || null,
      store_data: {
          store_id: itemsToProcess[0]?.store_id || 'JlezFXhoNl4lVpibJZDx',
      },
      // Add AeroPay transaction data if available
      paymentMethod: orderData.paymentMethod || 'TREEZ',
      isTestOrder: orderData.isTestOrder || false,
      aeroPayTransaction: orderData.aeroPayTransaction ? {
        id: orderData.aeroPayTransaction.id,
        status: orderData.aeroPayTransaction.status,
        created: orderData.aeroPayTransaction.created,
      } : null,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    };

    const docRef = await addDoc(collection(db, 'orders'), firestoreOrderData);

    // Add reference to user's orders subcollection
    await setDoc(doc(db, 'users', user.userId, 'orders', docRef.id), { orderId: docRef.id });

    dispatch({
      type: actionTypes.CREATE_ORDER,
      payload: {
        ...firestoreOrderData,
        id: docRef.id,
      },
    });

    return docRef.id;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const updateCartItemQuantity = (cartItemId, productId, newQuantity) => async (dispatch, getState) => {
  const { user } = getState();
  
  try {
    // First, query to find the correct cart item document
    const cartRef = collection(db, 'users', user.userId, 'cart');
    const q = query(cartRef, where('id', '==', productId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const cartItemDoc = querySnapshot.docs[0];
      await updateDoc(cartItemDoc.ref, { quantity: newQuantity });

      dispatch({
        type: actionTypes.UPDATE_CART_ITEM_QUANTITY,
        payload: { id: productId, cartItemId: cartItemDoc.id, quantity: newQuantity },
      });
    } else {
      console.error('Cart item not found');
    }
  } catch (error) {
    console.error('Error updating cart item quantity:', error);
  }
};

// Buy Now Actions
export const setBuyNowProduct = (product, quantity = 1) => ({
  type: actionTypes.SET_BUY_NOW_PRODUCT,
  payload: {
    product,
    quantity
  }
});

export const updateBuyNowQuantity = (quantity) => ({
  type: actionTypes.UPDATE_BUY_NOW_QUANTITY,
  payload: { quantity }
});

export const clearBuyNow = () => ({
  type: actionTypes.CLEAR_BUY_NOW
});

// Define other actionTypes as needed.
