import * as actionTypes from "./actionTypes";
import { loadAllProducts } from "../../src/utils/productCatalogLoader";

// Action Creators
export const setProducts = (products) => ({
  type: actionTypes.SET_PRODUCTS,
  payload: products
});

export const addProducts = (products) => ({
  type: actionTypes.ADD_PRODUCTS,
  payload: products
});

export const updateProduct = (product) => ({
  type: actionTypes.UPDATE_PRODUCT,
  payload: product
});

export const setProductActionsLoading = (isLoading) => ({
  type: actionTypes.SET_PRODUCT_ACTIONS_LOADING,
  payload: isLoading
});

export const setProductActionsError = (error) => ({
  type: actionTypes.SET_PRODUCT_ACTIONS_ERROR,
  payload: error
});

// Thunk action for loading all products
export const fetchProducts = () => async (dispatch, getState) => {
  try {
    dispatch(setProductActionsLoading(true));
    
    // Get cached stores from Redux state
    const cachedStores = getState().store.cachedStores || {};
    
    const { products, total } = await loadAllProducts(cachedStores);
    
    if (products && products.length > 0) {
      
      dispatch(setProducts(products));
    } else {
      dispatch(setProductActionsError('No products found'));
    }
    
    dispatch(setProductActionsLoading(false));
    
    return { products, total };
  } catch (error) {
    dispatch(setProductActionsError(error.message || 'Failed to fetch products'));
    dispatch(setProductActionsLoading(false));
    return { products: [], total: 0 };
  }
};

// Thunk action for refreshing product data from source (bypasses caches)
export const refreshProducts = () => async (dispatch) => {
  try {
    dispatch(setProductActionsLoading(true));
    
    // Pass forceRefresh=true to force a fresh fetch from the CDN
    const { products } = await loadAllProducts(1, 1000, true);
    
    if (products && products.length > 0) {
      dispatch(setProducts(products));
    } else {
      dispatch(setProductActionsError('No products found during refresh'));
    }
    
    dispatch(setProductActionsLoading(false));
    return products;
  } catch (error) {
    dispatch(setProductActionsError(error.message || 'Failed to refresh products'));
    dispatch(setProductActionsLoading(false));
    return null;
  }
};

// Specific product loader for a single product
export const fetchProductById = (productId) => async (dispatch) => {
  if (!productId) {
    return null;
  }
  
  try {
    dispatch(setProductActionsLoading(true));
    
    // Load all products via the existing loader
    // In the future, we could create a specialized single product loader
    const { products } = await loadAllProducts();
    
    // Find the specific product we're looking for
    const product = products.find(p => p.id === productId);
    
    if (product) {
      dispatch(updateProduct(product));
    } else {
      dispatch(setProductActionsError(`Product with ID ${productId} not found`));
    }
    
    dispatch(setProductActionsLoading(false));
    return product;
  } catch (error) {
    console.error(`Error fetching product with ID ${productId}:`, error);
    dispatch(setProductActionsError(error.message || `Failed to load product with ID ${productId}`));
    dispatch(setProductActionsLoading(false));
    return null;
  }
};
