// redux/reducers/aeropayReducer.js
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  transactions: {},
  subscriptions: [],
  activeSubscriptions: [],
  userEnrollments: [],
  subscriptionStatus: {
    isSubscribed: false,
    lastChecked: null,
    enrollments: []
  },
  loading: false,
  subscriptionLoading: false,
  error: null,
  subscriptionError: null
};

export default function aeropayReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_AEROPAY_TRANSACTION:
      return {
        ...state,
        loading: true,
        error: null
      };

    case actionTypes.CREATE_AEROPAY_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: {
          ...state.transactions,
          [action.payload.transactionId]: {
            status: action.payload.status
          }
        }
      };

    case actionTypes.CREATE_AEROPAY_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case actionTypes.UPDATE_AEROPAY_TRANSACTION_STATUS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [action.payload.transactionId]: {
            ...state.transactions[action.payload.transactionId],
            status: action.payload.status
          }
        }
      };

    // Subscription Actions
    case actionTypes.CREATE_AEROPAY_SUBSCRIPTION_REQUEST:
    case actionTypes.GET_AEROPAY_SUBSCRIPTION_REQUEST:
    case actionTypes.LIST_AEROPAY_SUBSCRIPTIONS_REQUEST:
    case actionTypes.UPDATE_AEROPAY_SUBSCRIPTION_REQUEST:
    case actionTypes.CANCEL_AEROPAY_SUBSCRIPTION_REQUEST:
    case actionTypes.CHECK_SUBSCRIPTION_STATUS_REQUEST:
      return {
        ...state,
        subscriptionLoading: true,
        subscriptionError: null
      };

    case actionTypes.ENROLL_AEROPAY_SUBSCRIPTION_REQUEST:
    case actionTypes.CANCEL_AEROPAY_ENROLLMENT_REQUEST:
    case actionTypes.LIST_AEROPAY_ENROLLMENTS_REQUEST:
      return {
        ...state,
        subscriptionLoading: true,
        subscriptionError: null
      };

    case actionTypes.CREATE_AEROPAY_SUBSCRIPTION_FAILURE:
    case actionTypes.GET_AEROPAY_SUBSCRIPTION_FAILURE:
    case actionTypes.LIST_AEROPAY_SUBSCRIPTIONS_FAILURE:
    case actionTypes.UPDATE_AEROPAY_SUBSCRIPTION_FAILURE:
    case actionTypes.CANCEL_AEROPAY_SUBSCRIPTION_FAILURE:
    case actionTypes.ENROLL_AEROPAY_SUBSCRIPTION_FAILURE:
    case actionTypes.CANCEL_AEROPAY_ENROLLMENT_FAILURE:
    case actionTypes.LIST_AEROPAY_ENROLLMENTS_FAILURE:
    case actionTypes.CHECK_SUBSCRIPTION_STATUS_FAILURE:
      return {
        ...state,
        subscriptionLoading: false,
        subscriptionError: action.payload
      };

    case actionTypes.CREATE_AEROPAY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        subscriptions: [...state.subscriptions, action.payload]
      };

    case actionTypes.GET_AEROPAY_SUBSCRIPTION_SUCCESS:
      // Replace the subscription if it exists, otherwise add it
      return {
        ...state,
        subscriptionLoading: false,
        subscriptions: state.subscriptions.some(sub => sub.id === action.payload.id)
          ? state.subscriptions.map(sub => 
              sub.id === action.payload.id ? action.payload : sub
            )
          : [...state.subscriptions, action.payload]
      };

    case actionTypes.LIST_AEROPAY_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        subscriptions: action.payload
      };

    case actionTypes.UPDATE_AEROPAY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        subscriptions: state.subscriptions.map(subscription => 
          subscription.id === action.payload.id 
            ? action.payload
            : subscription
        )
      };

    case actionTypes.CANCEL_AEROPAY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        subscriptions: state.subscriptions.map(subscription => 
          subscription.id === action.payload.subscriptionId
            ? { ...subscription, status: 'canceled' }
            : subscription
        )
      };

    case actionTypes.ENROLL_AEROPAY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        subscriptionError: null,
        userEnrollments: [...state.userEnrollments, action.payload],
        subscriptionStatus: {
          ...state.subscriptionStatus,
          isSubscribed: true, 
          lastChecked: Date.now(),
          status: 'active',
          planName: 'Premium Subscription'
        }
      };

    case actionTypes.CANCEL_AEROPAY_ENROLLMENT_SUCCESS:
      // When an enrollment is canceled, update its status in the enrollments list
      // Also update the subscription status if it was the active one
      const updatedEnrollments = state.userEnrollments.map(enrollment => 
        enrollment.id === action.payload.enrollmentId
          ? { ...enrollment, status: 'canceled' }
          : enrollment
      );
      
      // Check if we still have any active enrollments after cancellation
      const stillHasActiveEnrollment = updatedEnrollments.some(
        enrollment => enrollment.status === 'active'
      );
      
      return {
        ...state,
        subscriptionLoading: false,
        userEnrollments: updatedEnrollments,
        // Update subscription status if the active one was canceled
        subscriptionStatus: stillHasActiveEnrollment 
          ? state.subscriptionStatus 
          : {
              ...state.subscriptionStatus,
              isSubscribed: false,
              status: 'inactive',
              lastChecked: Date.now()
            }
      };

    case actionTypes.LIST_AEROPAY_ENROLLMENTS_SUCCESS:
      // Extract active subscription status from enrollments
      const hasActiveSubscription = action.payload.some(
        enrollment => enrollment.status === 'active'
      );
      
      // Find the active subscription details
      const activeSubscription = hasActiveSubscription
        ? action.payload.find(enrollment => enrollment.status === 'active')
        : null;
      
      return {
        ...state,
        subscriptionLoading: false,
        userEnrollments: action.payload,
        subscriptionStatus: {
          isSubscribed: hasActiveSubscription,
          lastChecked: Date.now(),
          enrollments: action.payload,
          planName: activeSubscription?.subscription?.name || 'Premium Subscription',
          planId: activeSubscription?.subscription?.id,
          status: hasActiveSubscription ? 'active' : 'inactive',
          subscribedUntil: activeSubscription?.billing?.nextBillingDate
        }
      };

    // Handle explicit checks for subscription status
    // Both when checking directly and when coming from user data
    case actionTypes.SET_USER_SUBSCRIPTION_STATUS:
    case actionTypes.CHECK_SUBSCRIPTION_STATUS_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        subscriptionStatus: {
          isSubscribed: action.payload.isSubscribed || false,
          lastChecked: action.payload.lastChecked || Date.now(),
          planName: action.payload.planName || 'Premium Subscription',
          planId: action.payload.planId,
          status: action.payload.status || 'unknown',
          subscribedUntil: action.payload.subscribedUntil
        }
      };

    default:
      return state;
  }
}