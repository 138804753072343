import React, { useState, useEffect } from 'react';
import { IonIcon } from '@ionic/react';
import { checkmarkCircleOutline, ellipseOutline, chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

const FilterGroup = ({
  type,
  options = [],
  selectedValues = [],
  onChange,
  label,
  supportSelectAll = false,
  isDropdown = false
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Default to all selected for brands on initial render only
  useEffect(() => {
    if (type === 'brands' && options.length > 0 && selectedValues.length === 0) {
      onChange([...options]);
    }
  }, [type, options.length]); // Only run when options change, not when selectedValues change

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCheckboxChange = (value, e) => {
    // Prevent the event from bubbling up to parent elements
    if (e) {
      e.stopPropagation();
    }
    
    let newValues;
    if (selectedValues.includes(value)) {
      newValues = selectedValues.filter(v => v !== value);
    } else {
      newValues = [...selectedValues, value];
    }
    onChange(newValues);
  };

  const handleSelectAll = (e) => {
    // Prevent the event from bubbling up
    if (e) {
      e.stopPropagation();
    }
    
    onChange([...filteredOptions]);
  };

  const handleSelectNone = (e) => {
    // Prevent the event from bubbling up
    if (e) {
      e.stopPropagation();
    }
    
    onChange([]);
  };

  const filteredOptions = options.filter(option => 
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const allSelected = filteredOptions.length > 0 && selectedValues.length === filteredOptions.length;
  const noneSelected = selectedValues.length === 0;

  // If we have too many options, show search
  const showSearch = options.length > 5;

  return (
    <div className={`filter-group ${isCollapsed ? 'collapsed' : ''}`} data-filter-type={type}>
      <div className="filter-group-header" onClick={isDropdown ? null : toggleCollapse}>
        <h3 className="filter-group-title">{label}</h3>
        {!isDropdown && (
          <div className="filter-group-actions">
            {supportSelectAll && (
              <div className="quick-select-bubbles">
                <div 
                  className={`quick-select-bubble ${allSelected ? 'active' : ''}`}
                  onClick={(e) => handleSelectAll(e)}
                >
                  All
                </div>
                <div 
                  className={`quick-select-bubble ${noneSelected ? 'active' : ''}`}
                  onClick={(e) => handleSelectNone(e)}
                >
                  None
                </div>
              </div>
            )}
            <IonIcon 
              icon={isCollapsed ? chevronDownOutline : chevronUpOutline} 
              className="filter-group-collapse-icon"
            />
          </div>
        )}
        {isDropdown && supportSelectAll && (
          <div className="quick-select-bubbles">
            <div 
              className={`quick-select-bubble ${allSelected ? 'active' : ''}`}
              onClick={(e) => handleSelectAll(e)}
            >
              All
            </div>
            <div 
              className={`quick-select-bubble ${noneSelected ? 'active' : ''}`}
              onClick={(e) => handleSelectNone(e)}
            >
              None
            </div>
          </div>
        )}
      </div>
      {showSearch && !isCollapsed && (
        <div className="filter-search">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
      {!isCollapsed && (
        <div className="filter-group-items">
          {filteredOptions.map(option => (
            <div 
              key={option} 
              className={`filter-item ${selectedValues.includes(option) ? 'selected' : ''}`}
              onClick={(e) => handleCheckboxChange(option, e)}
            >
              <div className="filter-checkbox">
                <IonIcon 
                  icon={selectedValues.includes(option) ? checkmarkCircleOutline : ellipseOutline}
                  className={`checkbox-icon ${selectedValues.includes(option) ? 'selected' : ''}`}
                />
              </div>
              <div className="filter-label">{option}</div>
            </div>
          ))}
          {filteredOptions.length === 0 && (
            <div className="no-results">No options found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterGroup;