// Store action types
export const SET_ACTIVE_STORE = 'SET_ACTIVE_STORE';
export const CLEAR_ACTIVE_STORE = 'CLEAR_ACTIVE_STORE';
export const CACHE_STORE_DATA = 'CACHE_STORE_DATA';
export const CLEAR_STORE_CACHE = 'CLEAR_STORE_CACHE';
export const SET_PRODUCT_STORES = 'SET_PRODUCT_STORES';
export const SET_BEST_PRICE_STORE = 'SET_BEST_PRICE_STORE';

/**
 * Set the active store for the user session
 * @param {string} storeId - The ID of the store to set as active
 * @param {boolean} [showConfirmation=false] - Whether a confirmation was shown to the user
 * @returns {Object} Action object
 */
export const setActiveStore = (storeId, showConfirmation = false) => ({
  type: SET_ACTIVE_STORE,
  payload: {
    storeId,
    showConfirmation
  }
});

/**
 * Clear the active store selection
 * @returns {Object} Action object
 */
export const clearActiveStore = () => ({
  type: CLEAR_ACTIVE_STORE
});

/**
 * Cache store data to improve performance
 * @param {string} storeId - The ID of the store to cache
 * @param {Object} storeData - The store data to cache
 * @returns {Object} Action object
 */
export const cacheStoreData = (storeId, storeData) => ({
  type: CACHE_STORE_DATA,
  payload: {
    storeId,
    storeData
  }
});

/**
 * Clear the store data cache
 * @returns {Object} Action object
 */
export const clearStoreCache = () => ({
  type: CLEAR_STORE_CACHE
});

/**
 * Set available stores for a product
 * @param {string} productId - The ID of the product
 * @param {Array} stores - List of stores that carry this product
 * @returns {Object} Action object
 */
export const setProductStores = (productId, stores) => ({
  type: SET_PRODUCT_STORES,
  payload: {
    productId,
    stores
  }
});

/**
 * Set the best price store for a product
 * @param {string} productId - The ID of the product
 * @param {Object} store - The store with the best price
 * @returns {Object} Action object
 */
export const setBestPriceStore = (productId, store) => ({
  type: SET_BEST_PRICE_STORE,
  payload: {
    productId,
    store
  }
});