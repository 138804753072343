import * as actionTypes from '../actions/actionTypes';

const initialState = {
  email: '',
  userId: '',
  role: '',
  accountName: '',
  legalName: '',
  birthday: '',
  purchaseStatus: '',
  idType: '',
  licenseState: '',
  idNumber: '',
  phoneNumber: '',
  phoneVerified: false,
  photoUrl: '',
  createdAt: null,
  lastUpdatedAt: null,
  aeroPay: {
    bankAccounts: {},
    aeroPayBankLinked: false,
    aeroPayUserId: '',
    aeroPayBankLinkError: null,
    defaultBankAccountId: '',
    aeroPayUserStatus: 'active' // Default to active if not specified
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      // Check if we have aeroPay data to load
      const aeroPay = action.payload?.aeroPay || {};
      
      return {
        ...state,
        ...action.payload,
        // Ensure aeroPay structure is completely preserved with all fields
        aeroPay: {
          ...state.aeroPay,
          ...aeroPay,  // This will include defaultBankAccountId if present
          // Make sure bankAccounts get loaded properly if they exist
          bankAccounts: {
            ...(state.aeroPay?.bankAccounts || {}),
            ...(aeroPay?.bankAccounts || {})
          }
        }
      };
    case actionTypes.CLEAR_USER:
      return initialState;
    case actionTypes.CREATE_AEROPAY_USER_SUCCESS:
      return {
        ...state,
        aeroPay: {
          ...state.aeroPay,
          aeroPayUserId: action.payload.aeroPayUserId,
          aeroPayBankLinkError: null
        }
      };
    case actionTypes.LINK_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        aeroPay: {
          ...state.aeroPay,
          aeroPayBankLinked: true,
          aeroPayBankLinkError: null,
          // Preserve defaultBankAccountId if it exists in payload or state
          defaultBankAccountId: action.payload.defaultBankAccountId || state.aeroPay.defaultBankAccountId,
          bankAccounts: {
            ...state.aeroPay.bankAccounts,
            [action.payload.bankAccountId]: action.payload
          }
        }
      };
    case actionTypes.UPDATE_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
        phoneVerified: false // Reset verified status when number changes
      };
        
    case actionTypes.SET_PHONE_VERIFIED:
      return {
        ...state,
        phoneVerified: action.payload.status,
        phoneNumber: action.payload.phoneNumber || state.phoneNumber
      };
    case actionTypes.SET_DEFAULT_BANK_ACCOUNT:
      return {
        ...state,
        aeroPay: {
          ...state.aeroPay,
          defaultBankAccountId: action.payload.defaultBankAccountId
        }
      };
    case actionTypes.REMOVE_BANK_ACCOUNT_SUCCESS:
      // Create a copy of bank accounts without the removed account
      const updatedBankAccounts = { ...state.aeroPay.bankAccounts };
      delete updatedBankAccounts[action.payload.accountId];
      
      // If we're removing the default bank account, set a new default if any accounts remain
      let defaultBankAccountId = state.aeroPay.defaultBankAccountId;
      if (defaultBankAccountId === action.payload.accountId) {
        // Get the first remaining account ID if exists, otherwise empty string
        const remainingAccountIds = Object.keys(updatedBankAccounts);
        defaultBankAccountId = remainingAccountIds.length > 0 ? remainingAccountIds[0] : '';
      }
      
      return {
        ...state,
        aeroPay: {
          ...state.aeroPay,
          bankAccounts: updatedBankAccounts,
          defaultBankAccountId,
          // If no accounts left, set aeroPayBankLinked to false
          aeroPayBankLinked: Object.keys(updatedBankAccounts).length > 0
        }
      };
    case actionTypes.SET_AEROPAY_BANKS:
      // Handle our new payload format with banks and timestamp
      const { banks = [], timestamp } = action.payload || {};
      const bankAccountsMap = {};
      
      // Convert array to object keyed by bankAccountId
      if (Array.isArray(banks)) {
        banks.forEach(bank => {
          // Mark bank as default if it matches the default account ID
          const isDefault = bank.bankAccountId === state.aeroPay?.defaultBankAccountId;
          bankAccountsMap[bank.bankAccountId] = {
            ...bank,
            isDefault: isDefault
          };
        });
      }
      
      return {
        ...state,
        aeroPay: {
          ...state.aeroPay,
          bankAccounts: bankAccountsMap,
          aeroPayBankLinked: banks.length > 0,
          lastBanksCheckTimestamp: timestamp
        }
      };
    default:
      return state;
  }
};

export default userReducer;
