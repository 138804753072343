export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const CREATE_USER_DOCUMENT_SUCCESS = 'CREATE_USER_DOCUMENT_SUCCESS';
export const CREATE_USER_DOCUMENT_FAIL = 'CREATE_USER_DOCUMENT_FAIL';

export const SET_CART = 'SET_CART';
export const UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const CART_ERROR = 'CART_ERROR';

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

export const SET_PRODUCT_ACTIONS_LOADING = 'SET_PRODUCT_ACTIONS_LOADING';
export const SET_PRODUCT_ACTIONS_ERROR = 'SET_PRODUCT_ACTIONS_ERROR';

// AeroPay Action Types
export const CREATE_AEROPAY_USER_REQUEST = 'CREATE_AEROPAY_USER_REQUEST';
export const CREATE_AEROPAY_USER_SUCCESS = 'CREATE_AEROPAY_USER_SUCCESS';
export const CREATE_AEROPAY_USER_FAILURE = 'CREATE_AEROPAY_USER_FAILURE';
export const CREATE_AEROPAY_USER_FAIL = 'CREATE_AEROPAY_USER_FAIL';

// Buy Now Action Types
export const SET_BUY_NOW_PRODUCT = 'SET_BUY_NOW_PRODUCT';
export const UPDATE_BUY_NOW_QUANTITY = 'UPDATE_BUY_NOW_QUANTITY';
export const CLEAR_BUY_NOW = 'CLEAR_BUY_NOW';

export const CONFIRM_AEROPAY_USER_REQUEST = 'CONFIRM_AEROPAY_USER_REQUEST';
export const CONFIRM_AEROPAY_USER_SUCCESS = 'CONFIRM_AEROPAY_USER_SUCCESS';
export const CONFIRM_AEROPAY_USER_FAILURE = 'CONFIRM_AEROPAY_USER_FAILURE';

export const UNVERIFY_AEROPAY_USER_START = 'UNVERIFY_AEROPAY_USER_START';
export const UNVERIFY_AEROPAY_USER_SUCCESS = 'UNVERIFY_AEROPAY_USER_SUCCESS';
export const UNVERIFY_AEROPAY_USER_ERROR = 'UNVERIFY_AEROPAY_USER_ERROR';

export const LINK_BANK_ACCOUNT_REQUEST = 'LINK_BANK_ACCOUNT_REQUEST';
export const LINK_BANK_ACCOUNT_SUCCESS = 'LINK_BANK_ACCOUNT_SUCCESS';
export const LINK_BANK_ACCOUNT_FAILURE = 'LINK_BANK_ACCOUNT_FAILURE';
export const LINK_BANK_ACCOUNT_FAIL = 'LINK_BANK_ACCOUNT_FAIL';

export const FETCH_BANKS_REQUEST = 'FETCH_BANKS_REQUEST';
export const FETCH_BANKS_SUCCESS = 'FETCH_BANKS_SUCCESS';
export const FETCH_BANKS_FAILURE = 'FETCH_BANKS_FAILURE';
export const SET_AEROPAY_BANKS = 'SET_AEROPAY_BANKS';

export const SET_DEFAULT_BANK_REQUEST = 'SET_DEFAULT_BANK_REQUEST';
export const SET_DEFAULT_BANK_ACCOUNT = 'SET_DEFAULT_BANK_ACCOUNT';
export const SET_DEFAULT_BANK_FAILURE = 'SET_DEFAULT_BANK_FAILURE';

export const REMOVE_BANK_ACCOUNT_REQUEST = 'REMOVE_BANK_ACCOUNT_REQUEST';
export const REMOVE_BANK_ACCOUNT_SUCCESS = 'REMOVE_BANK_ACCOUNT_SUCCESS';
export const REMOVE_BANK_ACCOUNT_FAILURE = 'REMOVE_BANK_ACCOUNT_FAILURE';

export const CHECK_AEROPAY_USER_STATUS_REQUEST = 'CHECK_AEROPAY_USER_STATUS_REQUEST';
export const CHECK_AEROPAY_USER_STATUS_SUCCESS = 'CHECK_AEROPAY_USER_STATUS_SUCCESS';
export const CHECK_AEROPAY_USER_STATUS_FAILURE = 'CHECK_AEROPAY_USER_STATUS_FAILURE';

// AeroPay transaction types
export const CREATE_AEROPAY_TRANSACTION = 'CREATE_AEROPAY_TRANSACTION';
export const CREATE_AEROPAY_TRANSACTION_SUCCESS = 'CREATE_AEROPAY_TRANSACTION_SUCCESS';
export const CREATE_AEROPAY_TRANSACTION_FAILURE = 'CREATE_AEROPAY_TRANSACTION_FAILURE';

// AeroPay payout types
export const PROCESS_AEROPAY_PAYOUTS = 'PROCESS_AEROPAY_PAYOUTS';
export const PROCESS_AEROPAY_PAYOUTS_SUCCESS = 'PROCESS_AEROPAY_PAYOUTS_SUCCESS';
export const PROCESS_AEROPAY_PAYOUTS_FAILURE = 'PROCESS_AEROPAY_PAYOUTS_FAILURE';

// AeroPay status update types
export const UPDATE_AEROPAY_TRANSACTION_STATUS = 'UPDATE_AEROPAY_TRANSACTION_STATUS';

// AeroPay Token refresh
export const REFRESH_AEROPAY_TOKEN_REQUEST = 'REFRESH_AEROPAY_TOKEN_REQUEST';
export const REFRESH_AEROPAY_TOKEN_SUCCESS = 'REFRESH_AEROPAY_TOKEN_SUCCESS';
export const REFRESH_AEROPAY_TOKEN_FAILURE = 'REFRESH_AEROPAY_TOKEN_FAILURE';

// AeroPay Subscription Types
export const CREATE_AEROPAY_SUBSCRIPTION_REQUEST = 'CREATE_AEROPAY_SUBSCRIPTION_REQUEST';
export const CREATE_AEROPAY_SUBSCRIPTION_SUCCESS = 'CREATE_AEROPAY_SUBSCRIPTION_SUCCESS';
export const CREATE_AEROPAY_SUBSCRIPTION_FAILURE = 'CREATE_AEROPAY_SUBSCRIPTION_FAILURE';

export const GET_AEROPAY_SUBSCRIPTION_REQUEST = 'GET_AEROPAY_SUBSCRIPTION_REQUEST';
export const GET_AEROPAY_SUBSCRIPTION_SUCCESS = 'GET_AEROPAY_SUBSCRIPTION_SUCCESS';
export const GET_AEROPAY_SUBSCRIPTION_FAILURE = 'GET_AEROPAY_SUBSCRIPTION_FAILURE';

export const LIST_AEROPAY_SUBSCRIPTIONS_REQUEST = 'LIST_AEROPAY_SUBSCRIPTIONS_REQUEST';
export const LIST_AEROPAY_SUBSCRIPTIONS_SUCCESS = 'LIST_AEROPAY_SUBSCRIPTIONS_SUCCESS';
export const LIST_AEROPAY_SUBSCRIPTIONS_FAILURE = 'LIST_AEROPAY_SUBSCRIPTIONS_FAILURE';

export const UPDATE_AEROPAY_SUBSCRIPTION_REQUEST = 'UPDATE_AEROPAY_SUBSCRIPTION_REQUEST';
export const UPDATE_AEROPAY_SUBSCRIPTION_SUCCESS = 'UPDATE_AEROPAY_SUBSCRIPTION_SUCCESS';
export const UPDATE_AEROPAY_SUBSCRIPTION_FAILURE = 'UPDATE_AEROPAY_SUBSCRIPTION_FAILURE';

export const CANCEL_AEROPAY_SUBSCRIPTION_REQUEST = 'CANCEL_AEROPAY_SUBSCRIPTION_REQUEST';
export const CANCEL_AEROPAY_SUBSCRIPTION_SUCCESS = 'CANCEL_AEROPAY_SUBSCRIPTION_SUCCESS';
export const CANCEL_AEROPAY_SUBSCRIPTION_FAILURE = 'CANCEL_AEROPAY_SUBSCRIPTION_FAILURE';

export const ENROLL_AEROPAY_SUBSCRIPTION_REQUEST = 'ENROLL_AEROPAY_SUBSCRIPTION_REQUEST';
export const ENROLL_AEROPAY_SUBSCRIPTION_SUCCESS = 'ENROLL_AEROPAY_SUBSCRIPTION_SUCCESS';
export const ENROLL_AEROPAY_SUBSCRIPTION_FAILURE = 'ENROLL_AEROPAY_SUBSCRIPTION_FAILURE';

export const CANCEL_AEROPAY_ENROLLMENT_REQUEST = 'CANCEL_AEROPAY_ENROLLMENT_REQUEST';
export const CANCEL_AEROPAY_ENROLLMENT_SUCCESS = 'CANCEL_AEROPAY_ENROLLMENT_SUCCESS';
export const CANCEL_AEROPAY_ENROLLMENT_FAILURE = 'CANCEL_AEROPAY_ENROLLMENT_FAILURE';

export const LIST_AEROPAY_ENROLLMENTS_REQUEST = 'LIST_AEROPAY_ENROLLMENTS_REQUEST';
export const LIST_AEROPAY_ENROLLMENTS_SUCCESS = 'LIST_AEROPAY_ENROLLMENTS_SUCCESS';
export const LIST_AEROPAY_ENROLLMENTS_FAILURE = 'LIST_AEROPAY_ENROLLMENTS_FAILURE';

// New action for setting user subscription status directly
export const SET_USER_SUBSCRIPTION_STATUS = 'SET_USER_SUBSCRIPTION_STATUS';

// Subscription status check actions
export const CHECK_SUBSCRIPTION_STATUS_REQUEST = 'CHECK_SUBSCRIPTION_STATUS_REQUEST';
export const CHECK_SUBSCRIPTION_STATUS_SUCCESS = 'CHECK_SUBSCRIPTION_STATUS_SUCCESS';
export const CHECK_SUBSCRIPTION_STATUS_FAILURE = 'CHECK_SUBSCRIPTION_STATUS_FAILURE';

// Twilio Action Types
export const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER';
export const SET_PHONE_VERIFIED = 'SET_PHONE_VERIFIED';