import ShopNowButton from "./ShopNowButton";
import "./SpotlightBanner.css";

const SpotlightBanner = () => {
  return (
    <div className="frame-wrapper7">
      <div className="frame-parent22">
        <img
          className="stars1"
          alt=""
          src="/stars-1-traced.svg"
        />
        <img
          className="stars2"
          alt=""
          src="/stars-1-traced.svg"
        />
        <div className="stash-pack-5-mystery-items-in-parent">
          <h1 className="stash-pack-5-container">
            <p className="stash-pack">
              <span className="stash-pack1">STASH PACKS</span>
            </p>
            <p className="mystery-items-in">
              <span className="highlight-number">5</span>
              <span> mystery items</span>
            </p>
            <p className="mystery-items-in">
              <span>for price of </span>
              <span className="highlight-number">1</span>
            </p>
          </h1>
          <ShopNowButton isSpotlight={true}/>
        </div>
        {/* <div className="frame">
          <div className="div15">DOPE DEALS</div>
        </div> */}
        <img
          className="photoroom-20240506-103707-2-icon"
          loading="lazy"
          alt=""
          src="/photoroom20240506-103707-2@2x.png"
        />
      </div>
    </div>
  );
};

export default SpotlightBanner;
