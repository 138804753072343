/**
 * Utility functions for handling cannabinoid data across the application
 */

// Determine if a product's cannabinoids should be displayed in mg or percentage
export const isMgBasedProduct = (productCategory) => {
  const mgBasedCategories = ['EDIBLE', 'EXTRACT', 'CONCENTRATE', 'TINCTURE'];
  return mgBasedCategories.includes(productCategory);
};

// Get display name for a cannabinoid key
export const getCannabinoidDisplayName = (key) => {
  const displayNames = {
    thc: 'THC',
    cbd: 'CBD',
    thcv: 'THCV',
    cbc: 'CBC',
    cbg: 'CBG',
    cbn: 'CBN',
    thca: 'THCA',
    delta9: 'Delta-9'
  };
  return displayNames[key] || key.toUpperCase();
};

// Get normalized cannabinoid value with proper formatting
export const getCannabinoidValue = (product, cannabinoidKey) => {
  if (!product) return { key: cannabinoidKey, value: 0, display: '0', unit: '%' };
  
  const isMgBased = isMgBasedProduct(product.category_type);
  const cannabinoid = product.cannabinoids?.[cannabinoidKey];
  
  let value = 0;
  
  // Handle special cases for THC and CBD which have fallbacks
  if (cannabinoidKey === 'thc') {
    value = isMgBased 
      ? (cannabinoid?.mg || product.thc || 0)
      : (cannabinoid?.percentage || 0);
  } else if (cannabinoidKey === 'cbd') {
    value = isMgBased 
      ? (cannabinoid?.mg || product.cbd || 0)
      : (cannabinoid?.percentage || 0);
  } else {
    value = isMgBased 
      ? (cannabinoid?.mg || 0)
      : (cannabinoid?.percentage || 0);
  }
  
  const parsedValue = parseFloat(value) || 0;
  
  return {
    key: cannabinoidKey,
    value: parsedValue,
    display: isMgBased 
      ? `${Math.round(parsedValue)}mg` 
      : `${parsedValue.toFixed(1)}%`,
    unit: isMgBased ? 'mg' : '%'
  };
};

// Get all cannabinoids for a product, sorted by value (highest to lowest)
export const getSortedCannabinoids = (product, limit = null) => {
  if (!product) return [];
  
  const cannabinoidKeys = ['thc', 'cbd', 'thcv', 'cbc', 'cbg', 'cbn', 'thca', 'delta9'];
  
  const cannabinoidValues = cannabinoidKeys
    .map(key => getCannabinoidValue(product, key))
    .filter(item => item.value > 0) // Filter out zero values
    .sort((a, b) => b.value - a.value); // Sort descending
  
  return limit ? cannabinoidValues.slice(0, limit) : cannabinoidValues;
};