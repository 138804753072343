// src/utils/formatUtils.js
/**
 * Converts unit of measure strings to display-friendly format
 * @param {string} uom - The unit of measure to format
 * @returns {string} - Formatted unit of measure
 */
export const formatUOM = (uom) => {
  if (!uom) return '';
  
  // Convert to uppercase for consistent matching
  const uomUpper = uom.toUpperCase();
  
  // Handle common unit conversions
  switch (uomUpper) {
    case 'GRAMS':
    case 'GRAM':
      return 'g';
    case 'MILLIGRAMS':
    case 'MILLIGRAM':
      return 'mg';
    case 'MILLILITERS':
    case 'MILLILITER':
      return 'ml';
    case 'OUNCES':
    case 'OUNCE':
    case 'FLUID OUNCES':
    case 'FLUID OUNCE':
      return 'oz';
    case 'POUNDS':
    case 'POUND':
      return 'lb';
    case 'EACH':
      return 'ea';
    case 'KILOGRAMS':
    case 'KILOGRAM':
      return 'kg';
    default:
      // Handle any other units or return as is but lowercase
      return uom.toLowerCase();
  }
};

// You can add more formatting utilities here as your app grows