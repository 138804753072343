import React, { useState, useEffect } from "react";
import { IonIcon } from '@ionic/react';
import { cardOutline, walletOutline, storefrontOutline, checkmarkCircleOutline, sparklesOutline } from 'ionicons/icons';

import AeroPayWidget from "./widgets/AeropayWidget";

import "./PurchasePaymentMethod.css";

const PurchasePaymentMethod = ({ user, onBankSelect, purpose = "payment" }) => {
    const title = purpose === "subscription" ? "Subscription Payment Method" : "Payment Method";
    
    // State to track window width for responsive design
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    // Update windowWidth when the window is resized
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleResize);
        
        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    // Check screen sizes based on StashItem breakpoints
    const isExtraSmallScreen = windowWidth <= 320;
    const isSmallScreen = windowWidth <= 375;
    const isMediumScreen = windowWidth <= 428;
    const isLargeScreen = windowWidth <= 768;
    
    // Responsive font sizes based on screen size
    const getFontSize = (base, small, smaller, smallest) => {
        if (isExtraSmallScreen) return smallest;
        if (isSmallScreen) return smaller;
        if (isMediumScreen) return small;
        return base;
    };
    
    // Responsive padding based on screen size
    const getPadding = () => {
        if (isExtraSmallScreen) return '10px';
        if (isSmallScreen) return '12px';
        if (isMediumScreen) return '14px';
        return '16px';
    };
    
    return (
        <div className="payment-method-wrapper" style={{ 
            width: '100%', 
            maxWidth: '100%',
            padding: getPadding(),
            boxSizing: 'border-box',
            overflow: 'hidden'
        }}> 
            <div className="payment-method-header-container">
                <IonIcon icon={walletOutline} className="payment-method-icon" style={{
                    fontSize: getFontSize('20px', '18px', '16px', '16px')
                }} />
                <h2 className="payment-method-header" style={{
                    fontSize: getFontSize('1.1em', '1em', '0.95em', '0.9em')
                }}>{title}</h2>
            </div>
            
            {/* Styled Pay at Store Option */}
            {/* <div className="aeropay-selected-method" style={{ 
                marginBottom: '15px',
                width: '100%'
            }}> */}
                <div className="aeropay-bank-card" style={{ 
                    border: '1px solid #e0e6ff',
                    borderRadius: '10px',
                    padding: isExtraSmallScreen ? '8px' : (isSmallScreen ? '10px' : '12px'),
                    backgroundColor: 'white',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                    width: '100%',
                    boxSizing: 'border-box',
                    marginTop: getFontSize('15px', '12px', '10px', '8px')
                }}>
                    <div className="aeropay-bank-info" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="aeropay-bank-logo-wrapper" style={{ 
                            backgroundColor: '#f0f8ff', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: isSmallScreen ? '36px' : '42px',
                            height: isSmallScreen ? '36px' : '42px',
                            borderRadius: '8px',
                            marginRight: isExtraSmallScreen ? '8px' : '12px',
                            flexShrink: 0
                        }}>
                            <IonIcon 
                                icon={storefrontOutline} 
                                style={{
                                    fontSize: getFontSize('24px', '22px', '20px', '18px'),
                                    color: '#1E3A8A'
                                }}
                            />
                        </div>
                        <div className="aeropay-bank-details" style={{ flexGrow: 1 }}>
                            <div className="aeropay-bank-name" style={{ 
                                fontWeight: '600', 
                                fontSize: getFontSize('16px', '15px', '14px', '13px'),
                                color: '#1E3A8A',
                                fontFamily: 'Outfit, sans-serif',
                                marginBottom: isSmallScreen ? '2px' : '4px'
                            }}>
                                Pay at Store
                            </div>
                            <div className="aeropay-account-details">
                                <span className="aeropay-account-type" style={{
                                    fontSize: getFontSize('14px', '13px', '12px', '11px'),
                                    color: '#666',
                                    fontFamily: 'Outfit, sans-serif'
                                }}>
                                    Cash, Debit or Credit Card
                                </span>
                            </div>
                        </div>
                        <div className="aeropay-bank-badge" style={{
                            backgroundColor: '#e8f5e9',
                            borderRadius: '20px',
                            padding: isMediumScreen ? '4px' : '4px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#2e7d32',
                            fontSize: getFontSize('13px', '12px', '11px', '10px'),
                            fontWeight: '500',
                            fontFamily: 'Outfit, sans-serif'
                        }}>
                            <IonIcon 
                                icon={checkmarkCircleOutline} 
                                style={{ 
                                    fontSize: getFontSize('16px', '15px', '14px', '13px'),
                                    marginRight: isMediumScreen ? '0' : '4px' 
                                }} 
                            />
                            {!isMediumScreen && <span>Selected</span>}
                        </div>
                    </div>
                </div>
            {/* </div> */}
            
            {/* Online Payments Coming Soon Message */}
            <div style={{
                margin: isSmallScreen ? '8px 0' : '10px 0',
                padding: isExtraSmallScreen ? '8px 10px' : (isSmallScreen ? '10px' : '10px 12px'),
                backgroundColor: '#f3f4ff',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #e0e6ff',
                width: '100%',
                boxSizing: 'border-box'
            }}>
                <IonIcon 
                    icon={sparklesOutline} 
                    style={{
                        fontSize: getFontSize('18px', '16px', '15px', '14px'),
                        color: '#1E3A8A',
                        marginRight: isExtraSmallScreen ? '6px' : '8px'
                    }}
                />
                <div>
                    <p style={{
                        margin: 0,
                        fontSize: getFontSize('14px', '13px', '12px', '11px'),
                        fontWeight: '600',
                        color: '#1E3A8A',
                        fontFamily: 'Outfit, sans-serif'
                    }}>
                        Online payments coming soon!
                    </p>
                    <p style={{
                        margin: isSmallScreen ? '1px 0 0 0' : '2px 0 0 0',
                        fontSize: getFontSize('12px', '11px', '10px', '9px'),
                        color: '#5c6ac4',
                        fontFamily: 'Outfit, sans-serif',
                        lineHeight: '1.3'
                    }}>
                        Pay securely from your bank account with StashPay
                    </p>
                </div>
            </div>
            
            {/* Commented out AeroPayWidget */}
            {/* <AeroPayWidget 
                user={user}
                mode="payment"
                onSuccess={() => {
                    // Refresh list to show new bank account
                }}
                onBankSelect={onBankSelect}
            /> */}
        </div>
    )
};

export default PurchasePaymentMethod;