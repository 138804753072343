import { useState, useEffect, useCallback, useMemo } from "react";
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from "react-redux";
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { getFunctions, httpsCallable } from "firebase/functions"; // Add Firebase functions import
import { fetchProducts, refreshProducts } from "../../redux/actions/productActions";
import HomeHero from "../components/HomeHero";
import CategorySelect from "../components/CategorySelect";
import FilterBar from "../components/widgets/filters/FilterBar"; // Import the FilterBar component
import ItemGallery from "../components/ItemGallery";
import SpotlightBanner from "../components/SpotlightBanner";
import Footer from "../components/Footer";
import TopNav from "../components/TopNav";
import PortalPopup from '../components/PortalPopup';
import AuthModal from '../components/AuthModal';
import StashModal from '../components/StashModal';
import TransitionModal from "../components/TransitionModal";
import defaultStoreImage from '../../public/default-store-image.svg';
import { IonIcon } from '@ionic/react';
import { locationOutline, timeOutline } from 'ionicons/icons';
import "./HomeScreen.css";
import {useInfiniteStores, useInfiniteStoresDirect } from '../utils/storeUtils';
import { useGeolocation } from '../hooks/useGeolocation';
import { extractFilterOptions, filterProducts, getDealsProducts } from '../utils/filterCache';

const HomeScreen = ({ openAuthModal, openStashModal }) => {
  const dispatch = useDispatch();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState('FLOWER');  // Default to FLOWER
  const [userSelectedType, setUserSelectedType] = useState(false);
  const [availableCategories, setAvailableCategories] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStore, setSelectedStore] = useState(null);
  const [availableStores, setAvailableStores] = useState([]); // Store the list of available stores
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const [isStashModalOpen, setIsStashModalOpen] = useState(false);
  const [stashModalMode, setStashModalMode] = useState('add');
  const [isTransitionModalOpen, setTransitionModalOpen] = useState(false);
  const [transitionModalMode, setTransitionModalMode] = useState('');
  const [seeAll, setSeeAll] = useState(false);
  const [headerText, setHeaderText] = useState('THE STASH'); // New state variable
  const [activeFilters, setActiveFilters] = useState({}); // Add state for active filters
  const [isCondensedView, setIsCondensedView] = useState(false); // Add state for filter view mode
  
  // Get products from Redux store
  const { 
    data: productsMap, 
    isLoading, 
    error: productsError,
    lastFetched
  } = useSelector(state => state.products);
  
  // Get user from Redux store
  const user = useSelector(state => state.user);
  
  // Extract products array from the map for easier use
  const allProducts = useMemo(() => {
    if (!productsMap) return [];
    return Object.values(productsMap);
  }, [productsMap]);
  
  // Selected store's products (or all products if no store selected)
  const products = useMemo(() => {
    if (selectedStore) {
      return filterProducts(allProducts, activeFilters, selectedProductType);
    }
    return allProducts;
  }, [allProducts, selectedStore, activeFilters, selectedProductType]);
  
  // Track pagination info
  const totalProducts = useMemo(() => products.length, [products]);
  const hasMore = false; // We load all products at once with our current implementation

  const PAGE_SIZE = 1000; // Match the pageSize in loadAllProducts
  
  // Test objects (can be removed in production)
  const testStore = {
    id: 'test-store-1',
    store_id: 'test-store-1', // Add this
    name: 'Test Cannabis Store',
    address: '123 Test St, Test City, CA 90210',
    imageUrl: 'https://via.placeholder.com/300',
    hours: {
      monday: { isOpen: true, open: '09:00', close: '21:00' },
      tuesday: { isOpen: true, open: '09:00', close: '21:00' },
      wednesday: { isOpen: true, open: '09:00', close: '21:00' },
      thursday: { isOpen: true, open: '09:00', close: '21:00' },
      friday: { isOpen: true, open: '09:00', close: '21:00' },
      saturday: { isOpen: true, open: '09:00', close: '21:00' },
      sunday: { isOpen: true, open: '09:00', close: '21:00' }
    },
    timezone: 'America/Los_Angeles',
    settings: {
      delivery: { enabled: true, minimumOrder: 50 },
      pickup: { enabled: true }
    }
  };
  
  const testProduct = {
    id: 'test-product-1',
    store_id: 'JlezFXhoNl4lVpibJZDx', // Add this
    name: 'AeroPay Test Product ($1)',
    pricing: {
      price_sell: 12.50,
      discounted_price: 12.50,
      price_type: "FLAT",
      discount_percent: 0,
      discount_amount: 0
    },
    category_type: 'FLOWER',
    classification: 'hybrid',
    brand: 'Test Brand',
    sellable_quantity: 999,
    imageUrl: 'https://via.placeholder.com/300',
    description: 'Test product for AeroPay integration. Safe to purchase.',
    type: 'flower',
    effects: ['relaxed', 'happy'],
    store: testStore,
    store_data: { // Add this
      store_id: 'JlezFXhoNl4lVpibJZDx',
      pricing: {
        price_sell: 12.50,
        discounted_price: 12.50,
        price_type: "FLAT",
        discount_percent: 0,
        discount_amount: 0,
        tier_name: null
      },
      quantity: {
        sellable_quantity: 999,
        sellable_quantity_details: {}
      }
    },
    isTestProduct: true
  };

  const openTransitionModal = (mode) => {
    setTransitionModalMode(mode);
    setTransitionModalOpen(true);
  };

  const functions = getFunctions();
  const processAndStoreSwishProducts = httpsCallable(functions, 'processAndStoreSwishProducts');
  const regenerateProductCDN = httpsCallable(functions, 'regenerateProductCDN');
  
  const handleProcessAndStoreSwishProducts = async () => {
    try {
      const result = await processAndStoreSwishProducts({
        dryRun: false,
        maxPages: 0,       // 0 means process all pages
        chunkSize: 50,     // Process 50 products per worker
        useCache: true,    // Use cache when available for better performance
        storeId: 'AkNtGp9779aEIdDRbVmA',
        collectionName: 'productsCatalog'
      });
      console.log('Swish products job started:', result.data);
      alert('Product processing job started! Check Firebase console for progress.');
    } catch (error) {
      console.error('Error processing and storing Swish products:', error);
      alert('Error starting product processing job: ' + error.message);
    }
  };

  const handleRegenerateCDN = async () => {
    try {
      setIsInitialLoading(true);
      const result = await regenerateProductCDN();
      console.log('CDN regenerated:', result.data);
      // After regenerating CDN, refresh the products in Redux
      await dispatch(refreshProducts());
      alert('Product CDN regeneration completed and products refreshed!');
      setIsInitialLoading(false);
    } catch (error) {
      console.error('Error regenerating CDN:', error);
      alert('Error regenerating CDN: ' + error.message);
      setIsInitialLoading(false);
    }
  };

  // Load products on component mount
  useEffect(() => {
    const loadProductData = async () => {
      try {
        // Check if products are already in Redux store
        if (productsMap && Object.keys(productsMap).length > 0) {
          console.log('Products already loaded in Redux, skipping fetch');
          setInitialLoadComplete(true);
          setIsInitialLoading(false);
          
          // Extract and update available stores from existing data
          const storesList = getAvailableStores(Object.values(productsMap));
          setAvailableStores(storesList);
          
          // Extract available categories from existing products
          const categories = new Set();
          Object.values(productsMap).forEach(product => {
            if (product.category_type) {
              categories.add(product.category_type);
            }
          });
          setAvailableCategories(categories);
          
          return;
        }
        
        // If not in Redux, fetch products
        const loadedProducts = await dispatch(fetchProducts());
        
        if (loadedProducts && loadedProducts.length > 0) {
          
          // Add debug console log to inspect first product
          console.log('HomeScreen - Product data after loading:', {
            firstProduct: loadedProducts[0],
            hasStores: loadedProducts[0].stores ? Object.keys(loadedProducts[0].stores).length : 0,
            hasAvailableStoreIds: loadedProducts[0].availableStoreIds ? loadedProducts[0].availableStoreIds.length : 0
          });
          
          // Update component state with products
          setInitialLoadComplete(true);
          setIsInitialLoading(false);
          
          // Extract and update available stores
          const storesList = getAvailableStores(loadedProducts);
          setAvailableStores(storesList);
          
          // Extract available categories from products
          const categories = new Set();
          loadedProducts.forEach(product => {
            if (product.category_type) {
              categories.add(product.category_type);
            }
          });
          setAvailableCategories(categories);
        } else {
          setIsInitialLoading(false);
        }
      } catch (error) {
        console.error('Error loading products:', error);
        setIsInitialLoading(false);
      }
    };
    
    loadProductData();
  }, []); // Run once on component mount

  // Update available categories whenever products change
  useEffect(() => {
    if (allProducts.length > 0) {
      const categories = new Set();
      allProducts.forEach(product => {
        if (product.category_type) {
          categories.add(product.category_type);
        }
      });
      setAvailableCategories(categories);
    }
  }, [allProducts]);

  const handleStoreSelect = async (storeOrProductId, storeIdOrFromShopNow = false) => {
    try {
      let store;
      let fromShopNow = false;
      
      // Handle both cases: store selection from dropdown and from Shop Now
      if (typeof storeIdOrFromShopNow === 'boolean') {
        // Case 1: Called from Shop Now button with (store, true)
        store = storeOrProductId;
        fromShopNow = storeIdOrFromShopNow;
      } else {
        // Case 2: Called from dropdown with (productId, storeId)
        // Find the store by ID
        const storeId = storeIdOrFromShopNow;
        store = availableStores.find(s => s.id === storeId);
      }
      
      if (!store || !store.name) {
        console.log('Invalid store or missing store name', store);
        return;
      }
      
      console.log('Store selected:', store.id, store.name, 'fromShopNow:', fromShopNow);
      
      // Always update the header text with the store name
      setHeaderText(`THE STASH @ ${store.name.toUpperCase()}`);
      
      if (fromShopNow) {
        // Filter existing products by the selected store
        const storeProducts = filterProducts(allProducts, activeFilters, selectedProductType);
        console.log(`Filtered to ${storeProducts.length} products for store ${store.id}`);
        
        // Update selected store and change view mode for "Shop Now"
        setSelectedStore({
          ...store,
          products: storeProducts
        });
        
        // Reset pagination
        setCurrentPage(1);
      }
    } catch (error) {
      console.error('Error filtering store products:', error);
    }
  };

  const handleSeeAllStores = () => {
    // navigate('/stores');
  };

  const loadMoreStores = () => {
    if (hasMoreStores) {
      fetchNextStoresPage();
    }
  };

  const formatAddress = (address) => {
    if (!address) return { street: '', location: '' };
    const addressWithoutCountry = address.replace(/, USA$/, '');
    const [street, ...rest] = addressWithoutCountry.split(',');
    return {
      street: street.trim(),
      location: rest.join(',').trim()
    };
  };

  const formatTodayHours = (hours, timezone) => {
    if (!hours) return 'Hours not available';
    
    const now = new Date();
    const storeTimezone = timezone || 'America/Los_Angeles';
    const localTime = new Date(now.toLocaleString('en-US', { timeZone: storeTimezone }));
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const dayName = days[localTime.getDay()];
    const todayHours = hours[dayName];
    
    if (!todayHours || !todayHours.open || !todayHours.close) {
      return 'Closed';
    }
    
    const formatTime = (timeStr) => {
      const [hours, minutes] = timeStr.split(':').map(Number);
      let period = 'am';
      let displayHours = hours;
      
      if (hours >= 12) {
        period = 'pm';
        if (hours > 12) {
          displayHours = hours - 12;
        }
      }
      if (hours === 0) {
        displayHours = 12;
      }
      
      return `${displayHours}:${minutes.toString().padStart(2, '0')}${period}`;
    };

    return `${formatTime(todayHours.open)} - ${formatTime(todayHours.close)}`;
  };

  const handleFilterChange = useCallback((newFilters) => {
    setActiveFilters(newFilters);
  }, []);

  // Get filter options once when products load
  const filterOptions = useMemo(() => 
    extractFilterOptions(products), [products]);

  // Memoize filtered products calculation for better performance
  const memoizedFilteredProducts = useMemo(() => 
    filterProducts(products, activeFilters, selectedProductType), 
    [products, activeFilters, selectedProductType]);

  // Memoize deal products calculation for better performance
  const memoizedDealProducts = useMemo(() => 
    getDealsProducts(products, activeFilters), 
    [products, activeFilters]);

  const handleCategoryChange = useCallback((newCategory) => {
    // Don't trigger any loading when changing categories - just update the selection
    setSelectedProductType(newCategory);
    setUserSelectedType(true);
  }, []);

  // Add store-related state and hooks
  // Temporarily disabled geolocation
  // const { location: userLocation, error: locationError } = useGeolocation();
  const { 
    data: storesData,
    fetchNextPage: fetchNextStoresPage,
    hasNextPage: hasMoreStores,
    isLoading: isLoadingStores,
    isFetching: isFetchingStores
  } = useInfiniteStoresDirect({
    // Temporarily disabled location-based filtering
    // ...(userLocation ? {
    //   latitude: userLocation.latitude,
    //   longitude: userLocation.longitude
    // } : {}),
    limit: 8,
    includeTest: true, // Set to true to include test stores
  });

  // Combine isLoading and isFetching for UI state
  const isStoresLoading = isLoadingStores || isFetchingStores;

  // Memoize flattened stores data
  const stores = useMemo(() => {
    if (!storesData?.pages) return [];
    const allStores = storesData.pages.flatMap(page => page.stores);
    
    // Temporarily disabled location-based sorting
    // if (userLocation) {
    //   return allStores.sort((a, b) => {
    //     const distanceA = a.distance || Number.MAX_VALUE;
    //     const distanceB = b.distance || Number.MAX_VALUE;
    //     return distanceA - distanceB;
    //   });
    // }
    
    return allStores;
  }, [storesData]);

  // Simple loadMore function for pagination if needed
  const loadMore = useCallback(() => {
    // Since we're fetching all products at once with the new API,
    // pagination isn't needed with our current implementation
    console.log('Load more called, but all products loaded at once');
  }, []);

  const openAuthModalLocal = () => setAuthModalOpen(true);
  const closeAuthModalLocal = () => setAuthModalOpen(false);
  const openStashModalLocal = (mode) => {
    setStashModalMode(mode);
    setIsStashModalOpen(true);
  };
  const closeStashModalLocal = () => setIsStashModalOpen(false);

  const handleBackClick = () => {
    setSeeAll(false);
    setSelectedStore(null);
    setHeaderText('THE STASH');
  };

  const handleSeeAllClick = () => {
    setSeeAll(true);
  };

  return (
    <div className="homescreen">
      <main className="home-page">
        <section className="frame-parent">
          <div className={`category-select-wrapper ${seeAll || selectedStore !== null ? 'fade-up' : ''}`}>
            {(seeAll || selectedStore) && (
              <div className="back-arrow" onClick={handleBackClick}>
                ← Back
              </div>
            )}
            <div className="section-header-container">
              <div 
                className="section-header" 
                data-text={headerText}
              >
                {headerText}
              </div>
              {selectedStore && (
                <img 
                  src={selectedStore.imageUrl || defaultStoreImage} 
                  alt={selectedStore.name}
                  className="store-header-image"
                />
              )}
            </div>
            {selectedStore && (
              <div className="store-details-card">
                <div className="store-info-line">
                  <img 
                    src={selectedStore.imageUrl || defaultStoreImage} 
                    alt={selectedStore.name}
                    className="store-card-icon"
                  />
                  <span className="store-name-text">{selectedStore.name}</span>
                </div>
                <div className="store-info-line">
                  <IonIcon icon={locationOutline} className="store-icon" />
                  <span>{formatAddress(selectedStore.address).street}, {formatAddress(selectedStore.address).location}</span>
                </div>
                <div className="store-info-line">
                  <IonIcon icon={timeOutline} className="store-icon" />
                  <span>Today: {formatTodayHours(selectedStore.hours, selectedStore.timezone)}</span>
                </div>
              </div>
            )}
            <CategorySelect 
              setSelectedProductType={handleCategoryChange}
              selectedProductType={selectedProductType}
              availableCategories={availableCategories}
              seeAll={seeAll || selectedStore !== null}
              setSeeAll={setSeeAll}
              handleBackClick={handleBackClick}
              products={products}
            />
            
            {/* Add FilterBar */}
            <FilterBar 
              products={products}
              activeFilters={activeFilters}
              setActiveFilters={handleFilterChange}
              condensedView={isCondensedView}
              selectedProductType={selectedProductType}
            />
          </div>
          <img
            className="night-mode-1-traced"
            alt=""
            src="/nightmode-1-traced.svg"
          />
          <img
            className="cloud-1-traced1"
            loading="lazy"
            alt=""
            src="/cloud-1-traced.svg"
          />
          {(isInitialLoading || isLoading) ? 
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
            : 
            <>
              <div className="product-gallery-wrapper">
                <ItemGallery 
                  products={memoizedFilteredProducts}
                  onSeeAllClick={handleSeeAllClick} 
                  seeAll={seeAll}
                  onLoginClick={openAuthModalLocal}
                  onPurchaseComplete={() => openTransitionModal('history')}
                  onLoadMore={loadMore}
                  hasMore={hasMore}
                  onStoreSelect={handleStoreSelect}
                  isLoading={isInitialLoading && !initialLoadComplete}
                />
              </div>
              {!seeAll && !selectedStore ? (
                <>
                  <SpotlightBanner className={fadeOut ? 'fade-out' : fadeIn ? 'fade-in' : ''} />
                  <div className="product-gallery-wrapper">
                    <div className="section-header" data-text="THE DEALS">
                      THE DEALS
                    </div>
                    {/* Add FilterBar for Deals section */}
                    <FilterBar 
                      products={memoizedDealProducts}
                      activeFilters={activeFilters}
                      setActiveFilters={handleFilterChange}
                      condensedView={true}
                      selectedProductType={selectedProductType}
                    />
                    <ItemGallery 
                      products={memoizedDealProducts}
                      onSeeAllClick={handleSeeAllClick} 
                      seeAll={false}
                      onLoginClick={openAuthModalLocal}
                      onPurchaseComplete={() => openTransitionModal('history')}
                      onLoadMore={loadMore}
                      hasMore={hasMore}
                      isLoading={isInitialLoading && !initialLoadComplete}
                    />
                  </div>
                  <div className={`category-select-wrapper ${seeAll || selectedStore !== null ? 'fade-up' : ''}`}>
                    <div className="product-gallery-wrapper">
                      <div className="section-header" data-text="THE STORES">
                        THE STORES
                      </div>
                      <ItemGallery 
                        stores={stores}
                        onSeeAllClick={handleSeeAllStores}
                        seeAll={false}
                        onLoadMore={loadMoreStores}
                        hasMore={hasMoreStores}
                        isLoading={isStoresLoading}
                        userLocation={null}
                        onStoreSelect={handleStoreSelect}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              
            </>
          }
          {!seeAll && !selectedStore && <Footer className={`footer ${fadeOut ? 'fade-out' : fadeIn ? 'fade-in' : ''}`} />}
        </section>
        
        {/* ADMIN TESTING BUTTON - REMOVE AFTER TESTING
        <div style={{ 
          position: 'fixed', 
          top: '100px', 
          right: '20px', 
          zIndex: 9999  // Increased z-index to ensure it's above everything
        }}>
          <button 
            onClick={handleProcessAndStoreSwishProducts}
            style={{
              backgroundColor: '#FF6F00',
              color: 'white',
              padding: '12px 20px',  // Larger padding
              border: '2px solid #FF4500',  // Added border
              borderRadius: '5px',
              cursor: 'pointer',
              fontWeight: 'bold',
              fontSize: '16px',  // Larger text
              boxShadow: '0 4px 8px rgba(0,0,0,0.3)',  // More prominent shadow
              transform: 'scale(1.05)',  // Slightly larger
              transition: 'transform 0.2s',  // Smooth scaling
              pointerEvents: 'auto'  // Ensure clicks are registered
            }}
          >
            Process Products
          </button>
          <button 
            onClick={handleRegenerateCDN}
            style={{
              backgroundColor: '#FF6F00',
              color: 'white',
              padding: '12px 20px',  // Larger padding
              border: '2px solid #FF4500',  // Added border
              borderRadius: '5px',
              cursor: 'pointer',
              fontWeight: 'bold',
              fontSize: '16px',  // Larger text
              boxShadow: '0 4px 8px rgba(0,0,0,0.3)',  // More prominent shadow
              transform: 'scale(1.05)',  // Slightly larger
              transition: 'transform 0.2s',  // Smooth scaling
              pointerEvents: 'auto',  // Ensure clicks are registered
              marginTop: '10px'
            }}
          >
            Regen CDN
          </button>
          <button 
            onClick={() => dispatch(refreshProducts())}
            style={{
              backgroundColor: '#0066CC',
              color: 'white',
              padding: '12px 20px',
              border: '2px solid #0044AA',
              borderRadius: '5px',
              cursor: 'pointer',
              fontWeight: 'bold',
              fontSize: '16px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
              transform: 'scale(1.05)',
              transition: 'transform 0.2s',
              pointerEvents: 'auto',
              marginTop: '10px'
            }}
          >
            Refresh Products
          </button>
        </div> */}
        <TopNav user={user} products={products} onLoginClick={openAuthModalLocal} />
        {isAuthModalOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Centered"
            onOutsideClick={closeAuthModalLocal}
          >
            <AuthModal onClose={closeAuthModalLocal} />
          </PortalPopup>
        )}
        {isStashModalOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Centered"
            onOutsideClick={closeStashModalLocal}
          >
            <StashModal
              onClose={closeStashModalLocal}
              products={products}
              initialMode={stashModalMode}
            />
          </PortalPopup>
        )}
        {isTransitionModalOpen && (
          <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={() => setTransitionModalOpen(false)}
          >
            <TransitionModal
              onClose={() => setTransitionModalOpen(false)}
              products={products}
              initialMode={transitionModalMode}
              openAccountModal={openAuthModal}
            />
          </PortalPopup>
        )}
      </main>
    </div>
  );
};

export default HomeScreen;