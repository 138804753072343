import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IonIcon } from '@ionic/react';
import { trashOutline, removeCircleOutline, addCircleOutline } from 'ionicons/icons';
import { updateCartItemQuantity, removeFromCart, updateBuyNowQuantity } from '../../redux/actions/orderActions';
import "./QuantityCounter.css";

const QuantityCounter = ({ inventoryCount, onQuantityChange, initialQuantity, productId, isBuyNow = false, align = 'center' }) => {
  const [quantity, setQuantity] = useState(initialQuantity)
  const cart = useSelector(state => state.order.cart || []);
  const buyNow = useSelector(state => state.order.buyNow || {});

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  // Find the current item in the cart to get its price
  const currentItem = isBuyNow ? buyNow.product : cart.find(item => item.id === productId);

  const handleIncrement = () => {
    if (quantity < inventoryCount) {
      // Only check cart subtotal if this is not a Buy Now flow
      if (!isBuyNow) {
        // Calculate current cart subtotal
        let subtotal = 0;
        cart.forEach(item => {
          const price = item.discountedPrice || item.price || 0;
          const qty = item.id === productId ? quantity : item.quantity || 0;
          subtotal += price * qty;
        });
        
        // Get current item price
        const itemPrice = currentItem ? (currentItem.discountedPrice || currentItem.price || 0) : 0;
        
        // Check if incrementing would exceed $500
        if (subtotal + itemPrice > 500) {
          alert('Your cart subtotal would exceed $500. Please remove some items to proceed.');
          return; // Prevent the increment
        }
      } else {
        // For Buy Now, check that the single item doesn't exceed $500
        const buyNowPrice = currentItem ? (currentItem.discountedPrice || currentItem.price || 0) : 0;
        
        // Check if incrementing would make the Buy Now item exceed $500
        if (buyNowPrice * (quantity + 1) > 500) {
          alert('This purchase would exceed $500. Please reduce the quantity to proceed.');
          return; // Prevent the increment
        }
      }
      
      // If we pass the check, update the quantity
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      
      // Update the Buy Now quantity in Redux if in Buy Now mode
      if (isBuyNow) {
        dispatch(updateBuyNowQuantity(newQuantity));
      }
      
      // Call the parent component's function to handle the actual update
      if (onQuantityChange) {
        onQuantityChange(newQuantity);
      }
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      
      // Update the Buy Now quantity in Redux if in Buy Now mode
      if (isBuyNow) {
        dispatch(updateBuyNowQuantity(newQuantity));
      }
      
      if (onQuantityChange) {
        onQuantityChange(newQuantity);
      }
    }
  };

  const handleRemove = () => {
    if (!isBuyNow && productId && user?.userId) {
      dispatch(removeFromCart(user.userId, productId));
    }
  };

  return (
    <div className={`quantity-counter-wrapper ${align}`}>
      <div className="quantity-counter">
        <div 
          className="icon-wrapper" 
          onClick={quantity === 1 && !isBuyNow ? handleRemove : handleDecrement}
          style={{ pointerEvents: quantity === 1 && isBuyNow ? 'none' : 'auto' }}
        >
          <IonIcon 
            icon={quantity === 1 ? trashOutline : removeCircleOutline} 
            style={{ visibility: quantity === 1 && isBuyNow ? 'hidden' : 'visible' }}
          />
        </div>
        <div className="quantity-display">
          <div className="quantity">{quantity}</div>
        </div>
        <div className="icon-wrapper" onClick={quantity < inventoryCount ? handleIncrement : undefined}>
          <IonIcon icon={addCircleOutline} style={{ visibility: quantity >= inventoryCount ? 'hidden' : 'visible' }} />
        </div>
      </div>
      {quantity >= inventoryCount && (
        <div className="quantity-message">Max quantity reached</div>
      )}
    </div>
  );
};

export default QuantityCounter;
