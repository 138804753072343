import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { IonIcon } from '@ionic/react';
import { 
  personCircleOutline, 
  pencilOutline, 
  checkmarkCircleOutline, 
  cardOutline, 
  alertCircleOutline,
  swapHorizontalOutline,
  pencilSharp,
  closeCircleOutline,
  timeOutline
} from 'ionicons/icons';

import { updateUserEmail, resetUserPassword, updateUserInfo } from '../../utils/FirebaseUtils';
import AuthService from "../../utils/AuthService";
import { sendVerificationCode, verifyPhoneCode, formatPhoneNumber, formatPhoneForDisplay } from '../../utils/TwilioService';

// Import toast from react-toastify
import { toast } from 'react-toastify';

import { 
  createAeroPayUser, 
  linkBank, 
  fetchAeroPayBanks, 
  listAeroPayEnrollments
} from '../../redux/actions/aeropayActions';
import { updatePhoneNumber, setPhoneVerified, clearUser } from '../../redux/actions/userActions';


import ModalHeader from "./ModalHeader";
import "./AccountModal.css";
import LoginButton from './LoginButton';
import VerificationForm from './VerificationForm';
import AeroPayWidget from './widgets/AeropayWidget';
import SubscriptionWidget from './widgets/SubscriptionWidget';
import UploadID from './widgets/UploadID';

const AccountModal = ({ onClose, user }) => {
  const [formData, setFormData] = useState({
    email: user.email || "",
    phoneNumber: user.phoneNumber || "",
    purchaseStatus: user.purchaseStatus || false
  });
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [status, setStatus] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: ""
  });
  const [showAeroPayWidget, setShowAeroPayWidget] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [verificationError, setVerificationError] = useState('');
  const [verificationLoading, setVerificationLoading] = useState(false);
  
  const dispatch = useDispatch();

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      email: user.email || "",
      phoneNumber: user.phoneNumber || "",
      purchaseStatus: user.purchaseStatus || false
    }));
  }, [user]);

  useEffect(() => {
    if (formData.purchaseStatus) {
      setStatus("Your account is all set.");
    } else {
      setStatus("Update account to make a purchase.");
    }
  }, [formData.purchaseStatus]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEmailEdit = async () => {
    if (isEditingEmail) {
      await updateUserEmail(user.userId, formData.email);
    }
    setIsEditingEmail(!isEditingEmail);
  };

const handlePhoneNumberInput = (e) => {
    // Special case for backspace at formatting characters
    if (e.target.value.length < formData.phoneNumber.length) {
      const prevDigits = formData.phoneNumber.replace(/\D/g, '');
      const currentDigits = e.target.value.replace(/\D/g, '');
      
      // If number of digits didn't change but length did, we're at a formatting character
      if (currentDigits.length === prevDigits.length) {
        // Manually remove one digit
        const newDigits = prevDigits.slice(0, -1);
        
        // Format the phone number
        let formattedNumber = '';
        if (newDigits.length > 0) {
          if (newDigits.length <= 3) {
            formattedNumber = newDigits.length === 3 ? `(${newDigits})` : `(${newDigits}`;
          } else if (newDigits.length <= 6) {
            formattedNumber = `(${newDigits.substring(0, 3)}) ${newDigits.substring(3)}`;
          } else {
            formattedNumber = `(${newDigits.substring(0, 3)}) ${newDigits.substring(3, 6)}-${newDigits.substring(6)}`;
          }
        }
        
        setFormData(prev => ({
          ...prev,
          phoneNumber: formattedNumber
        }));
        return;
      }
    }
    
    // Get the input value without any formatting
    const inputVal = e.target.value.replace(/\D/g, '');
    
    // Only process if the input consists of digits only
    if (/^\d*$/.test(inputVal)) {
      // Limit to 10 digits (US phone number)
      const trimmed = inputVal.substring(0, 10);
      
      // Format the phone number as (XXX) XXX-XXXX
      let formattedNumber = '';
      if (trimmed.length > 0) {
        if (trimmed.length <= 3) {
          // Just the area code or part of it
          formattedNumber = trimmed.length === 3 ? `(${trimmed})` : `(${trimmed}`;
        } else if (trimmed.length <= 6) {
          // Area code + prefix
          formattedNumber = `(${trimmed.substring(0, 3)}) ${trimmed.substring(3)}`;
        } else {
          // Complete number
          formattedNumber = `(${trimmed.substring(0, 3)}) ${trimmed.substring(3, 6)}-${trimmed.substring(6)}`;
        }
      }
      
      // Update the form data
      setFormData(prev => ({
        ...prev,
        phoneNumber: formattedNumber
      }));
    }
  };

  const handlePhoneEdit = () => {
    if (user.phoneVerified && !isEditingPhone) {
      // Confirm before allowing edit of a verified number
      if (!window.confirm("Warning: Changing your verified phone number will disconnect your Aeropay account and bank connections. Are you sure you want to continue?")) {
        return;
      }
    }
    if (isEditingPhone) {
      // Get only the digits from the formatted phone number
      const digitsOnly = formData.phoneNumber.replace(/\D/g, '');
      
      // Format phone number to E.164 format before saving
      const formattedPhone = formatPhoneNumber(digitsOnly);
      dispatch(updatePhoneNumber(formattedPhone));
    } else {
      // When starting to edit, format existing phone number for display
      if (formData.phoneNumber) {
        // Get only the digits from the stored phone number (removing the +1 prefix)
        const digitsOnly = formData.phoneNumber.replace(/\D/g, '');
        
        // Format for display in (XXX) XXX-XXXX format using only the national part (without country code)
        let displayNumber = '';
        const nationalNumber = digitsOnly.startsWith('1') ? digitsOnly.substring(1) : digitsOnly;
        
        if (nationalNumber.length >= 10) {
          displayNumber = `(${nationalNumber.slice(0, 3)}) ${nationalNumber.slice(3, 6)}-${nationalNumber.slice(6, 10)}`;
          
          // Update the form data with formatted display version
          setFormData(prev => ({
            ...prev,
            phoneNumber: displayNumber
          }));
        }
      } else {
        // If no phone number exists, initialize with empty format
        setFormData(prev => ({
          ...prev,
          phoneNumber: ''
        }));
      }
    }
    setIsEditingPhone(!isEditingPhone);
    
    // Reset verification UI when editing stops or starts
    setIsVerifying(false);
    setVerificationSent(false);
    setVerificationCode('');
    setVerificationError('');
  };

  // Start phone verification process
  const handleStartVerification = async () => {
    setIsVerifying(true);
    setVerificationError('');
    setVerificationLoading(true);
    
    try {
      // Format phone number for API
      const formattedPhone = formatPhoneNumber(formData.phoneNumber.replace(/\D/g, ''));
      
      // Send verification code
      const response = await sendVerificationCode(formattedPhone);
      
      // Check if phone is already verified
      if (response.message === 'Phone already verified') {
        // Show toast notification
        toast.info('Your phone number is already verified!', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        
        // Make sure Redux state reflects verified status
        dispatch(setPhoneVerified(true, formattedPhone));
        
        // Reset all UI states completely to show verified UI
        setIsVerifying(false);
        setVerificationSent(false);
        setVerificationCode('');
        setVerificationError('');
        setIsEditingPhone(false);
        
        // Force the component to recognize the verified state
        // This ensures the UI updates to show the verified state
        if (user && !user.phoneVerified) {
          // If user state doesn't reflect verification yet, update local state
          // until Redux propagates the change
          setFormData(prev => ({
            ...prev,
            phoneVerified: true
          }));
        }
      } else {
        // Normal flow for new verification
        setVerificationSent(true);
      }
    } catch (error) {
      // Check if this is the "phone already claimed" error
      if (error.code === 'PHONE_ALREADY_CLAIMED') {
        toast.error('This phone number is already verified by another account. Please use a different number.', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setVerificationError('This phone number is already verified by another account.');
      } else {
        setVerificationError(error.message || 'Failed to send verification code');
      }
    } finally {
      setVerificationLoading(false);
    }
  };

  // Verify the code entered by the user
  const handleVerifyCode = async () => {
    if (!verificationCode) {
      setVerificationError('Please enter the verification code');
      return;
    }
    
    setVerificationError('');
    setVerificationLoading(true);
    
    try {
      const formattedPhone = formatPhoneNumber(formData.phoneNumber.replace(/\D/g, ''));
      console.log('Submitting verification:', { 
        phoneNumber: formattedPhone, 
        code: verificationCode 
      });
      
      const result = await verifyPhoneCode(formattedPhone, verificationCode);
      console.log('Full verification response:', result);
      
      if (result.success === true) {
        // Update Redux with verified phone (you already have this)
        dispatch(setPhoneVerified(true, formattedPhone));
        
        // Now also update the phone in Firebase
        try {
          await updateUserInfo(user.userId, { 
            phoneNumber: formattedPhone,
            phoneVerified: true 
          });
        } catch (updateError) {
          console.error('Failed to update user info in database:', updateError);
        }
        // Reset verification UI
        setIsVerifying(false);
        setVerificationSent(false);
        setIsEditingPhone(false);
      } else {
        const errorMessage = result.message || 'Verification failed';
        console.error('Verification failed:', {
          status: result.status,
          message: errorMessage
        });
        setVerificationError(errorMessage);
      }
    } catch (error) {
      console.error('Verification error:', error);
      
      // Check if this is the "phone already claimed" error
      if (error.code === 'PHONE_ALREADY_CLAIMED') {
        toast.error('This phone number is already verified by another account. Please use a different number.', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setVerificationError('This phone number is already verified by another account.');
      } else {
        setVerificationError(error.message || 'Failed to verify code');
      }
    } finally {
      setVerificationLoading(false);
    }
  };

  const cancelPhoneEdit = () => {
    // Reset to original value
    setFormData(prev => ({
      ...prev,
      phoneNumber: user.phoneNumber || ""
    }));
    setIsEditingPhone(false);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const handleResetPassword = async () => {
    if (isResettingPassword) {
      if (passwords.newPassword === passwords.confirmPassword) {
        await resetUserPassword(formData.email, passwords.newPassword);
        toast({
          title: "Password reset successfully!",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setIsResettingPassword(false);
      } else {
        toast({
          title: "Passwords do not match!",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      setIsResettingPassword(true);
    }
  };

  const handleLogout = async () => {
    try {
      await AuthService.logout();
      dispatch(clearUser());
      onClose();
    } catch (error) {
      console.error('Logout failed', error);
      toast({
        title: "Logout Failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Not set";
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return "Not set";
      return date.toLocaleDateString();
    } catch (error) {
      console.error('Error formatting date:', error);
      return "Not set";
    }
  };

  // Function to fetch updated user details after AeroPay operations
  const fetchUserDetails = async () => {
    try {
      // This function is intentionally left empty as the user data 
      // is already being updated through redux actions and listeners
      // The component will re-render with fresh data due to redux state changes
    } catch (error) {
      console.error("Error refreshing user data:", error);
    }
  };

  return (
    <div className="account-modal">
      <div className="modal-header-wrapper">
        <ModalHeader title="Your Account" onClose={onClose} />
      </div>
      <hr className="divider" />
      <div className="account-modal-content">
        {!formData.purchaseStatus ? (
          <VerificationForm onVerificationSuccess={() => setFormData(prev => ({ ...prev, purchaseStatus: true }))} />
        ) : (
          <>
            <div className="greeting-section">
              <h2 className="greeting">Hello, {user.accountName ? user.accountName.split(' ')[0] : 'there'}!</h2>
            </div>
            <div className="profile-section">
              <div className="profile-left-column">
                <div className="profile-icon-wrapper-am">
                  <IonIcon icon={personCircleOutline} className="profile-icon-am" />
                </div>
                <div className="profile-item email-item">
                  {isEditingEmail ? (
                    <input 
                      type="email" 
                      name="email" 
                      value={formData.email} 
                      onChange={handleChange} 
                    />
                  ) : (
                    <span className="profile-value">{formData.email}</span>
                  )}
                  <IonIcon icon={isEditingEmail ? checkmarkCircleOutline : pencilOutline} className="edit-icon" onClick={handleEmailEdit} />
                </div>
                <div className="login-button-wrapper">
                  <LoginButton onClick={handleLogout} user={user} />
                </div>
                {isResettingPassword ? (
                  <div className="password-reset-section">
                    <input
                      type="password"
                      name="newPassword"
                      value={passwords.newPassword}
                      onChange={handlePasswordChange}
                      className="profile-input"
                      placeholder="New Password"
                    />
                    <input
                      type="password"
                      name="confirmPassword"
                      value={passwords.confirmPassword}
                      onChange={handlePasswordChange}
                      className="profile-input"
                      placeholder="Confirm Password"
                    />
                    <button onClick={handleResetPassword} className="reset-password-button">Submit</button>
                  </div>
                ) : (
                  <button onClick={handleResetPassword} className="reset-password-button">Reset Password</button>
                )}
              </div>
              <div className="profile-right-column">
                <div className="profile-content-wrapper">
                  <div className="profile-item">
                    <span className="profile-label">Full Name:</span>
                    <span className="profile-value">{user.accountName || "Not set"}</span>
                  </div>          
                  <div className="profile-item">
                    <span className="profile-label">Birthday:</span>
                    <span className="profile-value">{formatDate(user.birthday)}</span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-label">ID Type:</span>
                    <span className="profile-value">{user.idType || "Not set"}</span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-label">ID State:</span>
                    <span className="profile-value">{user.licenseState || "Not set"}</span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-label">ID Number:</span>
                    <span className="profile-value">{user.idNumber || "Not set"}</span>
                  </div>
                  <div className="profile-item" style={{ display: 'flex', justifyContent: 'center' }}>
                    <button 
                      className="edit-info-button" 
                      onClick={() => setFormData(prev => ({ ...prev, purchaseStatus: false }))}
                      style={{ 
                        width: '300px',
                        backgroundColor: '#1E3A8A',
                        color: 'white',
                        padding: '12px 24px',
                        border: '1px solid white',
                        borderRadius: '100px',
                        cursor: 'pointer',
                        fontWeight: '500',
                        fontSize: '16px',
                        fontFamily: 'Outfit, sans-serif',
                        letterSpacing: '0.02em',
                        lineHeight: '150%',
                        boxShadow: '4px 8px 30px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      Edit Information
                    </button>
                  </div>
                  {false && (
                    <>
                      <div className="profile-item" style={{ marginTop: '20px' }}>
                        {/* <h4 style={{ textAlign: 'center', marginBottom: '10px' }}>Verify Your Identity</h4> */}
                        <UploadID user={user} />
                      </div>
                      <div className={`status ${formData.purchaseStatus ? "status-ready" : "status-update"}`}>
                        <IonIcon icon={formData.purchaseStatus ? checkmarkCircleOutline : alertCircleOutline} className="status-icon" />
                        <span> {status}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <hr className="divider" />
            <div className="profile-section">
              <div className="profile-left-column">
                <div className="payment-icon-wrapper-am">
                  <IonIcon 
                    icon={cardOutline} 
                    className="payment-icon-am" 
                  />
                </div>
              </div>
              <div className="profile-right-column">
                <div className="profile-content-wrapper">
                  <h3 className="payment-section-title">Online payments coming soon!</h3>
                  {false && (
                    <div 
                      className="phone-verification-section" 
                      style={{ 
                        marginBottom: '15px', 
                        borderBottom: '1px solid #eee', 
                      paddingBottom: '15px',
                      ...(user.phoneVerified && !isEditingPhone ? {
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
                        padding: '12px 16px'
                      } : {})
                    }}
                  >
                    {user.phoneVerified ? (
                      // Verified phone - Collapsible section
                      <div>
                        {/* Collapsed View - Shows when section is collapsed */}
                        {!isEditingPhone && (
                          <div className="phone-verified-collapsed" style={{ 
                            display: 'flex', 
                            padding: '8px 12px',
                            alignItems: 'center', 
                            justifyContent: 'space-between'
                          }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ 
                                fontWeight: '500', 
                                fontSize: '16px', 
                                color: '#1E3A8A',
                                marginBottom: '4px'
                              }}>
                                {formatPhoneForDisplay(user.phoneNumber)}
                              </span>
                              <div style={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                color: '#4caf50'
                              }}>
                                <IonIcon icon={checkmarkCircleOutline} style={{ marginRight: '5px', fontSize: '16px' }} />
                                <span style={{ fontSize: '14px', fontWeight: '500' }}>Verified for Aeropay</span>
                              </div>
                            </div>
                            <div 
                              className="edit-icon-wrapper" 
                              onClick={() => setIsEditingPhone(true)}
                              style={{ 
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '32px',
                                height: '32px',
                                borderRadius: '50%',
                                color: '#1E3A8A',
                                transition: 'background-color 0.2s ease'
                              }}
                              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f0f4ff'}
                              onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                            >
                              <IonIcon icon={pencilOutline} style={{ fontSize: '18px' }} />
                            </div>
                          </div>
                        )}
                        {/* Expanded View - Shows when editing */}
                        {isEditingPhone && (
                          <>
                            <div className="phone-section-header" style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                              <IonIcon icon={checkmarkCircleOutline} 
                                      style={{ color: '#4caf50', marginRight: '10px', fontSize: '20px' }} />
                              <h4 style={{ margin: 0, fontSize: '16px', fontWeight: '500' }}>Mobile Phone for Aeropay</h4>
                            </div>
                            
                            <div className="phone-section-description" style={{ fontSize: '14px', color: '#666', marginBottom: '12px' }}>
                              Your verified mobile number is linked to your Aeropay account for secure bank payments. 
                              {user.phoneVerified && " Changing this number will disconnect your Aeropay account."}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      // Unverified phone - Always expanded view
                      <>
                        <div className="phone-section-header" style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                          <IonIcon icon={alertCircleOutline} 
                                  style={{ color: '#ff9800', marginRight: '10px', fontSize: '20px' }} />
                          <h4 style={{ margin: 0, fontSize: '16px', fontWeight: '500' }}>Mobile Phone for Aeropay</h4>
                        </div>
                        
                        <div className="phone-section-description" style={{ fontSize: '14px', color: '#666', marginBottom: '12px' }}>
                          Your verified mobile number is linked to your Aeropay account for secure bank payments. 
                          {!user.phoneVerified && " Verification is required before connecting a bank account."}
                        </div>
                      </>
                    )}
                    
                    {(isEditingPhone || !user.phoneVerified) && (
                      <div className="profile-item phone-item" style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
                        {isEditingPhone ? (
                          <>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <div className="phone-input-container" style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                                <span style={{ 
                                  marginRight: '4px', 
                                  color: '#555', 
                                  fontWeight: 'bold'
                                }}>+1</span>
                                <input
                                  type="tel"
                                  name="phoneNumber"
                                  value={formData.phoneNumber}
                                  onChange={handlePhoneNumberInput}
                                  className="profile-input"
                                  placeholder="(555) 123-4567"
                                  maxLength={14} // (XXX) XXX-XXXX = 14 chars
                                  style={{ flex: 1 }}
                                />
                              </div>
                              
                              {/* Conditional rendering for save button - only active when valid phone format */}
                              <button 
                                onClick={handlePhoneEdit}
                                disabled={!formData.phoneNumber || formData.phoneNumber.length < 14}
                                style={{
                                  backgroundColor: formData.phoneNumber && formData.phoneNumber.length === 14 ? '#1E3A8A' : '#ccc',
                                  color: 'white',
                                  border: 'none',
                                  borderRadius: '4px',
                                  padding: '5px 10px',
                                  marginRight: '5px',
                                  cursor: formData.phoneNumber && formData.phoneNumber.length === 14 ? 'pointer' : 'not-allowed'
                                }}
                              >
                                Continue
                              </button>
                              
                              <button 
                                onClick={cancelPhoneEdit}
                                style={{
                                  backgroundColor: 'white',
                                  color: '#1E3A8A',
                                  border: '1px solid #1E3A8A',
                                  borderRadius: '4px',
                                  padding: '5px 10px',
                                  cursor: 'pointer'
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                            
                            {/* Warning message for existing users */}
                            {user.phoneVerified && (
                              <div style={{ 
                                backgroundColor: '#fff3e0', 
                                color: '#e65100', 
                                padding: '10px', 
                                borderRadius: '4px', 
                                marginTop: '10px',
                                fontSize: '14px'
                              }}>
                                <IonIcon icon={alertCircleOutline} style={{ marginRight: '5px' }} />
                                Warning: Changing your verified phone number will disconnect your Aeropay account and bank connections.
                              </div>
                            )}
                            
                            {/* Remove verification UI from edit mode - it will only appear after saving */}
                          </>
                        ) : (
                          <>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '8px 0' }}>
                              <div style={{ flex: 1, marginRight: '15px' }}>
                                <div style={{ fontSize: '16px', fontWeight: '500' }}>
                                  {user.phoneNumber ? formatPhoneForDisplay(user.phoneNumber) : ""}
                                </div>
                                {user.phoneVerified && 
                                  <div style={{ fontSize: '13px', color: '#4caf50', display: 'flex', alignItems: 'center' }}>
                                    <IonIcon icon={checkmarkCircleOutline} style={{ marginRight: '5px', fontSize: '14px' }} />
                                    <span style={{ fontSize: '14px', fontWeight: '500' }}>Verified for Aeropay</span>
                                  </div>
                                }
                                {/* {!user.phoneVerified && user.phoneNumber && (
                                  <div style={{ 
                                    backgroundColor: '#e0f2f1', 
                                    color: '#00695c', 
                                    padding: '10px', 
                                    borderRadius: '4px', 
                                    marginBottom: '10px',
                                    fontSize: '14px',
                                    marginTop: '10px'
                                  }}>
                                    <IonIcon icon={alertCircleOutline} style={{ marginRight: '5px' }} />
                                    <strong>Verification needed</strong> for Aeropay payments
                                  </div>
                                )} */}
                              </div>
                              <button
                                onClick={handlePhoneEdit}
                                style={{
                                  backgroundColor: 'transparent',
                                  color: '#1E3A8A',
                                  border: '1px solid #1E3A8A',
                                  borderRadius: '4px',
                                  padding: '6px 12px',
                                  cursor: 'pointer',
                                  fontSize: '14px'
                                }}
                              >
                                {user.phoneNumber ? 'Change' : 'Add Phone Number'}
                              </button>
                            </div>
                            
                            {/* Verification UI - Only show when a phone number exists, isn't verified, and not in edit mode */}
                            {!user.phoneVerified && user.phoneNumber && (
                              <div className="verification-controls" style={{ marginTop: '15px' }}>
                                <button 
                                  onClick={handleStartVerification}
                                  className="verify-button"
                                  style={{
                                    padding: '10px 15px',
                                    borderRadius: '4px',
                                    border: 'none',
                                    backgroundColor: '#1E3A8A',
                                    color: 'white',
                                    cursor: 'pointer',
                                    width: '100%',
                                    marginTop: '5px'
                                  }}
                                >
                                  Verify Phone
                                </button>
                                
                                <div style={{ fontSize: '13px', color: '#666', marginTop: '8px' }}>
                                  <IonIcon icon={timeOutline} style={{ marginRight: '5px', fontSize: '14px' }} />
                                  A verification code will be sent to your mobile phone
                                </div>
                                
                                {isVerifying && (
                                  <div className="verification-ui" style={{ marginTop: '15px' }}>
                                    {verificationSent ? (
                                      <div className="verification-code-section">
                                        <label htmlFor="verification-code" style={{ 
                                          display: 'block', 
                                          marginBottom: '8px', 
                                          fontSize: '14px', 
                                          fontWeight: '500' 
                                        }}>
                                          Enter the 6-digit code sent to your phone:
                                        </label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <input
                                            id="verification-code"
                                            type="text"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                            placeholder="123456"
                                            maxLength={6}
                                            style={{
                                              padding: '10px',
                                              borderRadius: '4px',
                                              border: '1px solid #ccc',
                                              fontSize: '16px',
                                              letterSpacing: '2px',
                                              textAlign: 'center',
                                              width: '150px',
                                              marginRight: '10px'
                                            }}
                                          />
                                          <button 
                                            onClick={handleVerifyCode}
                                            disabled={verificationLoading || verificationCode.length !== 6}
                                            style={{
                                              padding: '10px 15px',
                                              borderRadius: '4px',
                                              border: 'none',
                                              backgroundColor: verificationCode.length === 6 ? '#1E3A8A' : '#ccc',
                                              color: 'white',
                                              cursor: verificationLoading || verificationCode.length !== 6 ? 'default' : 'pointer',
                                              opacity: verificationLoading ? 0.7 : 1
                                            }}
                                          >
                                            {verificationLoading ? 'Verifying...' : 'Submit'}
                                          </button>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                          <span style={{ fontSize: '14px', color: '#666', marginRight: '10px' }}>Didn't receive code?</span>
                                          <button 
                                            onClick={handleStartVerification}
                                            disabled={verificationLoading}
                                            style={{
                                              padding: '5px 10px',
                                              borderRadius: '4px',
                                              border: '1px solid #1E3A8A',
                                              backgroundColor: 'transparent',
                                              color: '#1E3A8A',
                                              cursor: verificationLoading ? 'default' : 'pointer',
                                              fontSize: '14px'
                                            }}
                                          >
                                            Resend Code
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      <p style={{ fontSize: '14px', marginTop: '8px', color: '#666' }}>
                                        {verificationLoading ? 'Sending verification code...' : 'A verification code will be sent to your mobile phone'}
                                      </p>
                                    )}
                                    
                                    {verificationError && (
                                      <div className="error-message" style={{ 
                                        color: '#f44336', 
                                        display: 'flex', 
                                        alignItems: 'center',
                                        marginTop: '15px',
                                        padding: '10px',
                                        borderRadius: '4px'
                                      }}>
                                        <IonIcon icon={alertCircleOutline} style={{ marginRight: '5px' }} />
                                        {verificationError}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    
                    {!user.phoneVerified && !isEditingPhone ? (
                      // Show unverified message underneath collapsed view when not verified
                      <div style={{ 
                        display: 'flex',
                        alignItems: 'center',
                        color: '#ff9800',
                        fontSize: '14px',
                        fontWeight: '500',
                        marginTop: '5px' 
                      }}>
                        <IonIcon icon={alertCircleOutline} style={{ marginRight: '5px', fontSize: '16px' }} />
                        <span>Verification needed for Aeropay</span>
                      </div>
                    ) : null}
                  </div>
                )}
                  
                  {/* AeroPayWidget - now appears UNDER the phone section */}
                  {/* <AeroPayWidget
                    user={user}
                    mode="account"
                    onSuccess={fetchUserDetails}
                  />
                  
                  <SubscriptionWidget user={user} /> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AccountModal;
