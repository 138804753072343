import React from 'react';
import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart } from '../redux/actions/orderActions';
// import { checkAeroPayUserStatus } from '../redux/actions/aeropayActions';
import { fetchProducts, refreshProducts } from '../redux/actions/productActions';
import { setupUserListener } from '../utils/FirebaseUtils';
import { fetchStoresDirect } from './utils/storeUtils';
import { cacheStoreData } from '../redux/actions/storeActions';
import HomeScreen from "./pages/HomeScreen";
import QRTracker from "./components/QRTracker";
import SplashPage from "./pages/SplashPage";
import AdminPanel from "./pages/AdminPanel";
import ProtectedRoute from "./components/ProtectedRoute";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TestAeroPay from "./components/TestAeroPay";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const productsState = useSelector(state => state.products);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  // Load store data on app initialization, then load products
  useEffect(() => {
    const loadStoresAndProducts = async () => {
      try {
        // First load all stores
        const storesData = await fetchStoresDirect({ limit: 100, includeTest: true });
        
        // Cache each store in Redux
        if (storesData && storesData.stores) {
          storesData.stores.forEach(store => {
            // Make sure we're using the exact field names as they appear in Firebase
            dispatch(cacheStoreData(store.id, {
              id: store.id,
              name: store.name || "Select Store",
              address: store.address || "",
              imageUrl: store.imageUrl || store.image_url || store.logo_url || "",
              hours: store.hours,
              delivery: store.delivery,
              pickup: store.pickup,
              latitude: store.latitude || (store.location?.lat || 0),
              longitude: store.longitude || (store.location?.lng || 0)
            }));
          });
          console.log(`Cached ${storesData.stores.length} stores in Redux before loading products`);
        }
        
        // Then fetch products
        dispatch(fetchProducts(true));
      } catch (error) {
        console.error('Error loading stores and products:', error);
        // Still try to load products even if store loading failed
        dispatch(fetchProducts(true));
      }
    };
    
    loadStoresAndProducts();
  }, [dispatch]);

  // Periodically check for product updates
  useEffect(() => {
    // Check for product updates every 15 minutes
    const REFRESH_INTERVAL = 15 * 60 * 1000; // 15 minutes
    let userActive = true;
    
    // Track if the user is active
    const handleUserActivity = () => {
      userActive = true;
    };
    
    // Add event listeners to track user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);
    
    const refreshInterval = setInterval(() => {
      // Only refresh if user is active and products were previously loaded
      if (userActive && productsState.lastFetched) {
        // Calculate time since last fetch (convert ISO string to timestamp)
        const lastFetchedTime = new Date(productsState.lastFetched).getTime();
        const timeSinceLastFetch = Date.now() - lastFetchedTime;
        
        // Only refresh if it's been at least 5 minutes since the last fetch
        if (timeSinceLastFetch > 5 * 60 * 1000) {
          dispatch(refreshProducts())
            .then(products => {
            })
            .catch(err => {
            });
          
          // Reset user activity flag after refresh attempt
          userActive = false;
          
          // Reset after 1 minute of inactivity
          setTimeout(() => {
            userActive = true;
          }, 60000);
        } else {
        }
      }
    }, REFRESH_INTERVAL);
    
    return () => {
      clearInterval(refreshInterval);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
    };
  }, [dispatch, productsState.lastFetched]);

  // Set up real-time listener for user data updates
  useEffect(() => {
    let userListener = null;
    
    // If user is logged in, set up the listener
    if (user && user.userId) {
      userListener = setupUserListener(user.userId);
    }
    
    // Clean up listener when component unmounts or user changes
    return () => {
      if (userListener) {
        userListener();
      }
    };
  }, [user?.userId]); // Only re-run if userId changes

  // Check AeroPay status and load bank accounts early in the app lifecycle
  useEffect(() => {
    if (user?.aeroPayUserId) {
      // Check localStorage for cached bank accounts
      try {
        const cachedData = localStorage.getItem('aeropay_banks_data');
        const cachedTimestamp = localStorage.getItem('aeropay_banks_timestamp');
        
        if (cachedData && cachedTimestamp) {
          const banks = JSON.parse(cachedData);
          const timestamp = parseInt(cachedTimestamp, 10);
          const ageInHours = (Date.now() - timestamp) / (1000 * 60 * 60);
          
          // Use cache if less than 4 hours old
          if (ageInHours < 4 && banks.length > 0) {
            // Update Redux from cache
            dispatch({
              type: 'SET_AEROPAY_BANKS', 
              payload: {
                banks: banks,
                timestamp: timestamp
              }
            });
            
            // Skip API call
            return;
          }
        }
      } catch (e) {
      }
      
      // dispatch(checkAeroPayUserStatus())
      //   .then(result => {
      //   })
      //   .catch(err => {
      //   });
    }
  }, [user?.aeroPayUserId, dispatch]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (user && user.userId) {
      dispatch(fetchCart(user.userId));
    }
  }, [dispatch, user.userId]);

  return (
    <QueryClientProvider client={queryClient}>
      <QRTracker />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route 
          path="/adminpanel" 
          element={
            <ProtectedRoute requiredRole="admin">
              <AdminPanel />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/test-aeropay" 
          element={
            <ProtectedRoute requiredRole="admin">
              <TestAeroPay />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
