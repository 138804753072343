import axios from 'axios';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { calculateStoreDiscount } from './discountProcessor';

// Updated API URL for product data from environment variable
// Updated API URL for product data from environment variable
const PRODUCT_API_URL = import.meta.env.VITE_PRODUCT_API_URL || "https://stashpandas24.web.app/api/products/data";

// Track the loading promise to prevent duplicate requests
let loadingPromise = null;

// Cache expiry time (30 minutes)
const CACHE_EXPIRY_TIME = 30 * 60 * 1000;

/**
 * Save product data to localStorage
 */
function saveToLocalStorage(data) {
  try {
    localStorage.setItem('cachedProductData', JSON.stringify({
      data,
      timestamp: Date.now()
    }));
  } catch (error) {
    console.error('Error saving to localStorage:', error);
  }
}

/**
 * Get product data from localStorage
 */
function getFromLocalStorage() {
  try {
    const cached = localStorage.getItem('cachedProductData');
    if (!cached) return null;
    
    const { data, timestamp } = JSON.parse(cached);
    
    // Check if cache is expired (30 minutes)
    if (Date.now() - timestamp > CACHE_EXPIRY_TIME) {
      localStorage.removeItem('cachedProductData');
      return null;
    }
    
    return data;
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return null;
  }
}

/**
 * Make an object serializable by removing functions and circular references
 */
function makeSerializable(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Fetches product data with simplified flow:
 * 1. Check localStorage
 * 2. Fetch from API endpoint
 * 
 * @returns {Promise<Object>} The product data
 */
export const fetchProductData = async (forceRefresh = false) => {
  // Return cached promise if loading is in progress
  if (loadingPromise && !forceRefresh) {
    return loadingPromise;
  }
  
  // First check localStorage (unless forced refresh)
  if (!forceRefresh) {
    const cachedData = getFromLocalStorage();
    if (cachedData) {
      return cachedData;
    }
  }
  
  // Create a new loading promise
  loadingPromise = new Promise(async (resolve, reject) => {
    try {      
      // Use the new proxy API endpoint (which handles all error cases)
      const response = await axios.get(PRODUCT_API_URL);
      
      // Handle different response structures
      let products = {};
      
      if (response.data && typeof response.data === 'object') {
        if (response.data.products) {
          // New format: { products: {...} }
          products = response.data.products;
        } else {
          // Traditional format: direct object of product maps
          products = response.data;
        }
      }
      
      // Log a sample product to debug the raw data
      const productIds = Object.keys(products);
      if (productIds.length > 0) {
        const sampleId = productIds[0];
        const sampleProduct = products[sampleId];
        
        // Log a sample store if available
        if (sampleProduct.stores) {
          const storeIds = Object.keys(sampleProduct.stores);
          if (storeIds.length > 0) {
            const sampleStoreId = storeIds[0];
            const sampleStore = sampleProduct.stores[sampleStoreId];
          }
        }
      }
      
      // // Cache data in localStorage
      // saveToLocalStorage(products);
      
      resolve(products);
    } catch (error) {
      console.error('Error fetching product data:', error);
      reject(error);
    } finally {
      // Reset loading promise regardless of outcome
      loadingPromise = null;
    }
  });
  
  return loadingPromise;
};




/**
 * Calculate discounted price using the optimized discount processor
 */
function calculateDiscountedPrice(storeData) {
  if (!storeData) return 0;
  
  // Use the optimized calculateStoreDiscount function
  const { price } = calculateStoreDiscount({
    ...storeData,
    store_id: storeData.store_id || storeData.storeId
  });
  
  return price;
}

/**
 * Calculate discount percentage using the optimized discount processor
 */
function calculateDiscountPercent(storeData) {
  if (!storeData) return 0;
  
  const originalPrice = storeData.price || 0;
  if (originalPrice === 0) return 0;
  
  // Use the optimized calculateStoreDiscount function
  const { price: discountedPrice, discountInfo } = calculateStoreDiscount({
    ...storeData,
    store_id: storeData.store_id || storeData.storeId
  });
  
  // If we have discount info with a percent, use it directly
  if (discountInfo) {
    return discountInfo.savings_percent;
  }
  
  // Skip if no actual discount
  if (discountedPrice >= originalPrice) {
    return 0;
  }
  
  // Calculate percentage
  return Math.round(((originalPrice - discountedPrice) / originalPrice) * 100);
}

/**
 * Transform raw CDN data into a normalized product structure
 * 
 * @param {string} productId - Product ID
 * @param {Object} productData - Raw product data from CDN
 * @param {Object} [cachedStores={}] - Optional cached store data from Redux
 * @returns {Object} - Complete normalized product without methods (serializable)
 */
export const transformProductData = async (productId, productData, cachedStores = {}) => {
  // Skip if invalid data
  if (!productId || !productData) {
    console.warn('Invalid product data received:', productId);
    return null;
  }
  

  
  // Extract base product data with defaults
  const {
    name = 'Unknown Product',
    description = '',
    brand = '',
    category_type = '',
    thc = 0,
    cbd = 0,
    images = [],
    classification = '',
    subtype = '',
    amount = '',
    uom = '', // unit of measure
    active = true,
    updated_at = null,
    sp = null // Standard properties object
  } = productData;
  
  // Extract stores data
  const stores = productData.stores || {};
  const availableStoreIds = Object.keys(stores).filter(storeId => {
    const storeData = stores[storeId];
    // Only count stores with inventory or no inventory tracking
    return storeData && (
      typeof storeData.sellable_quantity !== 'number' || 
      storeData.sellable_quantity > 0
    );
  });
  
  // Skip products not available at any store
  if (availableStoreIds.length === 0) {
    return null;
  }
  
  // ---------------------------------------------------------
  // Build product data following the hierarchy:
  // 1. Check SP object first
  // 2. Then first available store's product_configurable_fields
  // 3. Finally fall back to base product data
  // ---------------------------------------------------------
  
  // Get the first available store for fallback data
  const firstStoreId = availableStoreIds[0];
  const firstStoreData = stores[firstStoreId] || {};
  const storeFields = firstStoreData.product_configurable_fields || {};
  
  // Extract the sp.attributes object for easier access
  const spAttributes = sp && sp.attributes ? sp.attributes : {};
  
  // Extract product fields following the preference hierarchy
  const productName = 
    (sp && sp.name) || 
    storeFields.name || 
    (name !== 'Unknown Product' ? name : null) || 
    'Unknown Product';
    
  const productBrand = 
    (sp && sp.brand) || 
    storeFields.brand || 
    brand || 
    '';
    
  const productCategory = 
    (sp && sp.category_type) || 
    firstStoreData.category_type || 
    category_type || 
    '';
    
  const productDescription = 
    (sp && sp.description) || 
    storeFields.description || 
    description || 
    '';
    
  // Handle both images array and single imageUrl from sp
  const productImages = 
    (sp && sp.images && sp.images.length > 0) ? sp.images : 
    (sp && sp.imageUrl) ? [sp.imageUrl] : 
    images || 
    [];
    
  // Handle cannabinoid content with proper fallbacks
  // First check sp.attributes.thc object, then fallback to other properties
  const productThc = 
    (spAttributes.thc && spAttributes.thc.mg) ? parseFloat(spAttributes.thc.mg) || 0 : 
    (sp && sp.amount) ? parseFloat(sp.amount) || 0 : 
    storeFields.total_mg_thc || 
    thc || 
    0;
    
  const productCbd = 
    (spAttributes.cbd && spAttributes.cbd.mg) ? parseFloat(spAttributes.cbd.mg) || 0 : 
    storeFields.total_mg_cbd || 
    cbd || 
    0;
    
  const productClassification = 
    (sp && sp.classification) || 
    storeFields.classification || 
    classification || 
    '';
    
  const productSubtype = 
    (sp && sp.subtype) || 
    storeFields.subtype || 
    subtype || 
    '';
    
  const productAmount = 
    (sp && sp.amount) || 
    storeFields.amount || 
    storeFields.size || 
    amount || 
    '';
    
  const productUom = 
    (sp && sp.uom) || 
    storeFields.uom || 
    uom || 
    '';
    
  // Handle strain cross
  const strainCross = 
    (sp && sp.strain_cross) || 
    storeFields.strain_cross || 
    '';
    
  // Handle dose information
  const amountPerDose = 
    (sp && sp.amount_per_dose) || 
    storeFields.mg_per_dose || 
    0;
    
  const dosesUnits = 
    (sp && sp.doses_units) || 
    storeFields.doses || 
    undefined;
  
  // Special properties with proper fallbacks - prioritize sp.attributes
  const effects = 
    (spAttributes.effects) ? spAttributes.effects : 
    (sp && sp.effects) ? sp.effects : 
    (storeFields.effects) ? storeFields.effects : 
    [];
    
  const flavors = 
    (spAttributes.flavors) ? spAttributes.flavors : 
    (sp && sp.flavors) ? sp.flavors : 
    (storeFields.flavors) ? storeFields.flavors : 
    [];
    
  const activities = 
    (spAttributes.activities) ? spAttributes.activities : 
    (sp && sp.activities) ? sp.activities : 
    (storeFields.activities) ? storeFields.activities : 
    [];
    
  const ingredients = 
    (spAttributes.ingredients) || 
    '';
    
  const instructions = 
    (spAttributes.instructions) || 
    '';
  
  // Helper function for consistent cannabinoid handling
  const processCannabinoidsValue = (spAttribute, defaultValue = 0) => {
    const value = parseFloat(spAttribute?.mg) || defaultValue;
    
    return {
      mg: isMgBased ? value : 0,
      percentage: !isMgBased ? value.toString() : (spAttribute?.percentage || '')
    };
  };
  
  // Determine if this product is measured in mg or percentage
  const isMgBased = ['EDIBLE', 'EXTRACT', 'CONCENTRATE', 'TINCTURE'].includes(productCategory);
  
  // Prioritize main cannabinoids (THC/CBD) which have product-level values
  const cannabinoids = {
    thc: {
      mg: isMgBased ? productThc : 0,
      percentage: !isMgBased ? productThc.toString() : (spAttributes.thc && spAttributes.thc.percentage) || ''
    },
    cbd: {
      mg: isMgBased ? productCbd : 0,
      percentage: !isMgBased ? productCbd.toString() : (spAttributes.cbd && spAttributes.cbd.percentage) || ''
    },
    thcv: processCannabinoidsValue(spAttributes.thcv),
    cbc: processCannabinoidsValue(spAttributes.cbc),
    cbg: processCannabinoidsValue(spAttributes.cbg),
    cbn: processCannabinoidsValue(spAttributes.cbn),
    thca: processCannabinoidsValue(spAttributes.thca),
    delta9: processCannabinoidsValue(spAttributes.delta9)
  };
  
  const weights = 
    (sp && sp.weights) ? sp.weights : 
    null;
  
  // Create normalized product structure WITHOUT helper methods
  const normalizedProduct = {
    id: productId,
    name: productName,
    description: productDescription,
    brand: productBrand,
    category_type: productCategory,
    updated_at: serializeTimestamp(updated_at),
    thc: productThc,
    cbd: productCbd,
    images: productImages,
    // Add imageUrl as a direct property - many UI components look for this
    imageUrl: productImages && productImages.length > 0 ? productImages[0] : null,
    classification: standardizeClassification(productClassification),
    subtype: productSubtype,
    amount: productAmount,
    uom: productUom,
    active,
    
    // Additional standardized fields
    strain_cross: strainCross,
    amount_per_dose: amountPerDose,
    doses_units: dosesUnits,
    ingredients,
    instructions,
    cannabinoids,
    
    // Store data
    stores: {},
    availableStoreIds,
    bestPriceStoreId: null, // Will be populated after checking all stores
    
    // Additional fields 
    effects,
    flavors,
    activities,
    weights,
  };
  
  // Track the best price while populating store data
  let lowestPrice = Infinity;
  
  // Track discounts for logging
  let discountedStoreCount = 0;
  let totalSavings = 0;
  let discountTypes = {};
  let discountLogs = [];

  // Add store data to each store in the product
  for (const storeId in normalizedProduct.stores) {
    // Get store data from cache if available
    const storeData = cachedStores[storeId] || {};
    
    // Add store metadata to the store object in product
    normalizedProduct.stores[storeId] = {
      ...normalizedProduct.stores[storeId],
      // Use the exact matching field names from Firebase
      name: storeData.name || "Select Store",
      address: storeData.address || "",
      imageUrl: storeData.imageUrl || ""
    };
  }

  // Populate store-specific data for each available store
  availableStoreIds.forEach(storeId => {
    const storeData = stores[storeId];
    if (!storeData) return;
    
    // Extract product fields from store data
    const productFields = storeData.product_configurable_fields || {};
    const price = storeData.price || 0;
    const sellableQuantity = typeof storeData.sellable_quantity === 'number' ? storeData.sellable_quantity : 0;
    
    // Calculate discounted price using the optimized store discount calculator
    const { price: discountedPrice, discountInfo } = calculateStoreDiscount({
      ...storeData,
      store_id: storeId
    });
    
    const discountPercent = discountInfo ? discountInfo.savings_percent : 0;
    
    // Track discount information for logging
    if (discountInfo) {
      discountedStoreCount++;
      const savings = price - discountedPrice;
      totalSavings += savings;
      
      // Track discount types
      const discountTitle = discountInfo.title || 'Unknown Discount';
      discountTypes[discountTitle] = (discountTypes[discountTitle] || 0) + 1;
      
      // Create log entry
      discountLogs.push({
        product_id: productId,
        product_name: normalizedProduct.name,
        store_id: storeId,
        original_price: price,
        discounted_price: discountedPrice,
        savings: savings.toFixed(2),
        discount_title: discountTitle,
        discount_percent: discountPercent
      });
    }
    
    // Check if this is the lowest price so far
    if (discountedPrice > 0 && discountedPrice < lowestPrice) {
      lowestPrice = discountedPrice;
      normalizedProduct.bestPriceStoreId = storeId;
    }
    
    normalizedProduct.stores[storeId] = {
      store_id: storeId,
      
      // Store metadata (to avoid separate Firestore calls)
      name: storeData.name || "Select Store",
      address: storeData.address || "",
      imageUrl: storeData.imageUrl || "",
      
      // Price data
      price: price,
      original_price: price,
      discounted_price: discountedPrice,
      discount_percent: discountPercent,
      pricing: {
        price_sell: price,
        discounted_price: discountedPrice,
        price_type: "FLAT",
        discount_percent: discountPercent
      },
      
      // CRITICAL: Preserve the Treez productId
      productId: storeData.productId || null,
      
      // Inventory
      sellable_quantity: sellableQuantity,
      
      // Store-specific overrides (null if using shared product data)
      name: productFields.name || null,
      description: productFields.description || null,
      
      // Store-specific metadata
      category_type: storeData.category_type || productCategory,
      total_mg_thc: productFields.total_mg_thc || productThc,
      total_mg_cbd: productFields.total_mg_cbd || productCbd,
      brand: productFields.brand || productBrand,
      classification: standardizeClassification(productFields.classification || productClassification),
      subtype: productFields.subtype || productSubtype,
      amount: productFields.amount || productFields.size || productAmount,
      uom: productFields.uom || productUom,
      
      // Include any discounts
      discounts: storeData.discounts || []
    };
  });
  
  // If we didn't find a best price (all prices were 0), use the first available store
  if (normalizedProduct.bestPriceStoreId === null && availableStoreIds.length > 0) {
    normalizedProduct.bestPriceStoreId = availableStoreIds[0];
  }
  
  return normalizedProduct;
};

/**
 * Helper to standardize classification format (first letter uppercase, rest lowercase)
 */
const standardizeClassification = (classification) => {
  if (!classification) return classification;
  return classification.charAt(0).toUpperCase() + classification.slice(1).toLowerCase();
};

/**
 * Helper to serialize timestamps to ISO strings
 */
const serializeTimestamp = (value) => {
  if (!value) return value;
  
  // Convert Timestamp objects to ISO strings
  if (value._seconds !== undefined && value._nanoseconds !== undefined) {
    return new Date(value._seconds * 1000).toISOString();
  }
  
  // If it's a Date object, convert to ISO string
  if (value instanceof Date) {
    return value.toISOString();
  }
  
  return value;
};

/**
 * Utility function to check if a product is available at a store
 * @param {Object} product - Product object
 * @param {string} storeId - Store ID to check
 * @returns {boolean} - Whether the product is available at the store
 */
export const isProductAvailableAt = (product, storeId) => {
  return product && product.availableStoreIds && product.availableStoreIds.includes(storeId);
};

/**
 * Utility function to get the lowest price for a product
 * @param {Object} product - Product object
 * @returns {number} - Lowest price or 0 if not available
 */
export const getProductLowestPrice = (product) => {
  if (!product || !product.availableStoreIds || !product.stores) return 0;
  
  return product.availableStoreIds.reduce((lowestPrice, storeId) => {
    const storeData = product.stores[storeId];
    const price = storeData?.discounted_price || storeData?.price || 0;
    return price > 0 && (price < lowestPrice || lowestPrice === 0) ? price : lowestPrice;
  }, 0);
};

/**
 * Utility function to get the best store for a product
 * @param {Object} product - Product object
 * @param {string|null} selectedStoreId - User selected store ID (optional)
 * @returns {string|null} - Best store ID
 */
export const getProductBestStore = (product, selectedStoreId = null) => {
  if (!product || !product.availableStoreIds || !product.stores) return null;
  
  // If user has selected a store and product is available there, use it
  if (selectedStoreId && isProductAvailableAt(product, selectedStoreId)) {
    return selectedStoreId;
  }
  
  // Otherwise find store with inventory and lowest price
  return product.availableStoreIds.reduce((bestStoreId, storeId) => {
    const storeData = product.stores[storeId];
    if (!storeData) return bestStoreId;
    
    const currentPrice = storeData.price || 0;
    const bestPrice = bestStoreId ? (product.stores[bestStoreId]?.price || Infinity) : Infinity;
    
    return currentPrice < bestPrice ? storeId : bestStoreId;
  }, null) || (product.availableStoreIds[0] || null); // Fallback to first store
};

/**
 * Load all products with pagination, using cached data when available
 * @param {Object} [cachedStores={}] - Optional cached store data from Redux
 * @param {number} [page=1] - Page number (1-based)
 * @param {number} [pageSize=10000] - Number of items per page
 * @param {boolean} [forceRefresh=false] - Whether to bypass cache and force a refresh
 * @returns {Promise<Object>} - Object with products array, total count, and hasMore flag
 */
export const loadAllProducts = async (cachedStores = {}, page = 1, pageSize = 10000, forceRefresh = false) => {
  try {
    // Check for cached transformed products first
    const cachedTransformedProducts = getTransformedProductsFromCache();
    if (!forceRefresh && cachedTransformedProducts && cachedTransformedProducts.products) {
      
      // Process products to ensure valid store data
      const processedProducts = cachedTransformedProducts.products.map(product => {
        if (product.stores && !product.availableStoreIds) {
          product.availableStoreIds = Object.keys(product.stores);
        }
        return product;
      });
      
      // Sort cached products by image first, then alphabetically
      processedProducts.sort((a, b) => 
        Boolean(a.imageUrl) === Boolean(b.imageUrl) ? a.name.localeCompare(b.name) : Boolean(a.imageUrl) ? -1 : 1
      );
      
      // Apply pagination to cached transformed products
      const total = processedProducts.length;
      const startIndex = (page - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize, total);
      const paginatedProducts = processedProducts.slice(startIndex, endIndex);
      
      return {
        products: paginatedProducts,
        total,
        hasMore: endIndex < total
      };
    }
    
    // Clear localStorage cache if force refresh
    if (forceRefresh) {
      localStorage.removeItem('cachedProductData');
      localStorage.removeItem('cachedTransformedProducts');
    }
    
    // Fetch all product data
    const productData = await fetchProductData();
    
    // Exit early if there's no data
    if (!productData || Object.keys(productData).length === 0) {
      console.warn('No product data available from cache or server');
      return { products: [], hasMore: false, total: 0 };
    }
 
    console.log(`Using cached store data for ${Object.keys(cachedStores).length} stores when transforming products`);
    
    // Transform all products
    const allProductIds = Object.keys(productData);
    const total = allProductIds.length;
    
    // Transform each product
    const allTransformedProducts = [];
    for (const productId of allProductIds) {
      const rawProductData = productData[productId];
      const transformedProduct = await transformProductData(productId, rawProductData, cachedStores);
      
      if (transformedProduct) {
        allTransformedProducts.push(transformedProduct);
      }
    }
    
    // Cache all transformed products
    // saveTransformedProductsToCache(allTransformedProducts);
    
    // Sort: products with images first, then alphabetically
    allTransformedProducts.sort((a, b) => 
      Boolean(a.imageUrl) === Boolean(b.imageUrl) ? a.name.localeCompare(b.name) : Boolean(a.imageUrl) ? -1 : 1
    );
    
    // Paginate for this request
    const startIndex = (page - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, allTransformedProducts.length);
    const paginatedProducts = allTransformedProducts.slice(startIndex, endIndex);
    
    return {
      products: paginatedProducts,
      total: allTransformedProducts.length,
      hasMore: endIndex < allTransformedProducts.length
    };
  } catch (error) {
    console.error('Error in loadAllProducts:', error);
    return { products: [], total: 0, hasMore: false };
  }
};

/**
 * Save transformed products to localStorage
 */
function saveTransformedProductsToCache(transformedProducts) {
  try {
    // Before saving, ensure all products have required fields for store display
    const productsToSave = transformedProducts.map(product => {
      // Make sure all store data is properly structured
      if (product.stores && Object.keys(product.stores).length > 0) {
        if (!product.availableStoreIds || !product.availableStoreIds.length) {
          product.availableStoreIds = Object.keys(product.stores);
        }
      }
      return product;
    });
    
    // Serialize to JSON to check size
    const jsonData = JSON.stringify({
      products: productsToSave,
      timestamp: Date.now()
    });
    
    // Check if data is too large (close to localStorage limit)
    if (jsonData.length > 4 * 1024 * 1024) {
      console.warn('Transformed products too large for localStorage, saving essential fields only');
      
      // Create a minimal version with only essential display fields
      const essentialProducts = productsToSave.map(product => ({
        id: product.id,
        name: product.name,
        brand: product.brand,
        category: product.category,
        category_type: product.category_type,
        imageUrl: product.imageUrl,
        price: product.price,
        discountedPrice: product.discountedPrice,
        effects: product.effects,
        activities: product.activities,
        flavors: product.flavors,
        description: product.description,
        cannabinoids: product.cannabinoids,
        availableStoreIds: product.availableStoreIds,
        stores: product.stores
      }));
            
      localStorage.setItem('cachedTransformedProducts', JSON.stringify({
        products: essentialProducts,
        timestamp: Date.now(),
        isEssentialOnly: true
      }));
      
    } else {
      // Store full transformed products
      localStorage.setItem('cachedTransformedProducts', jsonData);
    }
  } catch (error) {
    console.error('Error saving transformed products to localStorage:', error);
    if (error.name === 'QuotaExceededError') {
      console.warn('localStorage quota exceeded, clearing cache');
      localStorage.removeItem('cachedTransformedProducts');
    }
  }
}

/**
 * Get transformed products from localStorage
 */
function getTransformedProductsFromCache() {
  try {
    const cached = localStorage.getItem('cachedTransformedProducts');
    if (!cached) return null;
    
    const cachedData = JSON.parse(cached);
    const { products, timestamp, isEssentialOnly } = cachedData;
    
    // Check if cache is expired
    if (Date.now() - timestamp > CACHE_EXPIRY_TIME) {
      localStorage.removeItem('cachedTransformedProducts');
      return null;
    }
    
    return cachedData;
  } catch (error) {
    console.error('Error reading transformed products from localStorage:', error);
    localStorage.removeItem('cachedTransformedProducts');
    return null;
  }
}

/**
 * Filters products by store ID
 * @param {Array} products - Array of products from loadAllProducts
 * @param {string} storeId - ID of the store to filter by
 * @returns {Array} - Products available at the specified store
 */
export const filterProductsByStore = (products, storeId) => {
  if (!products || !storeId) {
    return [];
  }
  
  return products.filter(product => isProductAvailableAt(product, storeId));
};

/**
 * Gets a list of all stores where products are available
 * @param {Array} products - Array of products from loadAllProducts
 * @returns {Array} - Array of unique store IDs
 */
export const getAvailableStores = (products) => {
  if (!products || products.length === 0) {
    return [];
  }
  
  // Get all store IDs from all products
  const storeIds = new Set();
  
  products.forEach(product => {
    if (product.availableStoreIds) {
      product.availableStoreIds.forEach(storeId => storeIds.add(storeId));
    }
  });
  
  return Array.from(storeIds);
};

/**
 * Loads a single product by ID
 * @param {string} productId - ID of the product to load
 * @returns {Promise<Object|null>} - The product or null if not found
 */
export const loadProductById = async (productId) => {
  try {
    // Fetch all product data
    const cdnData = await fetchProductData();
    
    // Exit early if there's no data or product not found
    if (!cdnData || !cdnData[productId]) {
      console.warn(`Product ${productId} not found in CDN data`);
      return null;
    }
    
    // Transform the product
    return await transformProductData(productId, cdnData[productId]);
  } catch (error) {
    console.error(`Error loading product ${productId}:`, error);
    return null;
  }
};

// Export utility functions
export default {
  loadAllProducts,
  loadProductById,
  filterProductsByStore,
  getAvailableStores,
  isProductAvailableAt,
  getProductLowestPrice,
  getProductBestStore
};