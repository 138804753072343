import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../../firebase';
import { addDoc, collection, serverTimestamp, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import { IonIcon, IonSpinner } from '@ionic/react';
import { 
  caretDown, 
  caretUp, 
  cardOutline, 
  helpCircleOutline,
  closeCircleOutline,
  checkmarkCircleOutline,
  alertCircleOutline,
  timeOutline,
  chevronDownOutline,
  chevronUpOutline
} from 'ionicons/icons';

import { formatUOM } from '../utils/formatUtils';

import PurchasePaymentMethod from './PurchasePaymentMethod';
import LocationFull from "./LocationFull";
import ConfirmButton from "./ConfirmButton";
import CancelButton from "./CancelButton";
import QuantityCounter from "./QuantityCounter";
import VerificationForm from "./VerificationForm";
import ModalHeader from "./ModalHeader";
import ItemDetailsModal from "./ItemDetailsModal";
import OrderDetails from "./OrderDetails";
import PortalPopup from "./PortalPopup";

import { updateCartItemQuantity, addProductToCart, createOrder, removeFromCart, clearBuyNow, updateBuyNowQuantity } from '../../redux/actions/orderActions';
import { fetchAeroPayBanks, createAeroPayTransaction, pollTransactionStatus } from '../../redux/actions/aeropayActions';

import { previewTreezTicket, createTreezTicket } from '../../utils/TreezService';
import { getSortedCannabinoids, getCannabinoidDisplayName } from '../utils/cannabinoidUtils';

import { useOrders } from '../hooks/useOrders';

import "./BuyNowModal.css";

const BuyNowModal = ({ onClose, product, currentPrice, originalPrice, onOpenItemDetails, onPurchaseComplete, initialQuantity = 1 }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const cartItems = useSelector(state => state.order.cart);
  const buyNowState = useSelector(state => state.order.buyNow);
  
  // Initialize state
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState(false);
  const [isOrderDetailsExpanded, setIsOrderDetailsExpanded] = useState(false);
  const [orderTotal, setOrderTotal] = useState("0.00");
  const [orderSubtotal, setOrderSubtotal] = useState("0.00");
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const { addOrder } = useOrders(user?.userId);

  // Create a cart item for use in order creation
  const buyNowItem = useMemo(() => {
    if (!buyNowState.product) return null;
    
    return {
      id: buyNowState.product.id,
      name: buyNowState.product.name,
      price: currentPrice || buyNowState.product.discountedPrice || buyNowState.product.pricing?.discounted_price || buyNowState.product.price,
      regularPrice: originalPrice || buyNowState.product.price || buyNowState.product.pricing?.price_sell,
      quantity: buyNowState.quantity,
      product_id: buyNowState.product.productId || buyNowState.product.id, // Will be updated with Treez ID
      store_id: buyNowState.product.store_id || (buyNowState.product.store_data?.store_id),
      imageUrl: buyNowState.product.imageUrl || buyNowState.product.image_url,
      store_data: buyNowState.product.store_data || {
        store_id: buyNowState.product.store_id,
        name: buyNowState.product.store_name || 'Unknown Store'
      }
    };
  }, [buyNowState, currentPrice, originalPrice]);

  // Use quantity from buyNowState if available, or fallback to initialQuantity
  const [quantity, setQuantity] = useState(buyNowState.quantity || initialQuantity);

  // Keep our local quantity in sync with Redux buyNow state
  useEffect(() => {
    if (buyNowState.product && buyNowState.quantity) {
      setQuantity(buyNowState.quantity);
    }
  }, [buyNowState]);

  // Clean up: clear buyNow state when component unmounts
  useEffect(() => {
    return () => {
      dispatch(clearBuyNow());
    };
  }, [dispatch]);

  // Initialize purchaseStatus based on user verification
  useEffect(() => {
    // Always set purchaseStatus to true for testing
    setPurchaseStatus(true);
    
    // Original condition - commented out for now until we fix the verification flow
    // if (user && user.purchaseStatus) {
    //   setPurchaseStatus(true);
    // }
  }, [user]);

  // Add to buyNow when modal opens if not already in buyNow
  useEffect(() => {
    if (!buyNowState.product) {
      dispatch(updateBuyNowQuantity(initialQuantity));
    }
  }, []);

  // Calculate total including taxes
  const calculateTotal = () => {
    const price = currentPrice || product.discountedPrice || (product.pricing?.discounted_price) || 0;
    const subtotal = price * quantity;
    const laCannabisRate = 0;
    const caExciseRate = 0;
    const salesTaxRate = 0;

    const laCannabisCity = subtotal * laCannabisRate;
    const caExcise = subtotal * caExciseRate;
    const salesTax = subtotal * salesTaxRate;
    const total = subtotal + laCannabisCity + caExcise + salesTax;

    return {
      subtotal: parseFloat(subtotal.toFixed(2)),
      total: parseFloat(total.toFixed(2))
    };
  };

  // Update total when quantity changes
  useEffect(() => {
    const { subtotal, total } = calculateTotal();
    setOrderSubtotal(subtotal);
    setOrderTotal(total);
  }, [quantity, product.pricing]);

  // Check if product is undefined
  if (!product) {
    setError('Product is undefined in BuyNowModal');
    return null;
  }

  // Handle quantity changes
  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
    dispatch(updateBuyNowQuantity(newQuantity));
  };

  // Close modal and clear buy now state
  const handleClose = () => {
    dispatch(clearBuyNow());
    onClose();
  };

  const handleVerifyAccount = (formData) => {
    // For now, just log the form data
    handleVerificationSuccess();
  };

  const handleVerificationSuccess = () => {
    setPurchaseStatus(true);
    setShowVerificationForm(false);
  };

  const handleConfirmPurchaseNew = async () => {
    setIsLoading(true);
    setError(null);
    try {
      
      // Get the store-specific Treez product ID
      let treezProductId = product.id; // Default fallback to canonical key
      
      try {
        const storeId = product.store_id || (product.store_data?.store_id);
        
        if (storeId) {
          
          // Query for the document where canonicalKey matches the product.id
          const productsRef = collection(db, 'productsCatalog');
          const q = query(productsRef, where('canonicalKey', '==', product.id));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            // Get the first matching document
            const productDoc = querySnapshot.docs[0];
            const productDocId = productDoc.id;
            
            // Now get the store-specific document from the stores subcollection
            const storeDocRef = doc(db, 'productsCatalog', productDocId, 'stores', storeId);
            const storeDoc = await getDoc(storeDocRef);
            
            if (storeDoc.exists()) {
              const storeData = storeDoc.data();
              // Extract the Treez productId from the store document
              if (storeData.productId) {
                treezProductId = storeData.productId;
              } else {
              }
            } else {
            }
          } else {
          }
        } else {
        }
      } catch (error) {
        console.error('Error fetching store-specific productId:', error);
      }

      // Log whether we're using a proper Treez product ID or canonical key fallback
      if (treezProductId === product.id) {
        console.warn('POTENTIAL ISSUE: Using canonical key as Treez size_id:', {
          product_id: product.id,
          treez_id: treezProductId
        });
      } else {
      }
      
      // Update the buyNowItem with the correct Treez product ID
      const updatedBuyNowItem = {
        ...buyNowItem,
        product_id: treezProductId
      };
      
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            size_id: treezProductId, // Use the Treez-specific product ID we just looked up
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };

      const result = await createTreezTicket(ticketData);

      if (previewResult.resultCode === 'SUCCESS') {
        const price = currentPrice || product.discountedPrice || (product.pricing?.discounted_price) || 0;
        const regularPrice = originalPrice || product.price || product.pricing?.price_sell || price;
        const savings = Math.max(0, regularPrice - price);
        
        // Calculate subtotal in cents
        const priceInCents = Math.round(price * 100);
        const regularPriceInCents = Math.round(regularPrice * 100);
        const savingsInCents = Math.round(savings * 100);
        const subtotalInCents = priceInCents * quantity;
        
        // Calculate taxes in cents
        const laCannabisRate = 0;
        const caExciseRate = 0;
        const salesTaxRate = 0;
        
        const laCannabisInCents = Math.round(subtotalInCents * laCannabisRate);
        const caExciseInCents = Math.round(subtotalInCents * caExciseRate);
        const salesTaxInCents = Math.round(subtotalInCents * salesTaxRate);
        const totalTaxInCents = laCannabisInCents + caExciseInCents + salesTaxInCents;
        const totalInCents = subtotalInCents + totalTaxInCents;
        
        // Create an order with our proper item details
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          store_id: product.store_id || (product.store_data?.store_id),
          store_data: product.store_data || {},
          totals: {
            currency: "USD",
            savings: savingsInCents,
            subtotal: subtotalInCents,
            taxes: {
              caExcise: caExciseInCents,
              laCannabisCity: laCannabisInCents,
              salesTax: salesTaxInCents,
              totalTax: totalTaxInCents
            },
            total: totalInCents
          },
          items: [{
            productId: product.id,
            product: product,  // Include the full product for accurate price calculations
            quantity: quantity,
            price: price,
            product_id: treezProductId, // Include the Treez product ID
            itemSubtotal: subtotalInCents,
            itemTotal: totalInCents,
            pricePerUnit: {
              amount: priceInCents,
              currency: "USD",
              regularAmount: regularPriceInCents
            },
            savings: savingsInCents,
            taxes: {
              caExcise: caExciseInCents,
              laCannabisCity: laCannabisInCents,
              salesTax: salesTaxInCents,
              totalTax: totalTaxInCents
            }
          }],
          paymentStatus: "AWAITING_PAYMENT",
          paymentMethod: "TREEZ",
          ticketData: ticketData,
          treezTicketId: previewResult.data?.ticket_id || '',
          treezOrderNumber: previewResult.data?.order_number || '',
          treezOrderStatus: previewResult.data?.order_status || '',
          treezPaymentStatus: previewResult.data?.payment_status || '',
          isDirectPurchase: true,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };

        // Important: still await the order creation, but no need to save to state
        try {
          // Pass the buyNowItem to be used in totals calculation
          const orderId = await dispatch(createOrder(orderData, previewResult, [updatedBuyNowItem]));
          addOrder(orderId, orderData);
        } catch (orderError) {
          setError(`Failed to create order: ${orderError.message || 'Unknown error'}`);
          return;
        }

        // Remove item from cart if it was added
        const cartItem = cartItems.find(item => item.id === product.id);
        if (cartItem) {
          await dispatch(removeFromCart(user.userId, product.id));
        }
        onPurchaseComplete()
        onClose();
      } else {

        setError("Ticket preview was not successful. Please try again.");
      }
    } catch (error) {
      setError("Failed to process purchase. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  const handlePreviewConfirmPurchaseNew = async () => {
    setIsLoading(true);
    setError(null);
    try {
      console.log('Processing direct purchase for product:', product);
      console.log('Using buyNowItem for order creation:', buyNowItem);
      
      // Get the store-specific Treez product ID
      let treezProductId = product.id; // Default fallback to canonical key
      
      try {
        const storeId = product.store_id || (product.store_data?.store_id);
        
        if (storeId) {
          console.log(`Fetching Treez productId for product canonical key: ${product.id} and store ${storeId}`);
          
          // Query for the document where canonicalKey matches the product.id
          const productsRef = collection(db, 'productsCatalog');
          const q = query(productsRef, where('canonicalKey', '==', product.id));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            // Get the first matching document
            const productDoc = querySnapshot.docs[0];
            const productDocId = productDoc.id;
            console.log(`Found product document with ID: ${productDocId}`);
            
            // Now get the store-specific document from the stores subcollection
            const storeDocRef = doc(db, 'productsCatalog', productDocId, 'stores', storeId);
            const storeDoc = await getDoc(storeDocRef);
            
            if (storeDoc.exists()) {
              const storeData = storeDoc.data();
              // Extract the Treez productId from the store document
              if (storeData.productId) {
                treezProductId = storeData.productId;
                console.log(`Found Treez productId in Firebase: ${treezProductId}`);
              } else {
                console.log('No productId found in store document, using fallback canonical key');
              }
            } else {
              console.log(`Store document not found for store ${storeId}`);
            }
          } else {
            console.log(`No product found with canonicalKey: ${product.id}`);
          }
        } else {
          console.log('No store ID available, using canonical key as fallback');
        }
      } catch (error) {
        console.error('Error fetching store-specific productId:', error);
      }

      // Log whether we're using a proper Treez product ID or canonical key fallback
      if (treezProductId === product.id) {
        console.warn('POTENTIAL ISSUE: Using canonical key as Treez size_id:', {
          product_id: product.id,
          treez_id: treezProductId
        });
      } else {
        console.log('Using Treez size_id for purchase:', treezProductId);
      }
      
      // Update the buyNowItem with the correct Treez product ID
      const updatedBuyNowItem = {
        ...buyNowItem,
        product_id: treezProductId
      };
      
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            size_id: treezProductId, // Use the Treez-specific product ID we just looked up
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };

      console.log('Previewing Treez ticket with data:', JSON.stringify(ticketData, null, 2));
      const previewResult = await previewTreezTicket(ticketData);
      console.log('Preview result:', previewResult);
      
      if (previewResult.resultCode === 'SUCCESS') {
        const price = currentPrice || product.discountedPrice || (product.pricing?.discounted_price) || 0;
        const regularPrice = originalPrice || product.price || product.pricing?.price_sell || price;
        const savings = Math.max(0, regularPrice - price);
        
        // Calculate subtotal in cents
        const priceInCents = Math.round(price * 100);
        const regularPriceInCents = Math.round(regularPrice * 100);
        const savingsInCents = Math.round(savings * 100);
        const subtotalInCents = priceInCents * quantity;
        
        // Calculate taxes in cents
        const laCannabisRate = 0;
        const caExciseRate = 0;
        const salesTaxRate = 0;
        
        const laCannabisInCents = Math.round(subtotalInCents * laCannabisRate);
        const caExciseInCents = Math.round(subtotalInCents * caExciseRate);
        const salesTaxInCents = Math.round(subtotalInCents * salesTaxRate);
        const totalTaxInCents = laCannabisInCents + caExciseInCents + salesTaxInCents;
        const totalInCents = subtotalInCents + totalTaxInCents;
        
        // Create an order with our proper item details
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          store_id: product.store_id || (product.store_data?.store_id),
          store_data: product.store_data || {},
          totals: {
            currency: "USD",
            savings: savingsInCents,
            subtotal: subtotalInCents,
            taxes: {
              caExcise: caExciseInCents,
              laCannabisCity: laCannabisInCents,
              salesTax: salesTaxInCents,
              totalTax: totalTaxInCents
            },
            total: totalInCents
          },
          items: [{
            productId: product.id,
            product: product,  // Include the full product for accurate price calculations
            quantity: quantity,
            price: price,
            product_id: treezProductId, // Include the Treez product ID
            itemSubtotal: subtotalInCents,
            itemTotal: totalInCents,
            pricePerUnit: {
              amount: priceInCents,
              currency: "USD",
              regularAmount: regularPriceInCents
            },
            savings: savingsInCents,
            taxes: {
              caExcise: caExciseInCents,
              laCannabisCity: laCannabisInCents,
              salesTax: salesTaxInCents,
              totalTax: totalTaxInCents
            }
          }],
          paymentStatus: "AWAITING_PAYMENT",
          paymentMethod: "TREEZ",
          ticketData: ticketData,
          treezTicketId: previewResult.data?.ticket_id || '',
          treezOrderNumber: previewResult.data?.order_number || '',
          treezOrderStatus: previewResult.data?.order_status || '',
          treezPaymentStatus: previewResult.data?.payment_status || '',
          isDirectPurchase: true,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };

        // Important: still await the order creation, but no need to save to state
        try {
          // Pass the buyNowItem to be used in totals calculation
          const orderId = await dispatch(createOrder(orderData, previewResult, [updatedBuyNowItem]));
          addOrder(orderId, orderData);
        } catch (orderError) {
          setError(`Failed to create order: ${orderError.message || 'Unknown error'}`);
          return;
        }

        // Remove item from cart if it was added
        const cartItem = cartItems.find(item => item.id === product.id);
        if (cartItem) {
          await dispatch(removeFromCart(user.userId, product.id));
        }
        onPurchaseComplete()
        onClose();
      } else {
        setError("Ticket preview was not successful. Please try again.");
      }
    } catch (error) {
      setError("Failed to process purchase. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmPurchaseOld = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const ticketData = {
        type: "PICKUP", // or "DELIVERY" based on your app's logic
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            // location_name: "STASHPANDA",
            size_id: product.id,
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };
      
      const result = await createTreezTicket(ticketData);
      
      if (result.resultCode === 'SUCCESS') {
        // Calculate total amount
        const totalAmount = parseFloat((product.price * quantity).toFixed(2));
        
        // Create an object with all fields, filtering out undefined values
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: [
            {
              productId: product.id,
              quantity: quantity,
              price: product.price ,
            }
          ],
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: result.data?.ticket_id || '',
          treezOrderNumber: result.data?.order_number || '',
          treezOrderStatus: result.data?.order_status || '',
          treezPaymentStatus: result.data?.payment_status || '',
        };

        // Remove any fields with undefined values
        Object.keys(orderData).forEach(key => {
          if (orderData[key] === undefined) {
            delete orderData[key];
          }
        });

        // Now add the document with cleaned data
        await addDoc(collection(db, 'orders'), orderData);
        
        onPurchaseComplete && onPurchaseComplete(); // Call onPurchaseComplete before closing the modal
        onClose();
      } else {
        setError("Ticket creation was not successful. Please try again.");
      }
      
      setIsLoading(false);
    } catch (error) {
      setError("Failed to process your purchase. Please try again.");
      setIsLoading(false);
    }
  };

  const handleConfirmPurchase = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            size_id: product.id,
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };

      const result = await createTreezTicket(ticketData);
      
      if (result.resultCode === 'SUCCESS') {
        const totalAmount = parseFloat((product.price * quantity).toFixed(2));
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: [{
            productId: product.id,
            quantity: quantity,
            price: product.price,
          }],
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: result.data?.ticket_id || '',
          treezOrderNumber: result.data?.order_number || '',
          treezOrderStatus: result.data?.order_status || '',
          treezPaymentStatus: result.data?.payment_status || '',
        };

        // Important: still await the order creation, but no need to save to state
        try {
          const orderId = await dispatch(createOrder(orderData, result));
          addOrder(orderId, orderData);
        } catch (orderError) {
          setError(`Failed to create order: ${orderError.message || 'Unknown error'}`);
          return;
        }

        // Remove item from cart if it was added
        const cartItem = cartItems.find(item => item.id === product.id);
        if (cartItem) {
          await dispatch(removeFromCart(user.userId, product.id));
        }

        // onPurchaseComplete();
        onClose();
      } else {
        setError("Ticket preview was not successful. Please try again.");
      }
    } catch (error) {
      setError("Failed to process purchase. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };


  const handlePreviewConfirmPurchase = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            size_id: product.id,
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };

      const previewResult = await previewTreezTicket(ticketData);
      
      if (previewResult.resultCode === 'SUCCESS') {
        const totalAmount = parseFloat((product.price * quantity).toFixed(2));
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: [{
            productId: product.id,
            quantity: quantity,
            price: product.price,
          }],
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: previewResult.data?.ticket_id || '',
          treezOrderNumber: previewResult.data?.order_number || '',
          treezOrderStatus: previewResult.data?.order_status || '',
          treezPaymentStatus: previewResult.data?.payment_status || '',
        };

        // Important: still await the order creation, but no need to save to state
        try {
          const orderId = await dispatch(createOrder(orderData, result));
          addOrder(orderId, orderData);
        } catch (orderError) {
          setError(`Failed to create order: ${orderError.message || 'Unknown error'}`);
          return;
        }

        // Remove item from cart if it was added
        const cartItem = cartItems.find(item => item.id === product.id);
        if (cartItem) {
          await dispatch(removeFromCart(user.userId, product.id));
        }

        // onPurchaseComplete();
        onClose();
      } else {
        setError("Ticket preview was not successful. Please try again.");
      }
    } catch (error) {
      setError("Failed to process purchase. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviewConfirmPurchaseOld = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const ticketData = {
        type: "PICKUP", // or "DELIVERY" based on your app's logic
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            // location_name: "STASHPANDA",
            size_id: product.id,
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };
      
      const previewResult = await previewTreezTicket(ticketData);
      
      if (previewResult.resultCode === 'SUCCESS') {
        // Calculate total amount
        const totalAmount = parseFloat((product.price * quantity).toFixed(2));
        
        // Create an object with all fields, filtering out undefined values
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: [
            {
              productId: product.id,
              quantity: quantity,
              price: product.price ,
            }
          ],
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: previewResult.data?.ticket_id || '',
          treezOrderNumber: previewResult.data?.order_number || '',
          treezOrderStatus: previewResult.data?.order_status || '',
          treezPaymentStatus: previewResult.data?.payment_status || '',
        };

        // Remove any fields with undefined values
        Object.keys(orderData).forEach(key => {
          if (orderData[key] === undefined) {
            delete orderData[key];
          }
        });

        // Now add the document with cleaned data
        await addDoc(collection(db, 'orders'), orderData);
        
        onPurchaseComplete && onPurchaseComplete(); // Call onPurchaseComplete before closing the modal
        onClose();
      } else {
        setError("Ticket preview was not successful. Please try again.");
      }
      
      setIsLoading(false);
    } catch (error) {
      setError("Failed to process your purchase. Please try again.");
      setIsLoading(false);
    }
  };

  const handleBankSelect = useCallback((bank) => {
    setSelectedPaymentMethod({
      bankAccountId: bank.bankAccountId,
      bankName: bank.bankName,
      accountLast4: bank.accountLast4
    });
  }, []);

  const handleAeropayTestPurchase = async () => {
    if (!selectedPaymentMethod) {
      setError('Please select a payment method');
      return;
    }

    setIsLoading(true);
    setError(null);
    setTransactionStatus(null);
    setTransactionId(null);
    let latestProducts = null;
    try {
      const uuid = uuidv4();

      // Calculate proper totals with taxes
      const calculateTotals = () => {
        // Use the price directly from the product for this specific product
        const price = currentPrice || product.discountedPrice || (product.pricing?.discounted_price) || 0;
        // Calculate subtotal for just this product
        const subtotal = price * quantity;
        
        const laCannabisRate = 0.10;
        const caExciseRate = 0.15;
        const salesTaxRate = 0.0975;

        const laCannabisCity = subtotal * laCannabisRate;
        const caExcise = subtotal * caExciseRate;
        const salesTax = subtotal * salesTaxRate;
        const total = subtotal + laCannabisCity + caExcise + salesTax;

        return {
          subtotal: parseFloat(subtotal.toFixed(2)),
          laCannabisCity: parseFloat(laCannabisCity.toFixed(2)),
          caExcise: parseFloat(caExcise.toFixed(2)),
          salesTax: parseFloat(salesTax.toFixed(2)),
          total: parseFloat(total.toFixed(2))
        };
      };

      const totals = calculateTotals();

      const result = await dispatch(createAeroPayTransaction(
        [product], 
        latestProducts,
        user.userId,
        true,
        selectedPaymentMethod.bankAccountId,
        uuid,
        totals.total // Pass the full total including taxes
      ));
      
      if (result.success) {
        setTransactionId(result.transactionId);
        setTransactionStatus('processing');
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totals.total, // Using full total including taxes
          totals: {
            subtotal: Math.round(totals.subtotal * 100),
            taxes: {
              laCannabisCity: Math.round(totals.laCannabisCity * 100),
              caExcise: Math.round(totals.caExcise * 100),
              salesTax: Math.round(totals.salesTax * 100)
            },
            total: Math.round(totals.total * 100)
          },
          items: [{
            productId: product.id,
            quantity: quantity,
            price: currentPrice || product.price,
          }],
          paymentStatus: "PROCESSING",
          paymentMethod: "AEROPAY",
          isTestOrder: true,
          isDirectPurchase: true, // Add this flag for Buy Now purchases
          store_data: product.store_data || {},
          aeroPayTransaction: {
            id: uuid,
            status: result.status || 'processing',
            created: result.created || new Date().toISOString()
          },
        };

        // Important: still await the order creation, but no need to save to state
        try {
          const orderId = await dispatch(createOrder(orderData, result));
          addOrder(orderId, orderData);
        } catch (orderError) {
          setError(`Failed to create order: ${orderError.message || 'Unknown error'}`);
          return;
        }
        // Only remove this product from cart if it exists there
        const cartItem = cartItems.find(item => item.id === product.id);
        if (cartItem) {
          await dispatch(removeFromCart(user.userId, product.id));
        }
        onPurchaseComplete()
        onClose();
      } else {
        setTransactionStatus('failed');
        setError(result.error || 'Transaction failed. Please try again.');
      }
    } catch (error) {
      setError(error.message || 'Failed to process transaction');
      setTransactionStatus('failed');
    } finally {
      setIsLoading(false);
    }
  };

  const renderTransactionStatus = (transactionStatus, error, handleRetry) => {
    switch (transactionStatus) {
      case 'completed':
        return (
          <div className="transaction-status success">
            <IonIcon icon={checkmarkCircleOutline} />
            Transaction completed successfully!
          </div>
        );
      case 'failed':
        return (
          <div className="transaction-status error">
            <IonIcon icon={closeCircleOutline} />
            {error}
            <button className="retry-button" onClick={handleRetry}>
              Retry Transaction
            </button>
          </div>
        );
      case 'cancelled':
        return (
          <div className="transaction-status warning">
            <IonIcon icon={alertCircleOutline} />
            {error}
            <button className="retry-button" onClick={handleRetry}>
              Try Again
            </button>
          </div>
        );
      case 'timeout':
        return (
          <div className="transaction-status warning">
            <IonIcon icon={timeOutline} />
            {error}
            <p className="help-text">You can check your order status in your account page.</p>
          </div>
        );
      default:
        return null;
    }
  };

  const renderLoadingState = (isLoading, transactionId) => {
    if (!isLoading) return null;
    
    return (
      <div className="loading-status">
        <IonSpinner name="crescent" />
        {transactionId ? 'Processing your payment...' : 'Initializing transaction...'}
      </div>
    );
  };

  const lineageLabel = useMemo(() => {
    if (product.classification) {
      switch (product.classification.toLowerCase()) {
        case 'indica':
          return { text: 'Indica', backgroundColor: `rgba(var(--indica-color), var(--label-opacity))` };
        case 'sativa':
          return { text: 'Sativa', backgroundColor: `rgba(var(--sativa-color), var(--label-opacity))` }; 
        case 'cbd':
          return { text: 'CBD', backgroundColor: `rgba(var(--cbd-color), var(--label-opacity))` };
        case 'hybrid':
          return { text: 'Hybrid', backgroundColor: `rgba(var(--hybrid-color), var(--label-opacity))` };
        default:
          return null;
      }
    }
    return null;
  }, [product.classification]);

  return (
    <>
      <div className="buy-now-modal">
        <section className="buy-now-modal-content">
          <div className="buy-now-modal-header">
            <ModalHeader 
              onClose={handleClose} 
              title={showVerificationForm ? "Verify Account" : "Complete Purchase"}
            />
          </div>

          {showVerificationForm ? (
            <VerificationForm 
              onSubmit={handleVerifyAccount} 
              onVerificationSuccess={handleVerificationSuccess} 
            />
          ) : (
            <>
              <div className="product-info-container">
                <div 
                  className="buy-now-image-container" 
                  onClick={() => {
                    handleClose();
                    onOpenItemDetails && onOpenItemDetails(product, currentPrice, originalPrice);
                  }}
                >
                  {(product.pricing?.discounted_price && product.pricing?.price_sell && product.pricing.discounted_price < product.pricing.price_sell) ? (
                    <div className="discount-label">
                      {Math.round(((product.pricing.price_sell - product.pricing.discounted_price) / product.pricing.price_sell) * 100)}% OFF
                    </div>
                  ) : (product.discountedPrice && product.price && product.discountedPrice < product.price) && (
                    <div className="discount-label">
                      {Math.round(((product.price - product.discountedPrice) / product.price) * 100)}% OFF
                    </div>
                  )}
                  {lineageLabel && (
                    <div 
                      className="lineage-label" 
                      style={{ backgroundColor: lineageLabel.backgroundColor }}
                    >
                      {lineageLabel.text}
                    </div>
                  )}
                  <img
                    className="expand-icon"
                    src="/informationbutton-1-traced.svg"
                    alt="Expand"
                  />
                  <img
                    className="buy-now-product-image"
                    loading="lazy"
                    alt="Product"
                    src={product.imageUrl || '/default-product-image.png'}
                  />
                </div>
                <div className="item-info-container">
                  <div 
                    className="product-name"
                    onClick={() => {
                      handleClose();
                      onOpenItemDetails && onOpenItemDetails(product, currentPrice, originalPrice);
                    }}
                  >
                    {product.name} {product.amount}{formatUOM(product.uom)} {product.doses_units > 1 ? ` (${product.doses_units}pk)` : ''}
                  </div>
                  <div className="product-brand">{product.brand}</div>
                  
                  {/* Product Description (collapsible) */}
                  {product.description && (
                    <div className="product-description-container">
                      <div className={`product-description ${isDescriptionExpanded ? 'expanded' : ''}`}>
                        {product.description}
                      </div>
                      <div 
                        className="description-toggle" 
                        onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                      >
                        <IonIcon icon={isDescriptionExpanded ? chevronUpOutline : chevronDownOutline} />
                      </div>
                    </div>
                  )}
                  
                  {/* Cannabinoids and Effects */}
                  <div className="product-effects-container">
                    {/* Effects */}
                    {product?.effects && product.effects.length > 0 && (
                      <div className="effect-tags">
                        {product.effects.slice(0, 3).map((effect, i) => (
                          <span key={i} className="effect-tag">{effect}</span>
                        ))}
                      </div>
                    )}
                    
                    {/* Cannabinoids */}
                    <div className="cannabinoid-tags">
                      {getSortedCannabinoids(product, 3).map((cannabinoid) => (
                        <span key={cannabinoid.key} className="cannabinoid-tag">
                          {getCannabinoidDisplayName(cannabinoid.key)}: {cannabinoid.display}
                        </span>
                      ))}
                    </div>
                  </div>
                  
                  <div className="product-lineage">{product.lineage}</div>
                  <div className="price-container">
                    <div className="current-price">${Number(currentPrice || product.discountedPrice || (product.pricing?.discounted_price) || 0).toFixed(2)}</div>
                    <div className="original-price">${Number(originalPrice || product.price || (product.pricing?.price_sell) || 0).toFixed(2)}</div>
                  </div>
                  <QuantityCounter 
                    inventoryCount={product.product_configurable_fields?.sellable_quantity || product.sellableQuantity || 999999} 
                    onQuantityChange={handleQuantityChange}
                    initialQuantity={quantity}
                    isBuyNow={true}
                    align="left"
                  />  
                </div>
              </div>
              <div className="divider" />
              <div className="details-container">
                <PurchasePaymentMethod 
                  user={user}
                  onBankSelect={handleBankSelect}
                />
                <LocationFull />
                <div className="divider" />
                <div className="order-details-section">
                  <div 
                    className={`order-details-header mobile-only ${isOrderDetailsExpanded ? 'expanded' : ''}`}
                    onClick={() => setIsOrderDetailsExpanded(!isOrderDetailsExpanded)}
                  >
                    <span>Order Details - ${parseFloat(orderSubtotal).toFixed(2)} including taxes</span>
                    <IonIcon 
                      icon={isOrderDetailsExpanded ? caretUp : caretDown} 
                      className="order-details-caret"
                    />
                  </div>
                  <div className={`order-details-content ${isOrderDetailsExpanded ? 'expanded' : ''}`}>
                    <OrderDetails 
                      buyNowProduct={product}
                      buyNowQuantity={quantity}
                      storeId={product.store_id || (product.store_data && product.store_data.store_id)}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-sticky-footer">
                <div className="modal-button-container">
                  <div className="payment-notice">
                    <IonIcon icon={cardOutline} /> Payment made at store
                  </div>
                  <div className="help-notice">
                    <IonIcon icon={helpCircleOutline} /> Need help? Email hello@stashpandas.com
                  </div>
                  <ConfirmButton 
                    onClick={() => {
                      console.log('Button clicked, purchaseStatus:', purchaseStatus);
                      console.log('User verification status:', user?.purchaseStatus);
                      return purchaseStatus ? handleConfirmPurchaseNew() : setShowVerificationForm(true);
                    }}
                    text={isLoading ? "Processing..." : purchaseStatus ? `Complete Purchase • $${parseFloat(orderSubtotal).toFixed(2)}` : "Verify Account"}
                    disabled={isLoading}
                    isLoading={isLoading}
                    variant="primary"
                    size="large"
                    fullWidth
                  />
                </div>
              </div>
              {error && <p className="error">{error}</p>}
              {renderTransactionStatus(transactionStatus, error, handleConfirmPurchaseNew)}
              {renderLoadingState(isLoading, transactionId)}
            </>
          )}
        </section>
      </div>
    </>
  );
};

export default BuyNowModal;
