import * as actionTypes from '../actions/actionTypes';

const initialState = {
  data: {},  // Using an object for O(1) lookups by ID
  isLoading: false,
  error: null,
  lastFetched: null
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PRODUCTS: {
      // Simply store products by ID without further transformation
      const productsObj = {};
      for (const product of action.payload) {
        // Verify we have a product with an ID
        if (product && product.id) {
          productsObj[product.id] = product;
        }
      }
            
      return {
        ...state,
        data: productsObj,
        lastFetched: new Date().toISOString()
      };
    }

    case actionTypes.ADD_PRODUCTS: {
      // Merge new products with existing ones
      const newProducts = {};
      for (const product of action.payload) {
        if (product && product.id) {
          newProducts[product.id] = product;
        }
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...newProducts
        },
        lastFetched: new Date().toISOString()
      };
    }

    case actionTypes.UPDATE_PRODUCT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };

    case actionTypes.SET_PRODUCT_ACTIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    case actionTypes.SET_PRODUCT_ACTIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    default:
      return state;
  }
};

export default productReducer;
