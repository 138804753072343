import { useState, useEffect, useCallback } from "react";

import TransitionModal from "./TransitionModal";
import StashModal from "./StashModal";
import PortalPopup from "./PortalPopup";
import SearchInput from "./SearchInput";
import LoginButton from "./LoginButton";
import AuthModal from "./AuthModal";
import AccountModal from "./AccountModal";
import AuthService from "../../utils/AuthService";
import { useDispatch } from "react-redux";
import { clearUser } from "../../redux/actions/userActions";
import "./TopNav.css";

import { IonIcon } from '@ionic/react';
import { timeOutline, personOutline, bagOutline } from 'ionicons/icons';

const TopNav = ({ user, products }) => {
  const [isTransitionModalOpen, setTransitionModalOpen] = useState(false);
  const [transitionModalMode, setTransitionModalMode] = useState('stash');
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const [isAccountModalOpen, setAccountModalOpen] = useState(false);
  const [isStashModalOpen, setIsStashModalOpen] = useState(false);
  const [stashModalMode, setStashModalMode] = useState('stash');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSignUp, setIsSignUp] = useState(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const dispatch = useDispatch();

  const openAuthModal = useCallback(() => {
    setIsSignUp(false);
    setAuthModalOpen(true);
  }, []);

  const openSignUpModal = useCallback(() => {
    setIsSignUp(true);
    setAuthModalOpen(true);
  }, []);

  const closeAuthModal = useCallback(() => {
    setAuthModalOpen(false);
    setIsSignUp(false);
  }, []);

  const openAccountModal = useCallback(() => {
    setAccountModalOpen(true);
  }, []);

  const closeAccountModal = useCallback(() => {
    setAccountModalOpen(false);
  }, []);

  const openTransitionModal = (mode) => {
    setTransitionModalMode(mode);
    setTransitionModalOpen(true);
  };

  const closeTransitionModal = () => {
    setTransitionModalOpen(false);
  };
  
  const handleLogout = async () => {
    try {
      await AuthService.logout();
      dispatch(clearUser());
    } catch (error) {
      console.error('Logout failed', error);
      window.alert('Logout Failed', error.message);
    }
  };

  return (
    <>
      <header className="branching-tree-wrapper">
          <div className="section">
            {windowWidth < 768 ? (
              <div className="logo-wrapper">
                <img className="big-logo-traced2" src="/big-logo-traced.svg" alt="Logo" />
                <div className="center-content">
                  <div className="data-matrix">
                    <h2 className="stash-pandas2">Stash Pandas</h2>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="logo-wrapper">
                  <img className="big-logo-traced2" src="/big-logo-traced.svg" alt="Logo" />
                </div>
                <div className="center-content">
                  <div className="data-matrix">
                    <h2 className="stash-pandas2">Stash Pandas</h2>
                  </div>
                </div>
                </>
              )}
              <div className="frame-parent37">
                {user && user.email ? (
                  <>
                    <IonIcon
                      icon={personOutline}
                      className="nav-icon"
                      onClick={openAccountModal}
                    />
                    <IonIcon
                      icon={timeOutline}
                      className="nav-icon"
                      onClick={() => openTransitionModal('history')}
                    />
                    <IonIcon
                      icon={bagOutline}
                      className="nav-icon"
                      onClick={() => openTransitionModal('stash')}
                    />
                  </>
                ) : (
                  <>
                    <button className="signup-button" onClick={openSignUpModal}>
                      <span className="signup-text">Sign Up</span>
                    </button>
                    <LoginButton onClick={user && user.email ? handleLogout : openAuthModal} user={user} />
                  </>
                )}
              </div>
          </div>
      </header>
      {isTransitionModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTransitionModal}
        >
          <TransitionModal
            onClose={closeTransitionModal}
            products={products}
            initialMode={transitionModalMode}
            openAccountModal={openAccountModal}
          />
        </PortalPopup>
      )}
      {isAuthModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeAuthModal}
        >
          <AuthModal onClose={closeAuthModal} isSignUp={isSignUp} />
        </PortalPopup>
      )}
      {isAccountModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeAccountModal}
        >
          <AccountModal onClose={closeAccountModal} user={user} />
        </PortalPopup>
      )}
      {isStashModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={() => setIsStashModalOpen(false)}
        >
          <StashModal
            onClose={() => setIsStashModalOpen(false)}
            products={products}
            initialMode={stashModalMode}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default TopNav;
