import React from 'react';
import './CategoryBadge.css';

// Import the categories array directly from CategorySelect to ensure consistency
import { categories } from './CategorySelect';

const CategoryBadge = ({ category }) => {
  // Convert category to uppercase for matching with productType in categories array
  const categoryKey = category ? category.toUpperCase() : '';
  
  // Find the matching category object
  const categoryObj = categories.find(cat => cat.productType === categoryKey) || categories[0];
  
  // Brand pink color
  const brandPink = '#FF4FB0'; 
  
  return (
    <div className="category-badge" style={{ backgroundColor: brandPink }}>
      <div className="category-badge-icon">
        <img src={categoryObj.src} alt={category} />
      </div>
      <span className="category-badge-text">{category}</span>
    </div>
  );
};

export default CategoryBadge;
