import * as actionTypes from './actionTypes';
import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';

export const createUserDocument = (email, userId) => async (dispatch) => {
  const createUserDocumentFunction = httpsCallable(functions, 'createUserDocument');
  try {
    const response = await createUserDocumentFunction({ email, userId });
    if (response.data.success) {
      dispatch({ type: actionTypes.CREATE_USER_DOCUMENT_SUCCESS });
      return true;
    } else {
      throw new Error('Failed to create user document');
    }
  } catch (error) {
    console.error('Error creating user document:', error);
    dispatch({ type: actionTypes.CREATE_USER_DOCUMENT_FAIL, payload: error });
    throw error; // Re-throw the error to be handled by the caller
  }
};

// Helper function to serialize user data
const serializeUser = (user) => {
  // Debug what's coming from Firebase
  
  // Convert Firestore timestamps to ISO strings
  return user ? {
    ...user,
    createdAt: user.createdAt?.toDate?.()?.toISOString() || null,
    lastUpdatedAt: user.lastUpdatedAt?.toDate?.()?.toISOString() || null,
    aeroPayLinkedAt: user.aeroPayLinkedAt?.toDate?.()?.toISOString() || null,
    aeroPayVerifiedAt: user.aeroPayVerifiedAt?.toDate?.()?.toISOString() || null,
    phoneVerifiedAt: user.phoneVerifiedAt?.toDate?.()?.toISOString() || null,
    subscription: user.subscription ? {
      ...user.subscription,
      lastChecked: user.subscription.lastChecked?.toDate?.()?.toISOString() || null,
      subscribedAt: user.subscription.subscribedAt?.toDate?.()?.toISOString() || null,
      subscribedUntil: user.subscription.subscribedUntil?.toDate?.()?.toISOString() || null,
      cancelledAt: user.subscription.cancelledAt?.toDate?.()?.toISOString() || null,
    } : null
  } : null;
};

export const setUser = (user) => dispatch => {
  // Create a serializable version of the user object
  const serializedUser = serializeUser(user);
    
  // Set the user in the Redux store
  dispatch({
    type: actionTypes.SET_USER,
    payload: serializedUser
  });
  
  // If the user has subscription data, also update the subscription status in Redux
  if (serializedUser?.subscription) {
    dispatch({
      type: actionTypes.SET_USER_SUBSCRIPTION_STATUS,
      payload: serializedUser.subscription
    });
  }
};

export const clearUser = () => {
  return {
    type: actionTypes.CLEAR_USER,
  };
};

// Twilio Actions
export const updatePhoneNumber = (phoneNumber) => ({
  type: actionTypes.UPDATE_PHONE_NUMBER,
  payload: phoneNumber
});

export const setPhoneVerified = (status, phoneNumber) => ({
  type: actionTypes.SET_PHONE_VERIFIED,
  payload: { status, phoneNumber }
});