import React, { useState, useEffect, useRef, useMemo } from 'react';
import './FilterBar.css';
import FilterGroup from './FilterGroup';
import ActiveFilters from './ActiveFilters';
import { IonIcon } from '@ionic/react';
import { 
  searchOutline,
  filterOutline, 
  chevronDownOutline, 
  chevronUpOutline, 
  closeCircleOutline, 
  closeOutline, 
  happyOutline, 
  flameOutline, 
  pricetagOutline, 
  flashOutline,
  iceCreamOutline,
  ticketOutline,
  storefrontOutline,
  chevronBackOutline,
  chevronForwardOutline,
  statsChartOutline,
  cashOutline,
  sparklesOutline,
  leafOutline
} from 'ionicons/icons';
import { extractFilterOptions } from '../../../utils/filterCache';
import ReactDOM from 'react-dom';

const FilterBar = ({ 
  products, 
  activeFilters = {}, 
  setActiveFilters,
  condensedView = false,
  selectedProductType = null
}) => {
  const [openFilter, setOpenFilter] = useState(null);
  const [sortOption, setSortOption] = useState('featured');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchExpanded, setSearchExpanded] = useState(false);
  const filterBarRef = useRef(null);
  const searchInputRef = useRef(null);
  const buttonsContainerRef = useRef(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});

  // Use cached filter options instead of calculating them in component
  const cachedFilterOptions = useMemo(() => extractFilterOptions(products), [products]);

  // Ensure we use the most complete set of options
  useEffect(() => {
    if (products && products.length > 0) {
      // Extract options and log details for debugging
      const extractedOptions = extractFilterOptions(products);
      
      // Always update with latest cache
      setFilterOptions(extractedOptions);
    }
  }, [products, cachedFilterOptions]);

  const handleFilterChange = (filterType, values) => {
    setActiveFilters(prev => ({
      ...prev,
      [filterType]: values
    }));
  };

  const handleClearFilter = (filterType) => {
    setActiveFilters(prev => {
      const newFilters = { ...prev };
      delete newFilters[filterType];
      return newFilters;
    });
  };

  const clearAllFilters = () => {
    setActiveFilters({});
    setSortOption('featured');
    setSearchQuery('');
  };

  const handleSortChange = (value) => {
    setSortOption(value);
    setActiveFilters(prev => ({
      ...prev,
      sort: value
    }));
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    
    if (value.trim()) {
      setActiveFilters(prev => ({
        ...prev,
        search: value.trim()
      }));
    } else if (activeFilters.search) {
      handleClearFilter('search');
    }
  };

  const handleSearchFocus = () => {
    setSearchExpanded(true);
    // Focus the input when expanded
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleSearchBlur = () => {
    // Only collapse if there's no query
    if (!searchQuery) {
      setSearchExpanded(false);
    }
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (searchInputRef.current) {
        searchInputRef.current.blur();
      }
      setSearchExpanded(false);
    }
  };

  const clearSearch = () => {
    setSearchQuery('');
    handleClearFilter('search');
    setSearchExpanded(false);
  };

  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, right: 0 });

  const toggleFilter = (filterType, event) => {
    
    // Calculate position for the dropdown based on the button that was clicked
    if (event && event.currentTarget) {
      const rect = event.currentTarget.getBoundingClientRect();
      const scrollY = window.scrollY || window.pageYOffset;
      const scrollX = window.scrollX || window.pageXOffset;
      
      // Calculate absolute position
      const top = rect.bottom + scrollY;
      const left = rect.left + scrollX;
      
      setDropdownPosition({
        top: top,
        left: filterType === 'sort' ? 'auto' : left,
        right: filterType === 'sort' ? window.innerWidth - (rect.right + scrollX) : 'auto'
      });
    }
    
    if (openFilter === filterType) {
      setOpenFilter(null);
    } else {
      setOpenFilter(filterType);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Don't close if clicking inside the filter bar
      if (filterBarRef.current && filterBarRef.current.contains(event.target)) {
        return;
      }
      
      // Don't close if clicking inside the dropdown overlay (which is outside the filter bar)
      const dropdownElements = document.querySelectorAll('.filter-dropdown-overlay');
      for (let i = 0; i < dropdownElements.length; i++) {
        if (dropdownElements[i].contains(event.target)) {
          return;
        }
      }
      
      // Otherwise, close the dropdown
      setOpenFilter(null);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Modified scroll functions with reduced amounts and better detection
  const scrollLeft = () => {
    if (buttonsContainerRef.current) {
      // Scroll left - show content that's to the left (move view to the left)
      buttonsContainerRef.current.scrollBy({
        left: -120, // Smaller increment for smoother scrolling
        behavior: 'smooth'
      });
      
      // Force update scroll indicators after animation
      setTimeout(() => {
        updateScrollIndicators();
      }, 350);
    }
  };
  
  const scrollRight = () => {
    if (buttonsContainerRef.current) {
      // Scroll right - show content that's to the right (move view to the right)
      buttonsContainerRef.current.scrollBy({
        left: 120, // Smaller increment for smoother scrolling
        behavior: 'smooth'
      });
      
      // Force update scroll indicators after animation
      setTimeout(() => {
        updateScrollIndicators();
      }, 350);
    }
  };
  
  // Extracted function to consistently update scroll indicators
  const updateScrollIndicators = () => {
    const container = buttonsContainerRef.current;
    if (!container) return;
    
    // Use zero threshold for left scroll to make it appear immediately when scrolled at all
    const hasLeftScroll = container.scrollLeft > 0;
    
    // Calculate if more content is available to the right
    const hasRightScroll = 
      container.scrollWidth > container.clientWidth && 
      container.scrollLeft < (container.scrollWidth - container.clientWidth - 2);
    
    setShowLeftScroll(hasLeftScroll);
    setShowRightScroll(hasRightScroll);
  };

  // Effect for initial setup and filter option processing
  useEffect(() => {
    // Initialize scroll indicators after render
    setTimeout(() => updateScrollIndicators(), 500);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  
  // Effect to initialize and monitor scroll indicators
  useEffect(() => {
    const container = buttonsContainerRef.current;
    if (!container) return;
    
    // Initial check
    updateScrollIndicators();
    
    // Event listeners for scroll and resize
    const handleScroll = () => updateScrollIndicators();
    const handleResize = () => {
      // On resize, we need to check if arrows should be visible
      setTimeout(updateScrollIndicators, 100);
    };
    
    // Add event listeners
    container.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    
    // Cleanup
    return () => {
      container.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [filterOptions]); // Re-add listeners when filter options change

  const sortOptions = [
    { value: 'priceAsc', label: 'Price: Low to High' },
    { value: 'priceDesc', label: 'Price: High to Low' },
    { value: 'discountDesc', label: 'Biggest Discount' },
    { value: 'potencyDesc', label: 'Highest Potency' },
  ];

  const commonEffects = [
    'Relaxed', 'Euphoric', 'Happy', 'Uplifted', 'Creative', 
    'Energetic', 'Focused', 'Tingly', 'Sleepy', 'Hungry'
  ];

  const commonStrainTypes = ['Indica', 'Sativa', 'Hybrid', 'CBD'];

  const commonFlavors = [
    'Sweet', 'Earthy', 'Citrus', 'Pine', 'Woody', 
    'Fruity', 'Spicy', 'Berry', 'Lemon', 'Tropical'
  ];

  const commonDiscountTypes = ['On Sale'];

  const commonProductTypes = ['FLOWER', 'PREROLL', 'CARTRIDGE', 'EXTRACT', 'EDIBLE', 'TINCTURE', 'TOPICAL', 'ACCESSORY'];

  const hasActiveFilters = Object.keys(activeFilters).length > 0;
  
  const getFilterCount = (filterType) => {
    if (!activeFilters[filterType] || !Array.isArray(activeFilters[filterType])) {
      return 0;
    }
    return activeFilters[filterType].length;
  };

  const areAllSelected = (filterType) => {
    if (!filterOptions[filterType] || !activeFilters[filterType]) {
      return false;
    }
    
    // If all options are selected, don't show as active
    return filterOptions[filterType].length > 0 && 
           filterOptions[filterType].length === activeFilters[filterType].length;
  };

  const areSomeSelected = (filterType) => {
    return getFilterCount(filterType) > 0 && !areAllSelected(filterType);
  };

  const getTotalFilterCount = () => {
    return Object.keys(activeFilters).filter(key => key !== 'sort' && key !== 'search').length;
  };

  const showCannabisFilters = selectedProductType === 'FLOWER' || 
                             selectedProductType === 'PREROLL' || 
                             selectedProductType === 'CARTRIDGE' || 
                             selectedProductType === 'EXTRACT' ||
                             selectedProductType === 'EDIBLE' ||
                             !selectedProductType;

  // Combine real options with common options
  const combinedFilterOptions = useMemo(() => {
    if (!filterOptions) return {};
    
    // Helper to combine arrays without duplicates
    const combineArrays = (arr1 = [], arr2 = []) => {
      const combined = [...arr1];
      arr2.forEach(item => {
        if (!combined.includes(item)) {
          combined.push(item);
        }
      });
      return combined;
    };
    
    return {
      brands: filterOptions.brands || [],
      effects: combineArrays(filterOptions.effects, commonEffects),
      strainTypes: commonStrainTypes, // Only use predefined strain types
      flavors: combineArrays(filterOptions.flavors, commonFlavors),
      potencyRanges: filterOptions.potencyRanges || [],
      priceRanges: filterOptions.priceRanges || [],
      discountTypes: combineArrays(filterOptions.discountTypes, commonDiscountTypes)
    };
  }, [filterOptions]);

  return (
    <div className="filter-bar-container">
      <div className={`filter-bar-horizontal ${condensedView ? 'condensed' : ''}`} ref={filterBarRef} style={{ position: 'relative' }}>
        {/* Scroll Indicators - Outside the scrollable container */}
        {showLeftScroll && (
          <div className="scroll-indicator left visible" onClick={scrollLeft}>
            <IonIcon icon={chevronBackOutline} />
          </div>
        )}
        
        {showRightScroll && (
          <div className="scroll-indicator right visible" onClick={scrollRight}>
            <IonIcon icon={chevronForwardOutline} />
          </div>
        )}
        
        {/* Horizontal filter buttons */}
        <div className="filter-buttons-container" ref={buttonsContainerRef}>
          {/* 1. Integrated search button/input */}
          <div 
            className={`search-button ${searchExpanded ? 'expanded' : ''} ${activeFilters.search ? 'has-search' : ''}`}
            onClick={handleSearchFocus}
          >
            <IonIcon icon={searchOutline} className="search-icon" />
            <input 
              ref={searchInputRef}
              type="text" 
              className="search-input" 
              placeholder="Search products..."
              value={searchQuery}
              onChange={handleSearchChange}
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              onKeyDown={handleSearchKeyDown}
            />
            {searchQuery && (
              <button className="clear-search-button" onClick={clearSearch}>
                <IonIcon icon={closeOutline} />
              </button>
            )}
          </div>
          {/* 2. Brand filter button */}
          <button
            className={`filter-button ${openFilter === 'brands' ? 'active' : ''} ${areSomeSelected('brands') ? 'has-selection' : ''}`}
            onClick={(e) => toggleFilter('brands', e)}
          >
            <IonIcon icon={storefrontOutline} className="filter-button-icon" />
            <span>Brands</span>
            <IonIcon icon={openFilter === 'brands' ? chevronUpOutline : chevronDownOutline} />
          </button>
          
          {/* 3. Effects filter button - only show for cannabis products */}
          {showCannabisFilters && (
            <button 
              className={`filter-button ${openFilter === 'effects' ? 'active' : ''} ${areSomeSelected('effects') ? 'has-selection' : ''}`}
              onClick={(e) => toggleFilter('effects', e)}
              data-filter-type="effects"
            >
              <IonIcon icon={happyOutline} className="filter-button-icon" />
              <span>Effects</span>
              <IonIcon icon={openFilter === 'effects' ? chevronUpOutline : chevronDownOutline} />
            </button>
          )}
          
          {/* 4. Strain Type filter button - only show for cannabis products */}
          {showCannabisFilters && (
            <button 
              className={`filter-button ${openFilter === 'strainTypes' ? 'active' : ''} ${areSomeSelected('strainTypes') ? 'has-selection' : ''}`}
              onClick={(e) => toggleFilter('strainTypes', e)}
              data-filter-type="strainTypes"
            >
              <IonIcon icon={leafOutline} className="filter-button-icon" />
              <span>Strains</span>
              <IonIcon icon={openFilter === 'strainTypes' ? chevronUpOutline : chevronDownOutline} />
            </button>
          )}
          
          {/* 5. Flavors filter button - only show for cannabis products */}
          {showCannabisFilters && (
            <button
              className={`filter-button ${openFilter === 'flavors' ? 'active' : ''} ${areSomeSelected('flavors') ? 'has-selection' : ''}`}
              onClick={(e) => toggleFilter('flavors', e)}
              data-filter-type="flavors"
            >
              <IonIcon icon={iceCreamOutline} className="filter-button-icon" />
              <span>Flavors</span>
              <IonIcon icon={openFilter === 'flavors' ? chevronUpOutline : chevronDownOutline} />
            </button>
          )}
          
          {/* 6. Potency filter button - only show for cannabis products */}
          {/* {showCannabisFilters && (
            <button 
              className={`filter-button ${openFilter === 'potencyRanges' ? 'active' : ''} ${areSomeSelected('potencyRanges') ? 'has-selection' : ''}`}
              onClick={(e) => toggleFilter('potencyRanges', e)}
              data-filter-type="potencyRanges"
            >
              <IonIcon icon={flameOutline} className="filter-button-icon" />
              <span>Potency</span>
              <IonIcon icon={openFilter === 'potencyRanges' ? chevronUpOutline : chevronDownOutline} />
            </button>
          )} */}
          
          {/* 7. Price Range filter button */}
          {/* <button 
            className={`filter-button ${openFilter === 'priceRanges' ? 'active' : ''} ${areSomeSelected('priceRanges') ? 'has-selection' : ''}`}
            onClick={(e) => toggleFilter('priceRanges', e)}
          >
            <IonIcon icon={pricetagOutline} className="filter-button-icon" />
            <span>Price</span>
            <IonIcon icon={openFilter === 'priceRanges' ? chevronUpOutline : chevronDownOutline} />
          </button>
           */}
          {/* 8. Deals filter button */}
          {/* <button 
            className={`filter-button ${openFilter === 'discountTypes' ? 'active' : ''} ${areSomeSelected('discountTypes') ? 'has-selection' : ''}`}
            onClick={(e) => toggleFilter('discountTypes', e)}
            data-filter-type="discountTypes"
          >
            <IonIcon icon={ticketOutline} className="filter-button-icon" />
            <span>Deals</span>
            <IonIcon icon={openFilter === 'discountTypes' ? chevronUpOutline : chevronDownOutline} />
          </button> */}
          
          {/* 9. Sort button and dropdown */}
          {/* <button 
            className={`filter-button sort-button ${openFilter === 'sort' ? 'active' : ''}`}
            onClick={(e) => toggleFilter('sort', e)}
          >
            <IonIcon icon={filterOutline} className="sort-icon" />
            <span>Sort</span>
            <IonIcon icon={openFilter === 'sort' ? chevronUpOutline : chevronDownOutline} />
          </button> */}

          {/* Clear all button */}
          {hasActiveFilters && (
            <div className="clear-all-button" onClick={clearAllFilters}>
              <span>Clear All</span>
              <IonIcon icon={closeCircleOutline} className="clear-icon" />
            </div>
          )}
        </div>
      </div>
      
      {/* Dropdown overlays - render them outside the filter bar */}
      {openFilter && ReactDOM.createPortal(
        <div 
          className="filter-dropdown-overlay" 
          style={{ 
            position: 'fixed',
            top: `${dropdownPosition.top}px`, 
            left: dropdownPosition.left !== 'auto' ? `${dropdownPosition.left}px` : 'auto', 
            right: dropdownPosition.right !== 'auto' ? `${dropdownPosition.right}px` : 'auto'
          }}
        >
          {openFilter === 'brands' && (
            <FilterGroup 
              type="brands"
              options={combinedFilterOptions.brands}
              selectedValues={activeFilters.brands || []}
              onChange={(values) => handleFilterChange('brands', values)}
              label="Brands"
              supportSelectAll
              isDropdown={true}
            />
          )}
          
          {openFilter === 'priceRanges' && (
            <FilterGroup 
              type="priceRanges"
              options={combinedFilterOptions.priceRanges}
              selectedValues={activeFilters.priceRanges || []}
              onChange={(values) => handleFilterChange('priceRanges', values)}
              label="Price Ranges"
              supportSelectAll
              isDropdown={true}
            />
          )}
          
          {openFilter === 'effects' && (
            <FilterGroup 
              type="effects"
              options={combinedFilterOptions.effects}
              selectedValues={activeFilters.effects || []}
              onChange={(values) => handleFilterChange('effects', values)}
              label="Effects"
              supportSelectAll
              isDropdown={true}
            />
          )}
          
          {openFilter === 'strainTypes' && (
            <FilterGroup 
              type="strainTypes"
              options={combinedFilterOptions.strainTypes}
              selectedValues={activeFilters.strainTypes || []}
              onChange={(values) => handleFilterChange('strainTypes', values)}
              label="Strain Types"
              supportSelectAll
              isDropdown={true}
            />
          )}
          
          {openFilter === 'potencyRanges' && (
            <FilterGroup 
              type="potencyRanges"
              options={combinedFilterOptions.potencyRanges}
              selectedValues={activeFilters.potencyRanges || []}
              onChange={(values) => handleFilterChange('potencyRanges', values)}
              label="THC Potency"
              supportSelectAll
              isDropdown={true}
            />
          )}
          
          {openFilter === 'flavors' && (
            <FilterGroup 
              type="flavors"
              options={combinedFilterOptions.flavors}
              selectedValues={activeFilters.flavors || []}
              onChange={(values) => handleFilterChange('flavors', values)}
              label="Flavors/Tastes"
              supportSelectAll
              isDropdown={true}
            />
          )}
          
          {openFilter === 'discountTypes' && (
            <FilterGroup 
              type="discountTypes"
              options={combinedFilterOptions.discountTypes}
              selectedValues={activeFilters.discountTypes || []}
              onChange={(values) => handleFilterChange('discountTypes', values)}
              label="Deals & Discounts"
              supportSelectAll
              isDropdown={true}
            />
          )}
          
          {openFilter === 'sort' && (
            <div className="sort-dropdown">
              <div className="sort-dropdown-header">
                <h3 className="sort-dropdown-title">Sort By</h3>
              </div>
              <div className="sort-options">
                {sortOptions.map(option => (
                  <div 
                    key={option.value} 
                    className={`sort-option ${activeFilters.sort === option.value ? 'selected' : ''}`}
                    onClick={() => {
                      handleSortChange(option.value);
                      setOpenFilter(null);
                    }}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>,
        document.body
      )}
    </div>
  );
};

export default FilterBar;