// Cache object for filter options
let filterOptionsCache = {
  data: null,
  timestamp: 0,
  ttl: 600000 // 10 minutes
};

// Extract and cache filter options from products
export const extractFilterOptions = (products) => {
  // Clear the cache to ensure we always get fresh data during development
  filterOptionsCache = {
    data: null,
    timestamp: 0,
    ttl: 600000
  };
  
  const now = Date.now();
  if (filterOptionsCache.data && now - filterOptionsCache.timestamp < filterOptionsCache.ttl) {
    return filterOptionsCache.data;
  }
  
  // Fast extraction using Set and single pass
  const brands = new Set();
  const effects = new Set();
  const strainTypes = new Set();
  const productTypes = new Set();
  const discountTypes = new Set();
  const flavors = new Set();
  
  // Fixed arrays
  const potencyRanges = ["0-10%", "10-15%", "15-20%", "20-25%", "25%+"];
  const priceRanges = ["Under $20", "$20-$40", "$40-$60", "$60-$100", "$100+"];
  
  if (products && products.length > 0) {
    // Add all sample brands always, to ensure we have a good selection
    [
      '710 LABS', '21ST AMENDMENT', '3C FARMS', 'AIMS', 'ALIEN LABS', 
      'BLOOM FARMS', 'BUDDIES', 'CAMINO', 'CANNDESCENT', 'CARE BY DESIGN',
      'CONNECTED', 'COOKIES', 'CRESCO', 'DOSIST', 'FLORACAL', 
      'FRIENDLY FARMS', 'HEAVY HITTERS', 'JETTY EXTRACTS', 'KANHA', 'KINGPEN',
      'KOROVA', 'KIVA', 'LEGION OF BLOOM', 'LOWELL HERB CO', 'PAPA & BARKLEY',
      'RAW GARDEN', 'ROVE', 'SELECT', 'STIIIZY', 'SUNDAY GOODS'
    ].forEach(brand => brands.add(brand));
    
    products.forEach(product => {
      // Brand - handle all possible locations of brand info
      let brandName = null;
      
      // Try all possible locations of brand information
      if (product.sp?.brand) {
        brandName = product.sp.brand;
      } else if (product.brand) {
        brandName = product.brand;
      } else if (product.brands) { // Some systems use 'brands' plural
        brandName = Array.isArray(product.brands) ? product.brands[0] : product.brands;
      } else if (product.manufacturer) { // Some use manufacturer instead of brand
        brandName = product.manufacturer;
      } else if (product.store_data?.brand) { // Check in store_data
        brandName = product.store_data.brand;
      } else if (product.attributes?.brand) { // Check in attributes
        brandName = product.attributes.brand;
      } else if (product.metadata?.brand) { // Check in metadata
        brandName = product.metadata.brand;
      }
      
      // Add brand if found
      if (brandName && typeof brandName === 'string' && brandName.trim() !== '') {
        brands.add(brandName.trim());
      }
      
      // Product type
      if (product.category_type) {
        productTypes.add(product.category_type);
      }
      
      // Effects
      (product.sp?.attributes?.effects || product.effects || [])
        .forEach(effect => effects.add(effect));
      
      // Flavors
      (product.sp?.attributes?.flavors || product.flavors || [])
        .forEach(flavor => flavors.add(flavor));
      
      // Strain type
      if (product.sp?.attributes?.strainType || product.classification) {
        strainTypes.add(product.sp?.attributes?.strainType || product.classification);
      }
      
      // Discount types
      if (product.sp?.discountType) {
        discountTypes.add(product.sp.discountType);
      }
      
      // On Sale
      if ((product.sp?.discountPercent > 0) || 
          (product.stores && Object.values(product.stores).some(store => (store?.discount_percent || 0) > 0))) {
        discountTypes.add('On Sale');
      }
    });
  }
  
  // Common effects and flavors to ensure we always have some options
  [
    'Relaxed', 'Euphoric', 'Happy', 'Uplifted', 'Creative', 
    'Energetic', 'Focused', 'Tingly', 'Sleepy', 'Hungry'
  ].forEach(effect => effects.add(effect));
  
  [
    'Sweet', 'Earthy', 'Citrus', 'Pine', 'Woody', 
    'Fruity', 'Spicy', 'Berry', 'Lemon', 'Tropical'
  ].forEach(flavor => flavors.add(flavor));
  
  ['Indica', 'Sativa', 'Hybrid'].forEach(strain => strainTypes.add(strain));
  
  const filterOptions = {
    brands: Array.from(brands).sort(),
    effects: Array.from(effects).sort(),
    strainTypes: Array.from(strainTypes).sort(),
    productTypes: Array.from(productTypes).sort(),
    discountTypes: Array.from(discountTypes).sort(),
    flavors: Array.from(flavors).sort(),
    potencyRanges,
    priceRanges
  };
  
  // Update the cache
  filterOptionsCache = {
    data: filterOptions,
    timestamp: now,
    ttl: 600000 // 10 minutes
  };
  
  return filterOptions;
};

// Filter products with a single pass
export const filterProducts = (products, activeFilters, selectedProductType) => {
  if (!products || products.length === 0) return [];
  
  let productsToProcess = [...products]; // Create a copy to work with
  
  // Apply category filter and other filters if needed
  if (selectedProductType || 
      (activeFilters && 
       ((activeFilters.brands && activeFilters.brands.length > 0) ||
        (activeFilters.effects && activeFilters.effects.length > 0) ||
        (activeFilters.strainTypes && activeFilters.strainTypes.length > 0) ||
        (activeFilters.discountTypes && activeFilters.discountTypes.length > 0) ||
        (activeFilters.flavors && activeFilters.flavors.length > 0) ||
        (activeFilters.search && activeFilters.search.trim() !== '')))) {
    
    productsToProcess = productsToProcess.filter(product => {
      // Category filter - only apply if selectedProductType is provided
      if (selectedProductType && 
          product.category_type && 
          product.category_type.toUpperCase() !== selectedProductType.toUpperCase()) {
        return false;
      }
      
      // Brand filter - only apply if brands filter is active
      if (activeFilters?.brands && activeFilters.brands.length > 0) {
        const productBrand = product.sp?.brand || product.brand;
        if (!productBrand || !activeFilters.brands.includes(productBrand)) {
          return false;
        }
      }
      
      // Effects filter - only apply if effects filter is active
      if (activeFilters?.effects && activeFilters.effects.length > 0) {
        const productEffects = product.sp?.attributes?.effects || product.effects || [];
        if (!productEffects.length || !activeFilters.effects.some(effect => productEffects.includes(effect))) {
          return false;
        }
      }
      
      // Strain types filter - only apply if strain types filter is active
      if (activeFilters?.strainTypes && activeFilters.strainTypes.length > 0) {
        const productStrainType = product.sp?.attributes?.strainType || product.classification;
        if (!productStrainType || !activeFilters.strainTypes.includes(productStrainType)) {
          return false;
        }
      }
      
      // Discount types filter - only apply if discount types filter is active
      if (activeFilters?.discountTypes && activeFilters.discountTypes.length > 0) {
        // Check if product has any discount
        const hasDiscount = (product.sp?.discountPercent > 0) || 
                           (product.stores && Object.values(product.stores).some(store => (store?.discount_percent || 0) > 0));
        
        // For 'On Sale', we ONLY want products with an actual discount
        if (activeFilters.discountTypes.includes('On Sale')) {
          if (!hasDiscount) {
            return false;
          }
        } else {
          // For other discount types, check if the product's discount type matches any selected types
          const matchesDiscountType = product.sp?.discountType && activeFilters.discountTypes.includes(product.sp.discountType);
          if (!matchesDiscountType) {
            return false;
          }
        }
      }
      
      // Flavors filter - only apply if flavors filter is active
      if (activeFilters?.flavors && activeFilters.flavors.length > 0) {
        const productFlavors = product.sp?.attributes?.flavors || product.flavors || [];
        if (!productFlavors.length || !activeFilters.flavors.some(flavor => productFlavors.includes(flavor))) {
          return false;
        }
      }
      
      // Search term - only apply if search filter is active
      if (activeFilters?.search && activeFilters.search.trim() !== '') {
        const searchTerm = activeFilters.search.toLowerCase().trim();
        const nameMatch = product.name && product.name.toLowerCase().includes(searchTerm);
        const brandMatch = product.brand && product.brand.toLowerCase().includes(searchTerm);
        const descMatch = product.description && product.description.toLowerCase().includes(searchTerm);
        
        if (!nameMatch && !brandMatch && !descMatch) {
          return false;
        }
      }
      
      return true;
    });
  }
  
  // ALWAYS sort by photos first, then by discount - regardless of filtering
  
  // Helper function to get highest discount for a product
  const getHighestDiscount = (product) => {
    let highestDiscount = product.discount_percent || 0;
    
    if (product.stores && Object.values(product.stores).length > 0) {
      Object.values(product.stores).forEach(store => {
        if ((store.discount_percent || 0) > highestDiscount) {
          highestDiscount = store.discount_percent || 0;
        }
      });
    }
    
    return highestDiscount;
  };
  
  // Debug mode to help diagnose photo detection
  const DEBUG_PHOTOS = false; // Set to false for production
  
  // Explicitly separate products with and without photos
  const withPhotos = [];
  const withoutPhotos = [];
  
  // Sort each product into the appropriate array
  productsToProcess.forEach(product => {
    // Extract imageUrl exactly how ItemGallery does it
    const imageUrl = product.sp?.imageUrl || product.imageUrl;
    
    // A product has a photo if it has a non-empty imageUrl
    const hasPhoto = !!imageUrl; 
    
    
    
    if (hasPhoto) {
      withPhotos.push({...product, _sortDiscount: getHighestDiscount(product)});
    } else {
      withoutPhotos.push({...product, _sortDiscount: getHighestDiscount(product)});
    }
  });
  
  // Sort each array by discount (highest first)
  withPhotos.sort((a, b) => b._sortDiscount - a._sortDiscount);
  withoutPhotos.sort((a, b) => b._sortDiscount - a._sortDiscount);
  
  // If no products have photos, we still need to sort all products by discount
  let result;
  if (withPhotos.length === 0) {
  
    result = withoutPhotos.map(p => {
      // Remove helper property before returning
      const product = {...p};
      delete product._sortDiscount;
      return product;
    });
  } else {
    // Standard split-sort-merge approach when we have photos
    result = [...withPhotos, ...withoutPhotos].map(p => {
      // Remove helper property before returning
      const product = {...p};
      delete product._sortDiscount;
      return product;
    });
  }
  
  
  
  return result;
};

// Get deal products 
export const getDealsProducts = (products, activeFilters) => {
  if (!products || products.length === 0) return [];
  
  // Get products with discounts - simplified to check for discount_percent > 0
  let dealProducts = products.filter(product => 
    // Check product-level discount_percent
    product.discount_percent > 0 || 
    // Or check any store for discount_percent > 0
    (product.stores && Object.values(product.stores).some(store => store.discount_percent > 0))
  );
  
  // Apply filters 
  if (activeFilters && 
      (activeFilters.brands?.length > 0 || 
       activeFilters.effects?.length > 0 || 
       activeFilters.strainTypes?.length > 0 || 
       activeFilters.flavors?.length > 0 || 
       activeFilters.search)) {
    dealProducts = filterProducts(dealProducts, activeFilters, null);
  }
  
  // Sort by discount percentage (biggest first)
  dealProducts.sort((a, b) => {
    // Get discount percentage for product A
    let discountPercentA = a.discount_percent || 0;
    if (discountPercentA === 0 && a.stores) {
      // Find the highest discount percent from stores
      Object.values(a.stores).forEach(store => {
        if (store.discount_percent > discountPercentA) {
          discountPercentA = store.discount_percent;
        }
      });
    }
    
    // Get discount percentage for product B
    let discountPercentB = b.discount_percent || 0;
    if (discountPercentB === 0 && b.stores) {
      // Find the highest discount percent from stores
      Object.values(b.stores).forEach(store => {
        if (store.discount_percent > discountPercentB) {
          discountPercentB = store.discount_percent;
        }
      });
    }
    
    // Sort in descending order (highest discount first)
    return discountPercentB - discountPercentA;
  });
  
  // Return all deal products (no slice limit for horizontal scroll)
  return dealProducts;
};