// rootReducer.js
import { combineReducers } from 'redux';

import userReducer from './userReducer';
import orderReducer from './orderReducer';
import productReducer from './productReducer';
import aeropayReducer from './aeropayReducer';
import storeReducer from './storeReducer';

const rootReducer = combineReducers({
  user: userReducer,
  order: orderReducer,
  products: productReducer,
  aeropay: aeropayReducer,
  store: storeReducer
});

export default rootReducer;
