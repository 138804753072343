import "./ItemInfo.css";
import { useMemo } from 'react';
import { 
  getCannabinoidValue, 
  getCannabinoidDisplayName, 
  getSortedCannabinoids 
} from '../utils/cannabinoidUtils';
import { formatUOM } from "../utils/formatUtils";
import CategoryBadge from "./CategoryBadge";

const ItemInfo = ({ product }) => {

  const capitalize = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Extract data from attributes and from direct product properties
  const attributes = product?.attributes || {};
  const cannabinoids = attributes?.cannabinoids || {};
  const details = attributes?.details || {};

  // Handle effects, activities, and flavors - check both direct properties and inside attributes
  const productEffects = product?.effects || attributes?.effects || [];
  const productActivities = product?.activities || attributes?.activities || [];
  const productFlavors = product?.flavors || attributes?.flavors || [];

  // For debugging purposes - log the sources of our data
  if (process.env.NODE_ENV !== 'production') {
    if (product.id === 'kanha-nano-citrus-splash-edible-gummy-100-mg') {
      console.log('[ItemInfo Debug] Product data for Kanha gummy:', {
        productId: product.id,
        directEffects: product?.effects || [],
        attributesEffects: attributes?.effects || [],
        directActivities: product?.activities || [],
        attributesActivities: attributes?.activities || [],
        directFlavors: product?.flavors || [],
        attributesFlavors: attributes?.flavors || [],
        finalEffects: productEffects,
        finalActivities: productActivities,
        finalFlavors: productFlavors
      });
    }
  }

  // Get strain label configuration
  const strainLabel = useMemo(() => {
    if (product.classification) {
      switch (product.classification.toLowerCase()) {
        case 'indica':
          return { text: 'Indica', backgroundColor: `rgba(var(--indica-color), var(--label-opacity))` };
        case 'sativa':
          return { text: 'Sativa', backgroundColor: `rgba(var(--sativa-color), var(--label-opacity))` }; 
        case 'cbd':
          return { text: 'CBD', backgroundColor: `rgba(var(--cbd-color), var(--label-opacity))` };
        case 'hybrid':
          return { text: 'Hybrid', backgroundColor: `rgba(var(--hybrid-color), var(--label-opacity))` };
        default:
          return null;
      }
    }
    return null;
  }, [product.classification]);

  // Calculate discount percentage
  const getDiscountPercentage = () => {
    if (!product.price || !product.discountedPrice) return 0;
    const discount = ((product.price - product.discountedPrice) / product.price) * 100;
    return Math.round(discount);
  };

  // Use the utility functions for cannabinoids
  const sortedCannabinoids = getSortedCannabinoids(product);

  // Get THC value from cannabinoids structure
  const getThcValue = () => {
    const thcData = getCannabinoidValue(product, 'thc');
    return thcData.display;
  };

  // Get CBD value from cannabinoids structure
  const getCbdValue = () => {
    const cbdData = getCannabinoidValue(product, 'cbd');
    return cbdData.display;
  };

  const getCbnValue = () => {
    return product?.lab_results?.CBN ? `${(parseFloat(product.lab_results.CBN) * 100).toFixed(1)}%` : '0.0%';
  };

  const discountPercentage = getDiscountPercentage();

  // Add styles for the product-info-header
  const productInfoHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '0.5rem',
    marginBottom: '0.5rem'
  };

  const productInfoNameStyle = {
    margin: '0',
    marginRight: '0.25rem'
  };

  return (
    <div className="item-details-wrapper">
      {/* Header Section */}
        

        {/* Product Info Section */}
        <div className="product-info-section">

          {/* Labels */}
          <div className="labels-container">
            {discountPercentage > 0 && (
              <div className="discount-label">{discountPercentage}% OFF</div>
            )}
            {strainLabel && (
              <div 
                className="strain-label"
                style={{ backgroundColor: strainLabel.backgroundColor }}
              >
                {strainLabel.text}
              </div>
            )}
          </div>
          <img
            className="product-info-image"
            loading="lazy"
            alt=""
            src={product.imageUrl}
          />
          <div className="product-name-brand-description-container">
          <div className="product-info-header" style={productInfoHeaderStyle}>
            <h1 className="product-info-name" style={productInfoNameStyle}>
              {product.name} {product.amount}{formatUOM(product.uom)} {product.doses_units > 1 ? ` (${product.doses_units}pk)` : ''}
            </h1>
            {product.category_type && (
              <CategoryBadge category={product.category_type} />
            )}
          </div>
          <h2 className="product-info-brand">{product.brand}</h2>
          {product.description && (
            <div className="product-info-description">
              {product.description}
            </div>
            )}
          </div>
        </div>
        
        {/* Stats Section */}
        <div className="stats-section">

          {/* Combined Details Section */}
          <div className="details-section">
            {productEffects.length > 0 && (
              <div className="details-row">
                <div className="details-title">Effects</div>
                <div className="tag-container">
                  {productEffects.map((effect, i) => (
                    <span key={i} className="tag effect">{effect}</span>
                  ))}
                </div>
              </div>
            )}

            {productActivities.length > 0 && (
              <div className="details-row">
                <div className="details-title">Good For</div>
                <div className="tag-container">
                  {productActivities.map((activity, i) => (
                    <span key={i} className="tag activity">{activity}</span>
                  ))}
                </div>
              </div>
            )}

            {productFlavors.length > 0 && (
              <div className="details-row">
                <div className="details-title">Flavors</div>
                <div className="tag-container">
                  {productFlavors.map((flavor, i) => (
                    <span key={i} className="tag flavor">{flavor}</span>
                  ))}
                </div>
              </div>
            )}
          </div>
          
          {/* Cannabinoids Section - Always below all details */}
          {sortedCannabinoids.length > 0 && (
            <div className="cannabinoids-section">
              {sortedCannabinoids.map((cannabinoid, i) => (
                <div key={i} className="cannabinoid-box">
                  <div className="cannabinoid-label">{getCannabinoidDisplayName(cannabinoid.key)}</div>
                  <div className="cannabinoid-value">{cannabinoid.display}</div>
                </div>
              ))}
            </div>
          )}
          
        </div>

        {/* Serving Info */}
        {/* {details.servings && (
          <div className="serving-info">
            <span className="serving-label">Serving Size:</span>
            {details.servings}
          </div>
        )} */}
    </div>
  );
};

export default ItemInfo;
