/**
 * hardcodedDiscounts.js
 * Contains all hardcoded discount data for use in the application
 */

export const ACTIVE_DISCOUNTS = {
    "092b36a7-b0a3-4b5c-9e3b-a792d72500d8": {
    "discount_id": "092b36a7-b0a3-4b5c-9e3b-a792d72500d8",
    "discount_title": "JEETER CARES EARLY ACCESS MARCH 30%",
    "discount_affinity": "Pre-Cart",
    "discount_amount": 30,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "days": [0, 1, 2, 3, 4, 5, 6],
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "578561f9-2ff7-4c87-bb7d-7124eb0a0d10": {
    "discount_id": "578561f9-2ff7-4c87-bb7d-7124eb0a0d10",
    "discount_title": "JEETER CARES MARCH 40%",
    "discount_affinity": "Pre-Cart",
    "discount_amount": 40,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "days": [0, 1, 2, 3, 4, 5, 6],
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "46d0ec10-41dd-44dc-b7e3-896b1347186c": {
    "discount_id": "46d0ec10-41dd-44dc-b7e3-896b1347186c",
    "discount_title": "MARCH SLUGGERS 40%",
    "discount_affinity": "Pre-Cart",
    "discount_amount": 40,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "days": [0, 1, 2, 3, 4, 5, 6],
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "4ba9f05c-50cb-425b-88cb-154a8c545495": {
    "discount_id": "4ba9f05c-50cb-425b-88cb-154a8c545495",
    "discount_title": "FRIDAY PHAT PANDA 25%",
    "discount_affinity": "Pre-Cart",
    "discount_amount": 25,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Schedule",
      "discount_condition_value": "Weekly on Friday, Starting on 2024-12-20 12:00 am and ending at 11:59 pm"
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "days": [5],
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "65e8429b-2967-4d9c-80dd-f52849e1f9f4": {
    "discount_id": "65e8429b-2967-4d9c-80dd-f52849e1f9f4",
    "discount_title": "HALF AND FULL OZ 30%",
    "discount_affinity": "Pre-Cart",
    "discount_amount": 30,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "days": [0, 1, 2, 3, 4, 5, 6],
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "90f7dd24-9337-4ab2-b279-766494be0bb3": {
    "discount_id": "90f7dd24-9337-4ab2-b279-766494be0bb3",
    "discount_title": "FRIDAY WIZARD TREES 20%",
    "discount_affinity": "Pre-Cart",
    "discount_amount": 20,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,    
    "days": [5],
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "94ef9a02-ed76-4221-b21f-7dae33ca11f6": {
    "discount_id": "94ef9a02-ed76-4221-b21f-7dae33ca11f6",
    "discount_title": "FRIDAY / SATURDAY / SUNDAY STIIIZY 30%",
    "discount_affinity": "Pre-Cart",
    "discount_amount": 30,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Schedule",
      "discount_condition_value": "Custom; Repeat every 1 week; Repeat on FRI, SAT, SUN; Ends never; Starting on 2025-01-17 12:00 am and ending at 11:59 pm"
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "days": [5, 6, 0],
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "9d7c836f-f6da-48a3-b344-8fd6d199d244": {
    "discount_id": "9d7c836f-f6da-48a3-b344-8fd6d199d244",
    "discount_title": "FRIDAY TERP 15%",
    "discount_affinity": "Pre-Cart",
    "discount_amount": 15,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "days": [5],
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "e51be607-9d9c-494e-87f2-5b9bead27c5b": {
    "discount_id": "e51be607-9d9c-494e-87f2-5b9bead27c5b",
    "discount_title": "FRIDAY PACIFIC STONE 25%",
    "discount_affinity": "Pre-Cart",
    "discount_amount": 25,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Schedule",
      "discount_condition_value": "Weekly on Friday, Starting on 2025-02-28 12:00 am and ending at 11:59 pm"
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "days": [5],
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
}


const INACTIVE_DISCOUNTS = {
  "01f29626-a069-49a1-a887-039fca0bd3e8": {
    "discount_id": "01f29626-a069-49a1-a887-039fca0bd3e8",
    "discount_title": "$7 JOINTS - 3 FOR $18 BUNDLE",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $4.0",
      "required_buy_count": 2,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "094bc739-6962-4903-a503-ca76a4656dc3": {
    "discount_id": "094bc739-6962-4903-a503-ca76a4656dc3",
    "discount_title": "1ST PURCHASE 15%",
    "discount_affinity": "Cart",
    "discount_amount": 15,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Customer Event",
      "discount_condition_value": "Nth Purchase: 1"
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "15734c8f-0a3f-4066-8b81-7993227e51c3": {
    "discount_id": "15734c8f-0a3f-4066-8b81-7993227e51c3",
    "discount_title": "PACIFIC STONE SHAKE / INFUSED PREROLL BOGO",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "15b3e424-eea1-4267-9566-5e25f1fbfea5": {
    "discount_id": "15b3e424-eea1-4267-9566-5e25f1fbfea5",
    "discount_title": "$14 ITEMS - 3 FOR $35 BUNDLE",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $7.0",
      "required_buy_count": 2,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "17dae2fe-bc79-4b78-8aea-db9187df7281": {
    "discount_id": "17dae2fe-bc79-4b78-8aea-db9187df7281",
    "discount_title": "FRIDAY SWISH B2G1",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 2,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "22155cd7-8752-48aa-8a6b-ea922ac6db1f": {
    "discount_id": "22155cd7-8752-48aa-8a6b-ea922ac6db1f",
    "discount_title": "$12 GUMMIES - 3 FOR $30 BUNDLE",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $6.0",
      "required_buy_count": 2,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "23fa47aa-91a2-4767-850f-795eed4c50ec": {
    "discount_id": "23fa47aa-91a2-4767-850f-795eed4c50ec",
    "discount_title": "2ND PURCHASE 10%",
    "discount_affinity": "Cart",
    "discount_amount": 10,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Customer Event",
      "discount_condition_value": "Nth Purchase: 2"
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "279a49f7-994c-476d-8e26-6ceedb03e90a": {
    "discount_id": "279a49f7-994c-476d-8e26-6ceedb03e90a",
    "discount_title": "GRIZZLY PEAK PREROLL B2G1",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 2,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "3420b658-03e2-4737-832a-1d67d3aaa381": {
    "discount_id": "3420b658-03e2-4737-832a-1d67d3aaa381",
    "discount_title": "SMOAKLAND BOGO PREROLL",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "3c7035c0-edf6-4712-87f1-e597293d18ad": {
    "discount_id": "3c7035c0-edf6-4712-87f1-e597293d18ad",
    "discount_title": "NEIGHBORHOOD EMPLOYEE 15%",
    "discount_affinity": "Cart",
    "discount_amount": 15,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Customer Group",
      "discount_condition_value": "NEIGHBORHOOD EMPLOYEE"
    }, {
      "discount_condition_type": "Customer Type",
      "discount_condition_value": "Adult"
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "418d3447-f27a-48c6-8275-c05a8087c196": {
    "discount_id": "418d3447-f27a-48c6-8275-c05a8087c196",
    "discount_title": "FRIDAY BALL FAMILY 4G BOGO PREROLL",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "419cdeab-9cd6-4109-8ec1-2020ee6a0106": {
    "discount_id": "419cdeab-9cd6-4109-8ec1-2020ee6a0106",
    "discount_title": "INDUSTRY 10%",
    "discount_affinity": "Cart",
    "discount_amount": 10,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Customer Group",
      "discount_condition_value": "INDUSTRY"
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  
  "4e612d08-393d-42a6-b9af-59c07904912b": {
    "discount_id": "4e612d08-393d-42a6-b9af-59c07904912b",
    "discount_title": "SWISH VAPE BOGO 50%",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "50.0% Off",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  
  "594f13db-1a9e-4d4e-aa46-5506c0ccc5a2": {
    "discount_id": "594f13db-1a9e-4d4e-aa46-5506c0ccc5a2",
    "discount_title": "$17 JOINTS - 3 FOR $42 BUNDLE",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $8.0",
      "required_buy_count": 2,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "610cc7f9-48fa-45f2-a2ed-ec439ab36617": {
    "discount_id": "610cc7f9-48fa-45f2-a2ed-ec439ab36617",
    "discount_title": "THE CURE COMPANY 3.5G BOGO PREROLL",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "620c2cd2-8f3a-491a-9dff-2711a6299c4f": {
    "discount_id": "620c2cd2-8f3a-491a-9dff-2711a6299c4f",
    "discount_title": "FRIDAY / SATURDAY / SUNDAY WYLD B2G1",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 2,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  
  "6a33c8e0-7bb1-4838-a6e5-3c563d2183b4": {
    "discount_id": "6a33c8e0-7bb1-4838-a6e5-3c563d2183b4",
    "discount_title": "$5 ITEMS - 3 FOR $12 BUNDLE",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "$3.0 Off",
      "required_buy_count": 2,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "6f6eb0f7-17b9-4078-8a21-766bfd505db7": {
    "discount_id": "6f6eb0f7-17b9-4078-8a21-766bfd505db7",
    "discount_title": "EMPLOYEE & SECURITY 30%",
    "discount_affinity": "Cart",
    "discount_amount": 30,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Customer Group",
      "discount_condition_value": "EMPLOYEE, SECURITY"
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "849491ca-0c07-4749-84ab-426c26f3a31f": {
    "discount_id": "849491ca-0c07-4749-84ab-426c26f3a31f",
    "discount_title": "KWIK EASE B2G1",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 2,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "8c5d3f52-2b86-4f9a-b396-d3829d099e1a": {
    "discount_id": "8c5d3f52-2b86-4f9a-b396-d3829d099e1a",
    "discount_title": "STREET KINGS BOGO 1.5G PREROLL",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  
  
  "9d7888b3-bd34-4419-900f-4656304e05fe": {
    "discount_id": "9d7888b3-bd34-4419-900f-4656304e05fe",
    "discount_title": "710 LABS BOGO BATTERY",
    "discount_affinity": "Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }, {
      "discount_condition_type": "Item Limit",
      "discount_condition_value": "1"
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  
  "a857528d-8c56-4d4e-a674-9f9c0fcd084f": {
    "discount_id": "a857528d-8c56-4d4e-a674-9f9c0fcd084f",
    "discount_title": "SWISH VAPE B2G1",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 2,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "b84bc0d0-deb7-4428-8f5f-a4b9517580e9": {
    "discount_id": "b84bc0d0-deb7-4428-8f5f-a4b9517580e9",
    "discount_title": "SENIOR 10%",
    "discount_affinity": "Cart",
    "discount_amount": 10,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Customer Group",
      "discount_condition_value": "SENIOR"
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "c0ade186-2ffa-4fef-a650-8107d96e7793": {
    "discount_id": "c0ade186-2ffa-4fef-a650-8107d96e7793",
    "discount_title": "710 LABS 3.5G B2G1",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 2,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "c618b468-1fda-40be-ba8b-0d309e09bf4a": {
    "discount_id": "c618b468-1fda-40be-ba8b-0d309e09bf4a",
    "discount_title": "VETERAN 10%",
    "discount_affinity": "Cart",
    "discount_amount": 10,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Customer Group",
      "discount_condition_value": "VETERAN"
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "cd216f90-541f-4975-a153-314e1a4276df": {
    "discount_id": "cd216f90-541f-4975-a153-314e1a4276df",
    "discount_title": "TURN BOGO BATTERY",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "cdc4f7c9-296b-4829-a67a-306827e9c159": {
    "discount_id": "cdc4f7c9-296b-4829-a67a-306827e9c159",
    "discount_title": "3RD PURCHASE 10%",
    "discount_affinity": "Cart",
    "discount_amount": 10,
    "discount_method": "PERCENT",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Customer Event",
      "discount_condition_value": "Nth Purchase: 3"
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "d7d4f82e-69c0-4e4f-a940-c1318cc8a911": {
    "discount_id": "d7d4f82e-69c0-4e4f-a940-c1318cc8a911",
    "discount_title": "DELIGHTED 7G BOGO",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "ddcc23eb-4912-48cf-b4ba-36e368807518": {
    "discount_id": "ddcc23eb-4912-48cf-b4ba-36e368807518",
    "discount_title": "LAST CHANCE AGING PRODUCT BOGO ",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "e501012f-97bb-403a-a3c9-57eb2886f3c9": {
    "discount_id": "e501012f-97bb-403a-a3c9-57eb2886f3c9",
    "discount_title": "THE CURE COMPANY DISPO BOGO ",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  
  "e9439a76-ae6b-4739-8ccc-e0cbb39c0f46": {
    "discount_id": "e9439a76-ae6b-4739-8ccc-e0cbb39c0f46",
    "discount_title": "FRIDAY / SATURDAY / SUNDAY GOOD TIDE BOGO",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "f8db09af-f569-4897-a681-d7c640c858d3": {
    "discount_id": "f8db09af-f569-4897-a681-d7c640c858d3",
    "discount_title": "PLUG PLAY BOGO BATTERY 10%",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "10.0% Off",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  },
  "febba80e-fb01-425a-8ac3-10e7c70b2cdc": {
    "discount_id": "febba80e-fb01-425a-8ac3-10e7c70b2cdc",
    "discount_title": "3/7 TRADITIONAL PAD 5G BOGO 3.5G",
    "discount_affinity": "Pre-Cart",
    "discount_amount": null,
    "discount_method": "BOGO",
    "discount_stackable": "No",
    "discount_condition_detail": [{
      "discount_condition_type": "Bogo Condition",
      "discount_condition_value": "Price at Value $1.0",
      "required_buy_count": 1,
      "customer_earns_number": 1
    }],
    "storeId": "AkNtGp9779aEIdDRbVmA",
    "active": true,
    "updated_at": "2025-03-29T02:43:49.224Z"
  }
};

// Export active discounts as default for backward compatibility
export default ACTIVE_DISCOUNTS;