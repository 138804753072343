import {
  SET_ACTIVE_STORE,
  CLEAR_ACTIVE_STORE,
  CACHE_STORE_DATA,
  CLEAR_STORE_CACHE,
  SET_PRODUCT_STORES,
  SET_BEST_PRICE_STORE
} from '../actions/storeActions';

const initialState = {
  activeStoreId: null,
  showedConfirmation: false,
  lastSelectedAt: null,
  cachedStores: {},
  productStores: {},  // Map of productId to array of stores
  bestPriceStores: {} // Map of productId to best price store
};

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_STORE:
      return {
        ...state,
        activeStoreId: action.payload.storeId,
        showedConfirmation: action.payload.showConfirmation,
        lastSelectedAt: Date.now()
      };
      
    case CLEAR_ACTIVE_STORE:
      return {
        ...state,
        activeStoreId: null,
        showedConfirmation: false,
        lastSelectedAt: null
      };
      
    case CACHE_STORE_DATA:
      return {
        ...state,
        cachedStores: {
          ...state.cachedStores,
          [action.payload.storeId]: {
            ...action.payload.storeData,
            cachedAt: Date.now()
          }
        }
      };
      
    case CLEAR_STORE_CACHE:
      return {
        ...state,
        cachedStores: {}
      };
      
    case SET_PRODUCT_STORES:
      return {
        ...state,
        productStores: {
          ...state.productStores,
          [action.payload.productId]: action.payload.stores
        }
      };
    
    case SET_BEST_PRICE_STORE:
      return {
        ...state,
        bestPriceStores: {
          ...state.bestPriceStores,
          [action.payload.productId]: action.payload.store
        }
      };
      
    default:
      return state;
  }
};

export default storeReducer;