import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use ES module import

import rootReducer from './reducers/rootReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'store'], // Choose reducers to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { 
        warnAfter: 200 // Default is 32ms, increasing to 200ms
      },
      serializableCheck: {
        warnAfter: 200, // Default is 32ms, increasing to 200ms
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PURGE', 'persist/FLUSH', 'persist/PAUSE', 'persist/REGISTER'], // Ignore the non-serializable values in these actions
        ignoredActionPaths: ['payload.createdAt'], // Ignore the non-serializable Timestamp in the action 
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
