import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import "./OrderDetails.css";

const OrderDetails = ({ order, isHistorical = false, buyNowProduct, buyNowQuantity, storeId, onTotalCalculated }) => {
  const cartItems = useSelector(state => state.order.cart);
  
  const calculateTotals = () => {
    if (isHistorical) {
      return calculateHistoricalTotals(order);
    } else if (buyNowProduct) {
      return calculateBuyNowTotals(buyNowProduct, buyNowQuantity, storeId);
    } else {
      return calculateStashTotals(cartItems, storeId);
    }
  };

  const calculateHistoricalTotals = (order) => {
    if (!order?.totals?.subtotal || !order?.totals?.taxes) {
      return {
        subtotal: "0.00",
        laCannabisCity: "0.00",
        caExcise: "0.00",
        salesTax: "0.00",
        savings: "0.00",
        total: "0.00"
      };
    }

    const { subtotal, taxes, total } = order.totals;
    const savings = order.totals.savings || 0;

    return {
      subtotal: (subtotal / 100).toFixed(2),
      laCannabisCity: (taxes.laCannabisCity / 100).toFixed(2),
      caExcise: (taxes.caExcise / 100).toFixed(2),
      salesTax: (taxes.salesTax / 100).toFixed(2),
      savings: (savings / 100).toFixed(2),
      total: (total / 100).toFixed(2)
    };
  };

  const calculateBuyNowTotals = (product, quantity, storeId) => {    
    // First look for the selected store ID
    const productStoreId = storeId || product.store_id || product.bestPriceStoreId;
    
    // Get the store pricing data if available
    const storeData = productStoreId && product.stores && product.stores[productStoreId];
    
    // Get prices in order of preference
    // 1. Direct properties on product (if they were set by BaseCard)
    // 2. Store-specific pricing from the stores object
    // 3. Legacy pricing object
    // 4. Default to 0
    const price = product.price || 
                  (storeData?.price) || 
                  (product.pricing?.price_sell) || 
                  0;
                  
    const discountedPrice = product.discountedPrice || 
                            (storeData?.discounted_price) || 
                            (product.pricing?.discounted_price) || 
                            price;
    
    const regularPrice = price * quantity;
    const actualPrice = discountedPrice * quantity;
    const savings = discountedPrice < price ? (price - discountedPrice) * quantity : 0;

    // Check if we're dealing with Swish store (zero taxes)
    const isSwishStore = productStoreId === "AkNtGp9779aEIdDRbVmA" || 
                         (storeData?.name && storeData.name.toLowerCase().includes("swish")) ||
                         (product.store_data?.name && product.store_data.name.toLowerCase().includes("swish"));
                         
    // Set tax rates based on store
    const isTaxFree = isSwishStore;
    const laCannabisRate = isTaxFree ? 0 : 0.10;
    const caExciseRate = isTaxFree ? 0 : 0.15;
    const salesTaxRate = isTaxFree ? 0 : 0.0975;

    const laCannabisCity = actualPrice * laCannabisRate;
    const caExcise = actualPrice * caExciseRate;
    const salesTax = actualPrice * salesTaxRate;
    const total = actualPrice + laCannabisCity + caExcise + salesTax;

    return {
      subtotal: actualPrice.toFixed(2),
      laCannabisCity: laCannabisCity.toFixed(2),
      caExcise: caExcise.toFixed(2),
      salesTax: salesTax.toFixed(2),
      savings: savings.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const calculateStashTotals = (items, storeId) => {
    let subtotal = 0;
    let savings = 0;

    items.forEach(item => {
      const regularPrice = item.price * item.quantity;
      const actualPrice = (item.discountedPrice || item.price) * item.quantity;
      
      subtotal += actualPrice;
      if (item.discountedPrice && item.discountedPrice < item.price) {
        savings += (item.price - item.discountedPrice) * item.quantity;
      }
    });

    // Check if we're dealing with Swish store (zero taxes)
    const firstItem = items[0];
    const itemStoreId = storeId || 
                       (firstItem && firstItem.store_id) || 
                       (firstItem && firstItem.store_data && firstItem.store_data.store_id);
    
    const isSwishStore = itemStoreId === "AkNtGp9779aEIdDRbVmA" || 
                         (firstItem && firstItem.store_data && 
                          firstItem.store_data.name && 
                          firstItem.store_data.name.toLowerCase().includes("swish"));
                         
    // Set tax rates based on store
    const isTaxFree = isSwishStore;
    const laCannabisRate = isTaxFree ? 0 : 0.10;
    const caExciseRate = isTaxFree ? 0 : 0.15;
    const salesTaxRate = isTaxFree ? 0 : 0.0975;

    const laCannabisCity = subtotal * laCannabisRate;
    const caExcise = subtotal * caExciseRate;
    const salesTax = subtotal * salesTaxRate;
    const total = subtotal + laCannabisCity + caExcise + salesTax;

    return {
      subtotal: subtotal.toFixed(2),
      laCannabisCity: laCannabisCity.toFixed(2),
      caExcise: caExcise.toFixed(2),
      salesTax: salesTax.toFixed(2),
      savings: savings.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const totals = calculateTotals();
  
  // Call the onTotalCalculated callback when totals change
  useEffect(() => {
    if (onTotalCalculated && typeof onTotalCalculated === 'function') {
      onTotalCalculated(totals);
    }
  }, [totals, onTotalCalculated]);

  // Get the transaction status if available
  const getTransactionStatusInfo = () => {
    if (!isHistorical || !order?.aeroPayTransaction?.status) {
      return null;
    }

    const status = order.aeroPayTransaction.status.toLowerCase();
    
    // Define status configurations
    const statusConfigs = {
      'completed': { 
        label: 'PAYMENT COMPLETED', 
        className: 'transaction-status-success',
        description: 'Your payment has been successfully processed'
      },
      'pending': { 
        label: 'PAYMENT PENDING', 
        className: 'transaction-status-pending',
        description: 'Your payment is being processed'
      },
      'processing': { 
        label: 'PAYMENT PROCESSING', 
        className: 'transaction-status-pending',
        description: 'Your payment is being processed'
      },
      'failed': { 
        label: 'PAYMENT FAILED', 
        className: 'transaction-status-error',
        description: 'There was an issue with your payment'
      },
      'cancelled': { 
        label: 'PAYMENT CANCELLED', 
        className: 'transaction-status-error',
        description: 'This payment was cancelled'
      },
      'refunded': { 
        label: 'PAYMENT REFUNDED', 
        className: 'transaction-status-warning',
        description: 'This payment has been refunded'
      },
    };
    
    return statusConfigs[status] || { 
      label: `PAYMENT ${status.toUpperCase()}`,
      className: 'transaction-status-default',
      description: 'Payment status information'
    };
  };

  const statusInfo = getTransactionStatusInfo();

  return (
    <div className="receipt-container">
      {!isHistorical && !buyNowProduct && <h2 className="receipt-header">Order Details</h2>}
      
      {/* AeroPay Transaction Status Label */}
      {statusInfo && (
        <div className={`transaction-status-banner ${statusInfo.className}`}>
          <div className="transaction-status-label">{statusInfo.label}</div>
          <div className="transaction-status-description">{statusInfo.description}</div>
          {/* {order.aeroPayTransaction.id && (
            <div className="transaction-status-id">ID: {order.aeroPayTransaction.id.slice(-8)}</div>
          )} */}
        </div>
      )}
      
      <hr className="receipt-divider" />
      
      <div className="receipt-section">
        <div className="receipt-row">
          <span className="receipt-label">Subtotal</span>
          <span className="receipt-amount">${totals.subtotal}</span>
        </div>

        <div className="receipt-row receipt-tax">
          <span className="receipt-label">LA Cannabis City Tax</span>
          <span className="receipt-amount">${totals.laCannabisCity}</span>
        </div>

        <div className="receipt-row receipt-tax">
          <span className="receipt-label">CA Excise Tax</span>
          <span className="receipt-amount">${totals.caExcise}</span>
        </div>

        <div className="receipt-row receipt-tax">
          <span className="receipt-label">Sales Tax</span>
          <span className="receipt-amount">${totals.salesTax}</span>
        </div>

        {parseFloat(totals.savings) > 0 && (
          <div className="receipt-row receipt-savings">
            <span className="receipt-label">{isHistorical ? 'You Saved' : 'You Save'}</span>
            <span className="receipt-amount">-${totals.savings}</span>
          </div>
        )}

        <hr className="receipt-divider" />
        
        <div className="receipt-row receipt-total">
          <span className="receipt-label">Total</span>
          <span className="receipt-amount">${totals.total}</span>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
