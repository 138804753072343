/**
 * discountProcessor.js
 * Fast, store-specific discount processor with hardcoded rules for maximum performance
 */

import { ACTIVE_DISCOUNTS } from './hardcodedDiscounts';

/**
 * Get currently applicable discounts based on the current day
 * @returns {Object} - Object of applicable discounts
 */
export const getCurrentlyApplicableDiscounts = () => {
  const now = new Date();
  const currentDay = now.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
  
  const applicableDiscounts = {};
  
  Object.entries(ACTIVE_DISCOUNTS).forEach(([id, discount]) => {
    // Check if discount is applicable today
    if (discount.days && discount.days.includes(currentDay)) {
      applicableDiscounts[id] = discount;
    }
  });
  
  return applicableDiscounts;
};

/**
 * Process discounts for a collection of products
 * @param {Array|Object} products - Products to process (array or single product)
 * @returns {Array|Object} - Products with discounts applied
 */
export const processDiscounts = (products) => {
  // Handle both single product and array of products
  const isArray = Array.isArray(products);
  const productArray = isArray ? products : [products];
  
  // Process each product
  const processedProducts = productArray.map(applyHardcodedDiscounts);
  
  // Log summary of discounted products if processing an array
  if (isArray && processedProducts.length > 0) {
    const discountedProducts = processedProducts.filter(p => p.discount_info);
    
    if (discountedProducts.length > 0) {
      const totalSavings = discountedProducts.reduce((sum, p) => {
        const original = p.original_price || 0;
        const discounted = p.price || 0;
        return sum + (original - discounted);
      }, 0);
      
      console.log(
        `📊 DISCOUNT SUMMARY: ${discountedProducts.length} out of ${processedProducts.length} products discounted.`,
        `Total savings: $${totalSavings.toFixed(2)}`
      );
      
      // Group discounts by type for analysis
      const discountTypes = {};
      discountedProducts.forEach(p => {
        const discountTitle = p.discount_info?.title || 'Unknown Discount';
        discountTypes[discountTitle] = (discountTypes[discountTitle] || 0) + 1;
      });
      
      console.log('📊 DISCOUNT BREAKDOWN:', discountTypes);
    }
  }
  
  // Return in the same format as input
  return isArray ? processedProducts : processedProducts[0];
};

/**
 * Ultra-fast store discount calculator optimized for the productCatalogLoader pipeline
 * 
 * @param {Object} storeData - Store data with potential discounts
 * @returns {Object} - Object with discounted price and discount info
 */
export const calculateStoreDiscount = (storeData) => {
  // Skip if no store data, price, or discounts
  if (!storeData || !storeData.price || !storeData.discounts) {
    return {
      price: storeData?.price || 0,
      discountInfo: null
    };
  }
  
  const originalPrice = storeData.price;
  
  // Get discount IDs from this store
  const discountIds = Array.isArray(storeData.discounts) 
    ? storeData.discounts.map(d => typeof d === 'string' ? d : d.discount_id).filter(Boolean)
    : [storeData.discounts];
  
  if (discountIds.length === 0) {
    return {
      price: originalPrice,
      discountInfo: null
    };
  }
  
  // Get the applicable discounts for today
  const applicableDiscounts = getCurrentlyApplicableDiscounts();
  
  // Apply highest discount to the product
  let bestDiscount = null;
  let bestDiscountAmount = 0;
  let bestDiscountMethod = null;
  
  discountIds.forEach(discountId => {
    // Skip if not a string ID
    if (typeof discountId !== 'string') return;
    
    // Check if we have this discount in our applicable hardcoded discounts
    const discount = applicableDiscounts[discountId];
    
    // Skip if discount not found or not active
    if (!discount || !discount.active) return;
    
    // Calculate discount amount
    let discountAmount = 0;
    
    if (discount.discount_method === 'PERCENT') {
      discountAmount = (originalPrice * discount.discount_amount) / 100;
    } else if (discount.discount_method === 'FLAT') {
      discountAmount = discount.discount_amount;
    } else {
      // Skip unknown discount methods
      return;
    }
    
    // If this is the best discount so far, use it
    if (discountAmount > bestDiscountAmount) {
      bestDiscount = discount;
      bestDiscountAmount = discountAmount;
      bestDiscountMethod = discount.discount_method;
    }
  });
  
  // If no applicable discount found, return original price
  if (!bestDiscount) {
    return {
      price: originalPrice,
      discountInfo: null
    };
  }
  
  // Calculate final discounted price
  let discountedPrice = originalPrice;
  
  if (bestDiscountMethod === 'PERCENT') {
    discountedPrice = originalPrice - (originalPrice * bestDiscount.discount_amount / 100);
  } else if (bestDiscountMethod === 'FLAT') {
    discountedPrice = originalPrice - bestDiscount.discount_amount;
  }
  
  // Ensure price doesn't go below $0.01
  discountedPrice = Math.max(0.01, discountedPrice);
  
  // Calculate savings percent for display
  const savingsPercent = ((originalPrice - discountedPrice) / originalPrice) * 100;
  
  return {
    price: discountedPrice,
    discountInfo: {
      id: bestDiscount.discount_id,
      title: bestDiscount.discount_title,
      amount: bestDiscount.discount_amount,
      method: bestDiscountMethod,
      savings_percent: Math.round(savingsPercent)
    }
  };
};

/**
 * Calculate discount percentage
 * @param {Object} storeData - Store data
 * @returns {number} - Discount percentage (0-100)
 */
export const calculateDiscountPercent = (storeData) => {
  if (!storeData || !storeData.price) return 0;
  
  const originalPrice = storeData.price;
  const { price: discountedPrice } = calculateStoreDiscount(storeData);
  
  if (discountedPrice >= originalPrice) return 0;
  
  const savings = originalPrice - discountedPrice;
  return Math.round((savings / originalPrice) * 100);
};

/**
 * Apply hardcoded discounts to a product's store prices
 * For use with full product objects that have store data
 * 
 * @param {Object} product - Product with stores data
 * @returns {Object} - Product with discounts applied to qualifying stores
 */
function applyHardcodedDiscounts(product) {
  // Skip if no product or no stores
  if (!product || !product.stores) return product;
  
  // Clone the product to avoid modifying the original
  const result = { ...product };
  let isAnyDiscountApplied = false;
  let discountLog = {
    product_id: product.id,
    product_name: product.name,
    stores_with_discounts: [],
    original_price: null,
    discounted_price: null,
    savings: null,
    discount_info: null
  };
  
  // Process each store in the product
  result.stores = Object.entries(product.stores).reduce((stores, [storeId, storeData]) => {
    // Skip if no store data, price, or discounts
    if (!storeData || !storeData.price) {
      return { ...stores, [storeId]: { ...storeData } };
    }
    
    // Calculate discount for this store
    const { price: discountedPrice, discountInfo } = calculateStoreDiscount({
      ...storeData,
      store_id: storeId
    });
    
    // If no discount applied, return store unchanged
    if (!discountInfo) {
      return { ...stores, [storeId]: { 
        ...storeData,
        discount_percent: 0 // Add explicit 0 discount_percent for consistency
      }};
    }
    
    // Apply the discount to this store
    isAnyDiscountApplied = true;
    
    // Track for logging purposes
    discountLog.stores_with_discounts.push({
      store_id: storeId,
      original_price: storeData.price,
      discounted_price: discountedPrice,
      discount_title: discountInfo.title,
      savings_percent: discountInfo.savings_percent
    });
    
    return { 
      ...stores, 
      [storeId]: {
        ...storeData,
        original_price: storeData.price,
        price: discountedPrice,
        discount_info: discountInfo,
        discount_percent: discountInfo.savings_percent // Add explicit discount_percent
      }
    };
  }, {});
  
  // Initialize product level discount_percent as 0
  result.discount_percent = 0;
  
  // If any store had a discount applied, recalculate the product's overall lowest price
  if (isAnyDiscountApplied) {
    let lowestPrice = Number.MAX_VALUE;
    let lowestOriginalPrice = Number.MAX_VALUE;
    let bestDiscountInfo = null;
    let highestDiscountPercent = 0;
    
    // Find the store with the lowest discounted price
    Object.values(result.stores).forEach(storeData => {
      if (storeData.price && storeData.price < lowestPrice) {
        lowestPrice = storeData.price;
        lowestOriginalPrice = storeData.original_price || storeData.price;
        bestDiscountInfo = storeData.discount_info;
        
        // Update highest discount percent
        if (storeData.discount_percent && storeData.discount_percent > highestDiscountPercent) {
          highestDiscountPercent = storeData.discount_percent;
        }
      }
    });
    
    // Update the product's top-level price and discount info
    if (lowestPrice < Number.MAX_VALUE) {
      result.price = lowestPrice;
      if (bestDiscountInfo) {
        result.original_price = lowestOriginalPrice;
        result.discount_info = bestDiscountInfo;
        result.discount_percent = bestDiscountInfo.savings_percent; // Add top-level discount_percent
        
        // Complete log info for console
        discountLog.original_price = lowestOriginalPrice;
        discountLog.discounted_price = lowestPrice;
        discountLog.savings = (lowestOriginalPrice - lowestPrice).toFixed(2);
        discountLog.discount_info = bestDiscountInfo;
        discountLog.discount_percent = bestDiscountInfo.savings_percent;
        
        // Log discounted product details
        console.log('PRODUCT RECEIVED DISCOUNT:', discountLog);
      }
    }
  }
  
  return result;
}

export default {
  processDiscounts,
  calculateStoreDiscount,
  calculateDiscountPercent,
  getCurrentlyApplicableDiscounts
};