import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { IonIcon } from '@ionic/react';
import { locationOutline, timeOutline } from 'ionicons/icons';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { addProductToCart, updateCartItemQuantity, setBuyNowProduct, clearBuyNow } from '../../redux/actions/orderActions';
import { setActiveStore, cacheStoreData, setProductStores, setBestPriceStore } from '../../redux/actions/storeActions';

import { formatUOM } from '../utils/formatUtils';
import { findBestPriceStore } from '../utils/storeUtils';
import { getSortedCannabinoids, getCannabinoidDisplayName } from '../utils/cannabinoidUtils';
import ItemDetailsModal from './ItemDetailsModal';
import BuyNowModal from './BuyNowModal';
import PortalPopup from './PortalPopup';
import BuyNowButton from "./BuyNowButton";
import AddToStashButton from "./AddToStashButton";
import QuantityCounter from "./QuantityCounter";
import ShopNowButton from "./ShopNowButton";
import defaultProductImage from '../../public/cherry-ak--1removebgpreview-1@2x.png';
import defaultStoreImage from '../../public/default-store-image.svg';
import starIcon from '../../public/star.svg';
import pickupBadge from '../../public/pickup-badge.svg';
import deliveryBadge from '../../public/delivery-badge.svg';
import StoreDetailsModal from './StoreDetailsModal';
import StoreOptionsDropup from './StoreOptionsDropup'; // Import StoreOptionsDropup component

import "./BaseCard.css";

const storage = getStorage();

const categories = [
  { src: "/marijuana-1@2x.png", alt: "Flower", title: "Flower", className: "flower", category: "FLOWER" },
  { src: "/vaping-1-2@2x.png", alt: "Vapes", title: "Vapes", className: "vapes", category: "CARTRIDGE" },
  { src: "/chocolatebar-2@2x.png", alt: "Edibles", title: "Edibles", className: "edibles", category: "EDIBLE" },
  { src: "/man-1@2x.png", alt: "Pre-Rolls", title: "Pre-Rolls", className: "pre-rolls", category: "PREROLL" },
  { src: "/wax.png", alt: "Extracts", title: "Extracts", className: "extracts", category: "EXTRACT" },
];

const BaseCard = React.memo(({ 
  cardType = 'product',  // 'product' or 'store'
  propWidth, 
  propAlignSelf, 
  id, 
  cartItemId,
  name, 
  price, 
  discountedPrice, 
  category_type,
  classification,
  brand,
  lab_results,
  sellableQuantity,
  stockStatus,
  imageUrl, 
  description,
  onLoginClick,
  onPurchaseComplete,
  // Store specific props
  address,
  hours,
  timezone,
  settings,
  metrics,
  location,
  userLocation,
  featuredProducts = [],
  isLoading = false,
  type, 
  sp,
  onSeeAllClick,
  onShopNowClick,
  onStoreSelect,
  product, // Add product prop
  initialSelectedStoreId,
  bestPriceStoreId,
  productStores,
  store,
  inventory,
  status, // Add status prop
  }) => {
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.order.cart || []);
  const storeData = useSelector(state => state.store.cachedStores || {});
  const activeStoreId = useSelector(state => state.store.activeStoreId);
  const user = useSelector(state => state.user);
  const isSignedIn = useSelector(state => state.user && state.user.email); // Get isSignedIn from state

  const [isItemDetailsModalOpen, setItemDetailsModalOpen] = useState(false);
  const [isBuyNowModalOpen, setBuyNowModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [addedToStash, setAddedToStash] = useState(false);
  const [quantity, setQuantity] = useState(1);

  // State for store prices
  const [currentPrice, setCurrentPrice] = useState(discountedPrice || price || 0);
  const [originalPrice, setOriginalPrice] = useState(price || 0);

  // State for store inventory
  const [storeQuantity, setStoreQuantity] = useState(sellableQuantity || 0);
  const [storeStockStatus, setStoreStockStatus] = useState(stockStatus || 'IN_STOCK');

  const [imageUrlFix, setImageUrlFix] = useState('/default-product-image.png');
  const [isCategoryImage, setIsCategoryImage] = useState(false);
  const loadedImages = useRef(new Set());
  const [isImageLoading, setIsImageLoading] = useState(!loadedImages.current.has(imageUrl));

  // State for managing tooltip visibility
  const [isHovered, setIsHovered] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipRef = useRef(null);
  const iconRef = useRef(null);

  const [storeImage, setStoreImage] = useState(defaultStoreImage);
  const [selectedStoreIdState, setSelectedStoreId] = useState(initialSelectedStoreId || null);
  const [productStoresState, setProductStores] = useState(productStores || {});
  const [isLoadingStore, setIsLoadingStore] = useState(false);
  const [isStoreDropupOpen, setIsStoreDropupOpen] = useState(false);
  const dropupPosition = useRef({ x: 0, y: 0 });

  // Close tooltip when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isTooltipVisible && 
          tooltipRef.current && 
          !tooltipRef.current.contains(event.target) &&
          iconRef.current && 
          !iconRef.current.contains(event.target)) {
        setIsTooltipVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isTooltipVisible]);

  useEffect(() => {
    if (initialSelectedStoreId) {
      setSelectedStoreId(initialSelectedStoreId);
    }
    
    if (productStores && Object.keys(productStores).length > 0) {
      setProductStores(productStores);
    }
  }, [initialSelectedStoreId, productStores, store]);

  // Update prices and inventory when selected store changes
  useEffect(() => {
    
    if (cardType === 'product') {
      const storeId = selectedStoreIdState || bestPriceStoreId;
      
      // Use product prop directly instead of productDetails which is defined later
      if (storeId && product?.stores && product.stores[storeId]) {
        const storeData = product.stores[storeId];
        // Get prices from the selected store
        const storeOriginalPrice = storeData.price || 0;
        const storeDiscountedPrice = storeData.discounted_price || storeOriginalPrice;
        
        // Update prices
        setOriginalPrice(storeOriginalPrice);
        setCurrentPrice(storeDiscountedPrice);
        
        // Update inventory data
        const storeSellableQuantity = storeData.sellable_quantity || 0;
        setStoreQuantity(storeSellableQuantity);
        
        // Determine stock status based on quantity
        let newStockStatus = 'IN_STOCK';
        if (storeSellableQuantity <= 0) {
          newStockStatus = 'OUT_OF_STOCK';
        } else if (storeSellableQuantity <= 5) { // Consider 5 or fewer items as low stock
          newStockStatus = 'LOW_STOCK';
        }
        setStoreStockStatus(newStockStatus);
      
      } else {
        // Fallback to props if no store data is available
        setOriginalPrice(price || 0);
        setCurrentPrice(discountedPrice || price || 0);
        setStoreQuantity(sellableQuantity || 0);
        setStoreStockStatus(stockStatus || 'IN_STOCK');
      }
    }
  }, [selectedStoreIdState, bestPriceStoreId, product, cardType, price, discountedPrice, sellableQuantity, stockStatus]);

  useEffect(() => {
    const cartItem = cartItems.find(item => item.id === id);
    if (cartItem) {
      setAddedToStash(true);
      setQuantity(cartItem.quantity);
    } else {
      setAddedToStash(false);
      setQuantity(0);
    }
  }, [cartItems, id]);


  useEffect(() => {
   
    
    if (Object.keys(productStoresState).length > 0) {
      setIsLoadingStore(false);
      return;
    }
    
    if (cardType === 'product' && id) {
      const fetchBestStore = async () => {
        setIsLoadingStore(true);
        try {
          // Sanitize product ID to handle slashes (Firebase path separators)
          const safeId = id.replace(/\//g, '_SLASH_');
          const productDoc = doc(db, 'products', safeId);
          const productSnapshot = await getDoc(productDoc);
          if (productSnapshot.exists()) {
            const productData = productSnapshot.data();

            if (productData.stores && Object.keys(productData.stores).length > 0) {
              // First pass: find the best price store
              let bestStore = null;
              let bestPrice = Number.MAX_VALUE;
              const storeDataToCache = {};
              // Normalize and mark best price store
              for (const [storeId, storeData] of Object.entries(productData.stores)) {
                
                if (storeData.pricing && 
                    storeData.pricing.discounted_price !== undefined && 
                    storeData.pricing.discounted_price !== null) {
                  
                  const currentPrice = storeData.pricing.discounted_price;
                  
                  if (storeData.sellable_quantity && storeData.sellable_quantity > 0 && currentPrice < bestPrice) {
                    bestPrice = currentPrice;
                    bestStore = storeId;
                  }
                }
                
                // Prepare updated store data with best price flag
                storeDataToCache[storeId] = {
                  ...productData.stores[storeId],
                  isBestPrice: false // Reset all first
                };
              }
              
              // Mark the best price store
              if (bestStore) {
                storeDataToCache[bestStore].isBestPrice = true;
              }
              
              // Set all stores at once to reduce renders
              dispatch(setProductStores(storeDataToCache));
              
              // Select appropriate store
              const hasItemsInCart = cartItems && cartItems.length > 0;
              
              if (hasItemsInCart && activeStoreId && storeDataToCache[activeStoreId]) {
                setSelectedStoreId(activeStoreId);
              } 
              else if (bestStore) {
                setSelectedStoreId(bestStore);
              }
              
              // Batch fetch missing store data
              const storePromises = [];
              for (const storeId of Object.keys(storeDataToCache)) {
                if (!storeData[storeId]) {
                  const storePromise = getDoc(doc(db, 'stores', storeId))
                    .then(storeSnapshot => {
                      if (storeSnapshot.exists()) {
                        const storeInfo = storeSnapshot.data();
                        dispatch(cacheStoreData(storeId, storeInfo));
                      }
                    })
                    .catch(error => {
                      console.error(`Error fetching store ${storeId}:`, error);
                    });
                  
                  storePromises.push(storePromise);
                }
              }
              
              // Wait for all store data to be fetched
              if (storePromises.length > 0) {
                await Promise.all(storePromises);
              }
            }
          }
        } catch (error) {
          console.error('Error fetching store data:', error);
        } finally {
          setIsLoadingStore(false);
        }
      };
      fetchBestStore();
    }
  }, [cardType, id, activeStoreId, cartItems, dispatch, storeData]);

  const handleImageError = () => {
    if (cardType === 'product') {
      // First try category_type, then check categories array
      const categoryItem = categories.find(cat => 
        cat.category === category_type || 
        (product?.categories || []).includes(cat.category)
      ) || categories[0];
      
      setImageUrlFix(categoryItem.src);
      setIsCategoryImage(true);
    } else {
      setImageUrlFix(defaultStoreImage);
      setIsCategoryImage(false);
    }
    setIsImageLoading(false);
  };

  useEffect(() => {
    if (loadedImages.current.has(imageUrl)) {
      setIsImageLoading(false);
      return;
    }
    
    if (!imageUrl) {
      handleImageError();
      return;
    }

    const img = new Image();
    img.onload = () => {
      loadedImages.current.add(imageUrl);
      setIsImageLoading(false);
      setImageUrlFix(imageUrl);
      setIsCategoryImage(false);
    };
    img.onerror = handleImageError;
    img.src = imageUrl;
  }, [imageUrl]);

  const handleQuantityChange = (newQuantity) => {
    dispatch(updateCartItemQuantity(null, id, newQuantity));
  };

  const handleAddToStash = () => {
    // Make sure we have a store ID - use selected store or fallback to best price store
    const storeId = selectedStoreIdState || bestPriceStoreId;
    
    if (!storeId) {
      console.error('No store selected for product');
      return;
    }
    
    // Get the store data for the selected store
    const storeData = product?.stores && product.stores[storeId];
    
    if (!storeData) {
      console.error(`Store data not found for store ID: ${storeId}`);
      return;
    }
    
    // Create a properly formatted product object with all required fields
    const enrichedProduct = {
      ...product,
      store_id: storeId,
      price: storeData.price || 0,
      discountedPrice: storeData.discounted_price || storeData.price || 0,
      quantity: 1,
      sellableQuantity: storeData.sellable_quantity || 0,
      store_name: storeData.name || '',
      product_id: storeData.product_id || product.product_id || product.slug || product.id, // Include Treez product ID
      store_data: {
        store_id: storeId,
        name: storeData.name || '',
        address: storeData.address || {},
        settings: storeData.settings || {}
      }
    };
    
    // Dispatch action with the enriched product
    dispatch(addProductToCart(enrichedProduct));
    setAddedToStash(true);
  };

  const productDetails = {
    ...product, // Pass the full product object
    id,
    name,
    price,
    discountedPrice,
    category_type,
    classification,
    brand,
    lab_results,
    sellableQuantity,
    imageUrl: imageUrlFix,
    description,
    sp: product?.sp, // Explicitly pass sp data
    stores: product?.stores // Explicitly pass stores data
  };

  const openItemDetailsModal = useCallback((productFromBuyNow, priceFromBuyNow, originalPriceFromBuyNow) => {
    if (productFromBuyNow) {
      setSelectedProduct(productFromBuyNow);
    }
    setItemDetailsModalOpen(true);
  }, []);

  const closeItemDetailsModal = useCallback(() => {
    setItemDetailsModalOpen(false);
  }, []);

  const openBuyNowModal = useCallback((product) => {
    // Make sure we have a store ID - use selected store or fallback to best price store
    const storeId = selectedStoreIdState || bestPriceStoreId;
    
    if (!storeId) {
      console.error('No store selected for product');
      return;
    }
    
    // Get the store data for the selected store
    const storeData = product?.stores && product.stores[storeId];
    
    if (!storeData) {
      console.error(`Store data not found for store ID: ${storeId}`);
      return;
    }
    
    // Get store-specific pricing
    const storeOriginalPrice = storeData.price || 0;
    const storeDiscountedPrice = storeData.discounted_price || storeOriginalPrice;
    
    // Create an enhanced product with the correct pricing structure
    const enhancedProduct = {
      ...product,
      // Add store-specific data
      store_id: storeId,
      price: storeOriginalPrice,
      discountedPrice: storeDiscountedPrice,
      sellableQuantity: storeData.sellable_quantity || 0,
      store_name: storeData.name || '',
      // Ensure the pricing object exists with correct values for backward compatibility
      pricing: {
        ...(product.pricing || {}),
        price_sell: storeOriginalPrice,
        discounted_price: storeDiscountedPrice
      },
      // Include store data
      store_data: {
        store_id: storeId,
        name: storeData.name || '',
        address: storeData.address || {},
        settings: storeData.settings || {}
      }
    };
    
    // Add the product to buyNow state in Redux with initial quantity of 1
    dispatch(setBuyNowProduct(enhancedProduct, 1));
    
    setSelectedProduct(enhancedProduct);
    setBuyNowModalOpen(true);
  }, [selectedStoreIdState, bestPriceStoreId, dispatch]);

  const closeBuyNowModal = useCallback(() => {
    // Clear buyNow state in Redux
    dispatch(clearBuyNow());
    setBuyNowModalOpen(false);
    setSelectedProduct(null); // Clear the selected product when closing the modal
  }, [dispatch]);

  const handleStoreSelect = (storeId) => {
    setSelectedStoreId(storeId);
    setIsStoreDropupOpen(false);
    
    // Call parent component's onStoreSelect if provided
    if (onStoreSelect) {
      onStoreSelect(id, storeId);
    }
  };

  const handleToggleStoreDropup = useCallback((e) => {
    e.stopPropagation();
    
    if (!isStoreDropupOpen) {
      // Calculate position only when opening
      const rect = e.currentTarget.getBoundingClientRect();
      const centerX = rect.left + (rect.width / 2);
      
      dropupPosition.current = { 
        x: centerX, 
        y: rect.top - 10, 
      };
    }
    
    setIsStoreDropupOpen(prev => !prev);
  }, [isStoreDropupOpen]);

  const frameDivStyle = useMemo(() => {
    return {
      width: propWidth || '250px',
    };
  }, [propWidth]);

  const frameDiv1Style = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  const discountPercentage = currentPrice ? Math.round(((originalPrice - currentPrice) / originalPrice) * 100) : 0;

  const lineageLabel = useMemo(() => {
    if (classification) {
      switch (classification.toLowerCase()) {
        case 'indica':
          return { text: 'Indica', backgroundColor: `rgba(var(--indica-color), var(--label-opacity))` };
        case 'sativa':
          return { text: 'Sativa', backgroundColor: `rgba(var(--sativa-color), var(--label-opacity))` }; 
        case 'cbd':
          return { text: 'CBD', backgroundColor: `rgba(var(--cbd-color), var(--label-opacity))` };
        case 'hybrid':
          return { text: 'Hybrid', backgroundColor: `rgba(var(--hybrid-color), var(--label-opacity))` };
        default:
          return null;
      }
    }
    return null;
  }, [classification]);

  const getFulfillmentLabel = () => {
    const pickupEnabled = settings?.pickup?.enabled;
    const deliveryEnabled = settings?.delivery?.enabled;

    if (pickupEnabled && deliveryEnabled) {
      return {
        text: 'Pickup + Delivery',
        backgroundColor: `rgba(var(--indica-color), var(--label-opacity))`
      };
    } else if (pickupEnabled) {
      return {
        text: 'Pickup Only',
        backgroundColor: `rgba(var(--indica-color), var(--label-opacity))`
      };
    } else if (deliveryEnabled) {
      return {
        text: 'Delivery Only',
        backgroundColor: `rgba(var(--indica-color), var(--label-opacity))`
      };
    }
    return null;
  };

  

  const handleImageLoad = (e) => {
    e.target.classList.add('loaded');
  };

  const formatAddress = () => {
    if (!address) return { street: '', location: '' };
    
    // Remove country from the address
    const addressWithoutCountry = address.replace(/, USA$/, '');
    
    const [street, ...rest] = addressWithoutCountry.split(',');
    return {
      street: street.trim(),
      location: rest.join(',').trim()
    };
  };

  const getDayName = (dayIndex) => {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return days[dayIndex];
  };

  const formatTime = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    let period = 'am';
    let displayHours = hours;
    
    if (hours >= 12) {
      period = 'pm';
      if (hours > 12) {
        displayHours = hours - 12;
      }
    }
    if (hours === 0) {
      displayHours = 12;
    }
    
    return `${displayHours}:${minutes.toString().padStart(2, '0')}${period}`;
  };

  const formatTodayHours = () => {
    if (!hours) return 'Hours not available';
    
    const now = new Date();
    const storeTimezone = hours.timezone || timezone;
    const localTime = new Date(now.toLocaleString('en-US', { timeZone: storeTimezone }));
    const dayName = getDayName(localTime.getDay());
    const todayHours = hours[dayName];
    
    if (!todayHours || !todayHours.open || !todayHours.close) {
      return 'Closed today';
    }
    return `${formatTime(todayHours.open)} - ${formatTime(todayHours.close)}`;
  };

  const isClosingSoon = () => {
    if (!hours) return false;
    
    const now = new Date();
    const storeTimezone = hours.timezone || timezone;
    const localTime = new Date(now.toLocaleString('en-US', { timeZone: storeTimezone }));
    const dayName = getDayName(localTime.getDay());
    const todayHours = hours[dayName];
    
    if (!todayHours?.close) return false;
    
    const [closeHour, closeMinute] = todayHours.close.split(':').map(Number);
    const closeTime = new Date(localTime);
    closeTime.setHours(closeHour, closeMinute, 0);
    
    const timeDiff = closeTime - localTime;
    const hoursDiff = timeDiff / (1000 * 60 * 60);
    
    return hoursDiff > 0 && hoursDiff <= 1;
  };

  const pickupBadge = "/pickup-badge.svg";
  const deliveryBadge = "/delivery-badge.svg";

  return (
    <div 
      className="product-card" 
      style={{ width: propWidth, alignSelf: propAlignSelf }}
      data-card-type={cardType}
    >
      <div 
                ref={iconRef}
                className="info-icon-container"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsTooltipVisible(!isTooltipVisible);
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="expand-icon">
                  <circle cx="12" cy="12" r="11" fill="white" stroke="var(--color-darkblue)" strokeWidth="1.5" />
                  <circle cx="12" cy="8" r="1.25" fill="var(--color-darkblue)" />
                  <path d="M12 11V17" stroke="var(--color-darkblue)" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </div>
              {(isHovered || isTooltipVisible) && (
                <div 
                  ref={tooltipRef}
                  className="effects-tooltip"
                  onClick={(e) => e.stopPropagation()}
                >
                  {/* Show effects from product.effects */}
                  {product?.effects && product.effects.length > 0 && (
                    <div className="tooltip-tags">
                      {product.effects.slice(0, 3).map((effect, i) => (
                        <span key={i} className="tooltip-tag effect">{effect}</span>
                      ))}
                    </div>
                  )}
                  
                  {/* Show effects from product.attributes.effects if product.effects is empty */}
                  {(!product?.effects || product.effects.length === 0) && 
                   product?.attributes?.effects && product.attributes.effects.length > 0 && (
                    <div className="tooltip-tags">
                      {product.attributes.effects.slice(0, 3).map((effect, i) => (
                        <span key={i} className="tooltip-tag effect">{effect}</span>
                      ))}
                    </div>
                  )}
                  
                  {/* Cannabis info with cannabinoid tags */}
                  <div className="tooltip-tags">
                    {getSortedCannabinoids(product, 3).map((cannabinoid) => (
                      <span key={cannabinoid.key} className="tooltip-tag thc">
                        {getCannabinoidDisplayName(cannabinoid.key)}: {cannabinoid.display}
                      </span>
                    ))}
                  </div>
                  
                  {/* Show activities */}
                  {product?.activities && product.activities.length > 0 && (
                    <div className="tooltip-tags">
                      {product.activities.map((activity, i) => (
                        <span key={i} className="tooltip-tag activity">{activity}</span>
                      ))}
                    </div>
                  )}
                </div>
              )}
      <div 
        className="card-upper-content"
        onClick={() => openItemDetailsModal(productDetails)}
        style={{ cursor: 'pointer' }}
      >
        <div className="image-container">
          {isImageLoading ? (
            <div className="skeleton-image"></div>
          ) : (
            <div className="image-wrapper">
              <img
                className={`${cardType === 'product' ? 'product-image' : 'store-image'} ${isCategoryImage ? 'category-image' : ''}`}
                loading="lazy"
                alt={cardType === 'product' ? `${name || 'Product'} Image` : `${name || 'Store'} Image`}
                src={imageUrlFix}
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
              
              {cardType === 'product' ? (
                // Product card labels
                <div className="labels-container">
                  {discountPercentage > 0 && (
                    <div className="price-label-bc">{discountPercentage}% OFF</div>
                  )}
                  {lineageLabel && (
                    <div 
                      className="lineage-label-bc" 
                      style={{ backgroundColor: lineageLabel.backgroundColor }}
                    >
                      {lineageLabel.text}
                    </div>
                  )}
                  {storeStockStatus === 'LOW_STOCK' && (
                    <div className="low-stock-label">
                      {storeQuantity} left
                    </div>
                  )}
                  {storeStockStatus === 'OUT_OF_STOCK' && (
                    <div className="out-of-stock-label">
                      Out of Stock
                    </div>
                  )}
                </div>
              ) : (
                // Store card labels
                <>
                  <div className="price-label-bc">Up to 40% Off</div>
                  {getFulfillmentLabel() && (
                    <div 
                      className="lineage-label-bc" 
                      style={{ backgroundColor: getFulfillmentLabel().backgroundColor }}
                    >
                      {getFulfillmentLabel().text}
                    </div>
                  )}
                </>
              )}
              
            </div>
          )}
        </div>
        
        <div className={` ${cardType === 'store' ? 'store-info' : 'product-info'}`}>
          
          {cardType === 'product' ? (
            <>
              <div className="product-core-info">
                <h3 className="product-name">{name} {product.amount}{formatUOM(product.uom)} {product.doses_units > 1 ? ` (${product.doses_units}pk)` : ''}</h3>
                <div className="product-brand">{brand}</div>
                <div className="price-info-bc">
                  <div className="prices-only">
                    {discountPercentage > 0 && (
                      <span className="original-price-bc">${originalPrice.toFixed(2)}</span>
                    )}
                    <span className="current-price-bc">${currentPrice.toFixed(2)}</span>
                  </div>
                </div>
              </div>
              {/* Updated store selector implementation */}
              {(selectedStoreIdState || bestPriceStoreId) && (
                <div className="store-info-container">
                  {isLoadingStore ? (
                    <div className="product-store-badge loading">
                      <div className="store-badge-icon">
                        <img src={defaultStoreImage} alt="Loading" />
                      </div>
                      <div className="store-badge-name">Loading store...</div>
                    </div>
                  ) : (
                    <div 
                      className="product-store-badge" 
                      onClick={handleToggleStoreDropup}
                      title="Select store"
                      role="button"
                      aria-label="Select store"
                    >
                      <div className="store-badge-icon">
                        <img 
                          src={(productStoresState[selectedStoreIdState || bestPriceStoreId]?.storeImageUrl) || 
                              (storeData[selectedStoreIdState || bestPriceStoreId]?.imageUrl) || 
                              defaultStoreImage} 
                          alt="Store" 
                          onError={(e) => { e.target.src = defaultStoreImage; }}
                        />
                      </div>
                      <div className="store-badge-name">
                        {(productStoresState[selectedStoreIdState || bestPriceStoreId]?.storeName) || 
                         (storeData[selectedStoreIdState || bestPriceStoreId]?.name) || 
                         'Select Store'}
                      </div>
                    </div>
                  )}
                  {isStoreDropupOpen && ReactDOM.createPortal(
                    <div className="store-dropup-portal" style={{position:'fixed',zIndex:9999,left:0,top:0,width:'100%',height:'100%',pointerEvents:'none'}}>
                      <div style={{position:'absolute',left:`${dropupPosition.current.x}px`,top:`${dropupPosition.current.y}px`,transform:'translate(-50%, -100%)',pointerEvents:'auto'}}>
                        <StoreOptionsDropup
                          stores={productStoresState}
                          productId={id}
                          onStoreSelect={handleStoreSelect}
                          onClose={() => setIsStoreDropupOpen(false)}
                          selectedStoreId={selectedStoreIdState}
                          storeData={{ ...storeData, ...(store ? { [store.storeId]: store } : {}) }}
                          price={price}
                          discountedPrice={discountedPrice}
                          bestPriceStoreId={bestPriceStoreId}
                        />
                      </div>
                    </div>,
                    document.body
                  )}
                </div>
              )}
            </>
          ) : (
            cardType === 'store' && (
              <>
                <h3 className="store-name">{name}</h3>
                <div className="store-location">
                  <IonIcon icon={locationOutline} className="store-icon" />
                  <div className="store-text">
                    <div className="street-address">{formatAddress(address).street}</div>
                    <div className="location-address">{formatAddress(address).location}</div>
                    {/* Temporarily disabled distance display
                    <div className="distance">
                      {location && userLocation ? 
                        `${(location.distanceMiles || 0).toFixed(1)} miles away` :
                        '1.5 miles away'
                      }
                    */}
                  </div>
                </div>
                <div className="store-hours">
                  <IonIcon icon={timeOutline} className="store-icon" />
                  <div className="store-text">
                    <div>Today: {formatTodayHours(hours, timezone)}</div>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </div>
      <div className="divider-bc" />
      <div className="action-buttons">
        {cardType === 'product' ? (
          // Product card buttons
          user && user.email ? (
            <>
              <BuyNowButton buttonText="Buy Now" action={openBuyNowModal.bind(null, productDetails)} />
              {addedToStash ? (
                <QuantityCounter 
                  inventoryCount={storeQuantity} 
                  onQuantityChange={handleQuantityChange}
                  initialQuantity={quantity}
                  productId={id}
                />
              ) : (
                <AddToStashButton 
                  title="Add to Stash" 
                  action={handleAddToStash}
                />
              )}
            </>
          ) : (
            <AddToStashButton 
              title="Login to Buy" 
              action={onLoginClick}
            />
          )
        ) : (
          // Store card button
          <ShopNowButton onClick={onStoreSelect} isSpotlight={status === "SOON"} />
        )}
      </div>
      {isItemDetailsModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeItemDetailsModal}
        >
          <ItemDetailsModal 
            product={selectedProduct || product || productDetails} 
            onClose={closeItemDetailsModal}
            currentPrice={currentPrice}
            originalPrice={originalPrice}
          />
        </PortalPopup>
      )}
      {isBuyNowModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeBuyNowModal}
        >
          <BuyNowModal 
            product={selectedProduct}
            currentPrice={currentPrice}
            originalPrice={originalPrice}
            onClose={closeBuyNowModal}
            onOpenItemDetails={openItemDetailsModal}
            onPurchaseComplete={onPurchaseComplete}
            initialQuantity={quantity}
          />   
        </PortalPopup>
      )}
    </div>
  );
});

export default BaseCard;