import "./LocationFull.css";

const LocationFull = () => {
  return (
    <div className="frame-parent45">
      <div className="pickup-location-wrapper">
        <div className="pickup-location">Pickup Location</div>
      </div>
      <div className="frame-parent46">
        <div className="frame-wrapper16">
          <div className="logo-container">
            <img
              className="frame-child13"
              loading="lazy"
              alt="Swish Cannabis"
              src="https://firebasestorage.googleapis.com/v0/b/stashpandas24.appspot.com/o/product_images%2FAkNtGp9779aEIdDRbVmA_swish.png?alt=media&token=fe4419ce-09b6-4c14-be1e-2c535a568772"
            />
          </div>
        </div>
        <div className="the-woods-container">
          <p className="the-woods-">Swish Cannabis</p>
          <p className="main-st-los">
            10701 Burbank Blvd, <br className="mobile-break" />
            North Hollywood, CA 91601
          </p>
        </div>
      </div>
    </div>
  );
};

export default LocationFull;
