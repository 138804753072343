import React, { useRef, useEffect } from 'react';
import './StoreOptionsDropup.css';
import defaultStoreImage from '../../public/default-store-image.svg';

const StoreOptionsDropup = ({ 
  stores, 
  productId,
  onStoreSelect, 
  onClose,
  selectedStoreId,
  storeData,
  price: productPrice,
  discountedPrice: productDiscountedPrice
}) => {
  const dropupRef = useRef(null);

  // Close the dropup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropupRef.current && !dropupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!stores || Object.keys(stores).length === 0) {
    return (
      <div className="store-options-dropup" ref={dropupRef}>
        <div className="no-stores-message">No stores available for this product</div>
      </div>
    );
  }

  // Format store address
  const formatAddress = (address) => {
    if (!address) return 'No address available';
    
    // If address is a string, break it into parts
    if (typeof address === 'string') {
      const parts = address.split(',').map(part => part.trim());
      
      if (parts.length >= 2) {
        // First part is street address
        const streetAddress = parts[0];
        
        // Remaining parts are city, state, zip
        const cityStateZip = parts.slice(1).join(', ');
        
        // Return as object with two parts
        return {
          street: streetAddress,
          cityStateZip: cityStateZip
        };
      }
      
      // If can't split properly, just return the full address
      return {
        street: address,
        cityStateZip: ''
      };
    }
    
    // Otherwise handle as an object
    const street = address.street || '';
    const city = address.city || '';
    const state = address.state || '';
    const zip = address.zip || '';
    
    let cityStateZip = '';
    if (city && state && zip) {
      cityStateZip = `${city}, ${state} ${zip}`;
    } else if (city && state) {
      cityStateZip = `${city}, ${state}`;
    } else if (city) {
      cityStateZip = city;
    }
    
    return {
      street: street,
      cityStateZip: cityStateZip
    };
  };

  return (
    <div className="store-options-dropup" ref={dropupRef}>
      <div className="dropup-header">
        <h4>Select Store</h4>
        <button className="close-btn" onClick={(e) => {
          e.stopPropagation(); // Stop event propagation
          onClose();
        }}>×</button>
      </div>
      {console.log('Stores data in dropdown:', stores)}
      <div className="store-options-list">
        {Object.entries(stores).map(([storeId, storeInfo]) => {
          // Skip stores with no inventory
          if (!storeInfo.sellable_quantity || storeInfo.sellable_quantity <= 0) {
            return null;
          }
          
          // Get store details from the cached store data or from the store info itself
          const store = storeData[storeId] || {};
          console.log('Store info for display:', { storeId, storeInfo, store });
          
          // Try to access price from different possible sources
          const price = 
            storeInfo.discounted_price || 
            storeInfo.price_sell || 
            storeInfo.price || 
            (storeInfo.pricing ? (storeInfo.pricing.discounted_price || storeInfo.pricing.price_sell || storeInfo.pricing.price) : null) || 
            (storeId === selectedStoreId ? (productDiscountedPrice || productPrice) : null) ||
            0;
          
          const isBestPrice = storeInfo.isBestPrice;
          
          // Get store display name from new fields or fallback to cached data
          const storeName = storeInfo.storeName || store.name || 'Store';
          
          // Get store image from new fields or fallback to cached data
          const storeImage = storeInfo.imageUrl || store.imageUrl || defaultStoreImage;
          
          // Get store address from new fields or fallback to cached data
          const storeAddress = storeInfo.address || store.address || '';
          
          return (
            <div 
              key={storeId} 
              className={`store-option ${selectedStoreId === storeId ? 'selected' : ''}`}
              onClick={(e) => {
                e.stopPropagation(); // Stop event propagation
                onStoreSelect(storeId, productId);
              }}
            >
              <div className="store-option-left">
                <div className="store-option-icon">
                  <img src={storeImage} alt={storeName} onError={(e) => { e.target.src = defaultStoreImage; }} />
                </div>
                <div className="store-option-info">
                  <div className="store-option-name">{storeName}</div>
                  <div className="store-option-address">
                    <div className="address-street">{formatAddress(storeAddress).street}</div>
                    {formatAddress(storeAddress).cityStateZip && (
                      <div className="address-city-state-zip">{formatAddress(storeAddress).cityStateZip}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="store-option-right">
                <div className="store-option-price">${price.toFixed(2)}</div>
                {isBestPrice && <div className="store-option-best">Best Price</div>}
                {storeInfo.sellable_quantity <= 10 && (
                  <div className="store-option-inventory">Only {storeInfo.sellable_quantity} left</div>
                )}
                {selectedStoreId === storeId && <div className="store-option-selected">✓</div>}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StoreOptionsDropup;
