// Cache for geocoding results (lasts until page refresh)
const geocodeCache = new Map();

// Rate limiting variables
let lastRequestTime = 0;
const MIN_REQUEST_INTERVAL = 1000; // 1 second between requests

// Helper function to enforce rate limiting
const enforceRateLimit = async () => {
  const now = Date.now();
  const timeElapsed = now - lastRequestTime;
  
  if (timeElapsed < MIN_REQUEST_INTERVAL) {
    // Wait before making the next request
    await new Promise(resolve => setTimeout(resolve, MIN_REQUEST_INTERVAL - timeElapsed));
  }
  
  // Update the last request time
  lastRequestTime = Date.now();
};

// Search for address suggestions
export const searchAddress = async (query) => {
  try {
    // Don't search if query is empty
    if (!query.trim()) {
      return [];
    }

    const cacheKey = `search_${query}`;
    
    // Check cache first
    if (geocodeCache.has(cacheKey)) {
      return geocodeCache.get(cacheKey);
    }

    // Apply rate limiting
    await enforceRateLimit();

    // Search using Nominatim
    const response = await fetch(
      `${import.meta.env.VITE_ADDRESS_VALIDATION_API}?q=${encodeURIComponent(query)}&format=json&addressdetails=1&countrycodes=us&limit=5`,
      {
        headers: {
          'User-Agent': 'StashPandas/1.0'
        }
      }
    );

    const results = await response.json();
    
    // Format results
    const suggestions = results.map(result => ({
      formatted: result.display_name,
      address: {
        street: [result.address.house_number, result.address.road].filter(Boolean).join(' '),
        city: result.address.city || result.address.town,
        state: result.address.state,
        zip: result.address.postcode
      },
      location: {
        lat: parseFloat(result.lat),
        lng: parseFloat(result.lon)
      }
    }));

    // Cache results
    geocodeCache.set(cacheKey, suggestions);

    return suggestions;
  } catch (error) {
    console.error('Address search error:', error);
    return []; // Return empty array instead of throwing
  }
};

// Validate a specific address
export const validateAddress = async (address) => {
  try {
    const cacheKey = `validate_${JSON.stringify(address)}`;

    // Check cache first
    if (geocodeCache.has(cacheKey)) {
      return geocodeCache.get(cacheKey);
    }

    // Apply rate limiting
    await enforceRateLimit();

    // Search for exact address
    const searchQuery = encodeURIComponent(
      `${address.street}, ${address.city}, ${address.state} ${address.zip}, USA`
    );

    const response = await fetch(
      `${import.meta.env.VITE_ADDRESS_VALIDATION_API}?q=${searchQuery}&format=json&addressdetails=1&limit=1`,
      {
        headers: {
          'User-Agent': 'StashPandas/1.0'
        }
      }
    );

    const results = await response.json();

    if (!results || results.length === 0) {
      throw new Error('Address not found');
    }

    const result = results[0];
    const validated = {
      formatted: result.display_name,
      address: {
        street: [result.address.house_number, result.address.road].filter(Boolean).join(' '),
        city: result.address.city || result.address.town,
        state: result.address.state,
        zip: result.address.postcode
      },
      location: {
        lat: parseFloat(result.lat),
        lng: parseFloat(result.lon)
      }
    };

    // Cache result
    geocodeCache.set(cacheKey, validated);

    return validated;
  } catch (error) {
    console.error('Address validation error:', error);
    throw error;
  }
};
