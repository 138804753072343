import React, { useState, useEffect, useMemo } from "react";
import "./ProductImageSmall.css";

const categories = [
  { src: "/marijuana-1@2x.png", alt: "Flower", title: "Flower", className: "flower", category: "FLOWER" },
  { src: "/vaping-1-2@2x.png", alt: "Vapes", title: "Vapes", className: "vapes", category: "CARTRIDGE" },
  { src: "/chocolatebar-2@2x.png", alt: "Edibles", title: "Edibles", className: "edibles", category: "EDIBLE" },
  { src: "/man-1@2x.png", alt: "Pre-Rolls", title: "Pre-Rolls", className: "pre-rolls", category: "PREROLL" },
  { src: "/wax.png", alt: "Extracts", title: "Extracts", className: "extracts", category: "EXTRACT" },
  { src: "/topicals-1@2x.png", alt: "Topicals", title: "Topicals", className: "topicals", category: "TOPICAL" },
  { src: "/tincture-1@2x.png", alt: "Tinctures", title: "Tinctures", className: "tinctures", category: "TINCTURE" },
  { src: "/concentrate-1@2x.png", alt: "Concentrates", title: "Concentrates", className: "concentrates", category: "CONCENTRATE" },
];

const ProductImageSmall = React.memo(({ imageUrl, category, product }) => {
  const getCategoryImage = React.useMemo(() => {
    const categoryImage = categories.find(
      (cat) => cat.category === category
    );
    return categoryImage ? categoryImage.src : categories[0].src;
  }, [category]);

  const [imgSrc, setImgSrc] = useState(() => imageUrl || getCategoryImage);

  useEffect(() => {
    const newSrc = imageUrl || getCategoryImage;
    if (newSrc !== imgSrc) {
      setImgSrc(newSrc);
    }
  }, [imageUrl, getCategoryImage]);

  const handleImageError = () => {
    setImgSrc(getCategoryImage);
  };

  const handleImageLoad = (e) => {
    e.target.classList.add('loaded');
  };

  return (
    <div className="product-image-small">
      <img
        className="product-image"
        src={imgSrc}
        alt="Product"
        loading="lazy"
        onError={handleImageError}
        onLoad={handleImageLoad}
      />
    </div>
  );
});

export default ProductImageSmall;
