import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { IonIcon, IonSpinner } from '@ionic/react';
import { doc, getDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../../firebase';
import { timeOutline, bagOutline, cardOutline, helpCircleOutline, alertCircleOutline, closeCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';

import { removeFromCart, updateCartItemQuantity, addProductToCart, createOrder } from '../../redux/actions/orderActions';
import { fetchAeroPayBanks, createAeroPayTransaction, pollTransactionStatus } from '../../redux/actions/aeropayActions';
import { useOrders } from '../hooks/useOrders';
import { previewTreezTicket, createTreezTicket } from '../../utils/TreezService';

import ModalHeader from "./ModalHeader";
import StashItem from "./StashItem";
import OrderDetails from "./OrderDetails";
import LocationFull from "./LocationFull";
import VerificationForm from "./VerificationForm";
import OrderItem from './OrderItem';
import AeroPayWidget from './widgets/AeropayWidget';
import PurchasePaymentMethod from './PurchasePaymentMethod';

import "./StashModal.css";

const StashModal = memo(({ onClose, products, onPurchaseComplete, initialMode = 'stash' }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  const [imageUrls, setImageUrls] = useState({});
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState(false);
  const [mode, setMode] = useState(initialMode);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [latestProducts, setLatestProducts] = useState({});
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [showAddPayment, setShowAddPayment] = useState(false);
  const [orderTotal, setOrderTotal] = useState("0.00");

  const dispatch = useDispatch();
  const user = useSelector(state => state.user, shallowEqual);
  const cartItems = useSelector(state => state.order.cart, shallowEqual);
  const { orders, loading: ordersLoading, error: ordersError, addOrder } = useOrders(user?.userId);

  const renderCount = useRef(0);
  useEffect(() => {
    renderCount.current += 1;
  });

  useEffect(() => {
    setMode(initialMode);
  }, [initialMode]);

  useEffect(() => {
    // Add modal-open class to body when component mounts
    document.body.classList.add('modal-open');

    // Clean up function to remove class when component unmounts
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    // Set loading state when switching to history mode
    if (mode === 'history') {
      setIsHistoryLoading(true);
      // Use a small timeout to prevent flash
      const timer = setTimeout(() => {
        setIsHistoryLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [mode]);

  useEffect(() => {
    const fetchLatestProductData = async () => {
      setIsLoadingProducts(true);
      const updatedProducts = {};
      
      for (const item of cartItems) {
        try {
          const productRef = doc(db, 'products', item.id);
          const productDoc = await getDoc(productRef);
          
          if (productDoc.exists()) {
            const productData = productDoc.data();
            
            // Get store-specific data
            const storeRef = doc(db, 'products', item.id, 'stores', item.store_id);
            const storeDoc = await getDoc(storeRef);
            const storeData = storeDoc.exists() ? storeDoc.data() : {};
            
            updatedProducts[item.id] = {
              ...productData,
              ...storeData,
              id: item.id,
              sellableQuantity: storeData.sellable_quantity || 0
            };
          }
        } catch (err) {
          setError('Error fetching latest product data');
        }
      }
      
      setLatestProducts(updatedProducts);
      setIsLoadingProducts(false);
    };

    if (cartItems.length > 0) {
      fetchLatestProductData();
    }
  }, [cartItems]);

  

  const handleRemoveFromCart = (itemId) => {
    dispatch(removeFromCart(itemId));
  };

  const handlePurchase = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Get store ID from first cart item
      const storeId = cartItems.length > 0 ? cartItems[0].store_id : null;
      
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: cartItems.map(item => {
          // Log the item to see what product_id is available
          
          // Add additional debug logging to help track Treez product ID issues
          if (!item.product_id || item.product_id === item.id) {
            console.warn('POTENTIAL ISSUE: item.product_id appears to be the canonical key instead of Treez size_id:', {
              item_id: item.id,
              product_id: item.product_id,
              is_same_as_canonical_key: item.product_id === item.id
            });
          } else {
          }
          
          return {
            size_id: item.product_id, // Use the Treez-specific product_id
            quantity: item.quantity,
          };
        }),
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };
      
      const result = await createTreezTicket(ticketData);
      if (result.resultCode === 'SUCCESS') {
        const totalAmount = cartItems.reduce((total, item) => {
          const product = latestProducts[item.id];
          const price = product?.pricing?.discounted_price || product?.pricing?.price_sell || item.price;
          return total + parseFloat((price * item.quantity).toFixed(2));
        }, 0);
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: cartItems.map(item => {
            const product = latestProducts[item.id];
            const price = product?.pricing?.discounted_price || product?.pricing?.price_sell || item.price;
            return {
              productId: item.id,
              quantity: item.quantity,
              price: price,
            };
          }),
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: result.data?.ticket_id || '',
          treezOrderNumber: result.data?.order_number || '',
          treezOrderStatus: result.data?.order_status || '',
          treezPaymentStatus: result.data?.payment_status || '',
          isDirectPurchase: false
        };

        // Important: still await the order creation, but no need to save to state
        try {
          const orderId = await dispatch(createOrder(orderData, result));
          // Immediately add the order to state so it appears without waiting for Firebase
          addOrder(orderId, orderData);
        } catch (orderError) {
          setError(`Failed to create order: ${orderError.message || 'Unknown error'}`);
          return;
        }
        
        for (const item of cartItems) {
          await dispatch(removeFromCart(user.userId, item.id));
        }

        setMode('history');
        // onPurchaseComplete();
      } else {
        setError("Ticket creation was not successful. Please try again.");
      }
    } catch (error) {
      setError("Failed to create ticket. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviewPurchase = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Get store ID from first cart item
      const storeId = cartItems.length > 0 ? cartItems[0].store_id : null;
      console.log('Cart items for preview:', cartItems);
      
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: cartItems.map(item => {
          // Log the item to see what product_id is available
          console.log('Processing item for Treez API preview:', item);
          
          // Add additional debug logging to help track Treez product ID issues
          if (!item.product_id || item.product_id === item.id) {
            console.warn('POTENTIAL ISSUE: item.product_id appears to be the canonical key instead of Treez size_id:', {
              item_id: item.id,
              product_id: item.product_id,
              is_same_as_canonical_key: item.product_id === item.id
            });
          } else {
            console.log('Using Treez size_id:', item.product_id);
          }
          
          return {
            size_id: item.product_id, // Use the Treez-specific product_id
            quantity: item.quantity,
          };
        }),
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };

      console.log('Previewing Treez ticket with data:', JSON.stringify(ticketData, null, 2));
      const previewResult = await previewTreezTicket(ticketData);
      console.log('Preview result:', previewResult);
      if (previewResult.resultCode === 'SUCCESS') {
        const totalAmount = cartItems.reduce((total, item) => {
          const product = latestProducts[item.id];
          const price = product?.pricing?.discounted_price || product?.pricing?.price_sell || item.price;
          return total + parseFloat((price * item.quantity).toFixed(2));
        }, 0);
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(),
          totalAmount: totalAmount,
          items: cartItems.map(item => {
            const product = latestProducts[item.id];
            const price = product?.pricing?.discounted_price || product?.pricing?.price_sell || item.price;
            return {
              productId: item.id,
              quantity: item.quantity,
              price: price,
            };
          }),
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: previewResult.data?.ticket_id || '',
          treezOrderNumber: previewResult.data?.order_number || '',
          treezOrderStatus: previewResult.data?.order_status || '',
          treezPaymentStatus: previewResult.data?.payment_status || '',
          isDirectPurchase: false
        };

        // Important: still await the order creation, but no need to save to state
        try {
          const orderId = await dispatch(createOrder(orderData, previewResult));
          addOrder(orderId, orderData);
        } catch (orderError) {
          setError(`Failed to create order: ${orderError.message || 'Unknown error'}`);
          return;
        }
        
        for (const item of cartItems) {
          await dispatch(removeFromCart(user.userId, item.id));
        }

        setMode('history');
        // onPurchaseComplete();
      } else {
        setError("Ticket creation was not successful. Please try again.");
      }
    } catch (error) {
      setError("Failed to preview ticket. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAeropayTestPurchase = async () => {
    if (!selectedPaymentMethod) {
      setError('Please select a payment method');
      return;
    }

    setIsLoading(true);
    setError(null);
    setTransactionStatus(null);
    setTransactionId(null);
    
    try {
      // Calculate the proper totals including taxes using the same logic as OrderDetails
      const calculateTotals = (items) => {
        let subtotal = 0;
        let savings = 0;

        items.forEach(item => {
          const product = latestProducts[item.id];
          const regularPrice = item.price * item.quantity;
          const actualPrice = (product?.pricing?.discounted_price || product?.pricing?.price_sell || item.price) * item.quantity;
          
          subtotal += actualPrice;
          if (item.discountedPrice && item.discountedPrice < item.price) {
            savings += (item.price - item.discountedPrice) * item.quantity;
          }
        });

        const laCannabisRate = 0.10;
        const caExciseRate = 0.15;
        const salesTaxRate = 0.0975;

        const laCannabisCity = subtotal * laCannabisRate;
        const caExcise = subtotal * caExciseRate;
        const salesTax = subtotal * salesTaxRate;
        const total = subtotal + laCannabisCity + caExcise + salesTax;

        return {
          subtotal: parseFloat(subtotal.toFixed(2)),
          laCannabisCity: parseFloat(laCannabisCity.toFixed(2)),
          caExcise: parseFloat(caExcise.toFixed(2)),
          salesTax: parseFloat(salesTax.toFixed(2)),
          savings: parseFloat(savings.toFixed(2)),
          total: parseFloat(total.toFixed(2))
        };
      };

      const totals = calculateTotals(cartItems);
      
      const uuid = uuidv4();

      const result = await dispatch(createAeroPayTransaction(
        cartItems, 
        latestProducts, 
        user.userId,
        true,
        selectedPaymentMethod.bankAccountId,
        uuid,
        totals.total // Pass the full total including taxes
      ));
      
      if (result.success) {
        setTransactionId(result.transactionId);
        setTransactionStatus('processing');
      
      const orderData = {
        userId: user.userId,
        orderStatus: "AWAITING_PROCESSING",
        orderTime: new Date().toISOString(),
        totalAmount: totals.total, // Using full total including taxes
        totals: {
          subtotal: Math.round(totals.subtotal * 100),
          taxes: {
            laCannabisCity: Math.round(totals.laCannabisCity * 100),
            caExcise: Math.round(totals.caExcise * 100),
            salesTax: Math.round(totals.salesTax * 100)
          },
          savings: Math.round(totals.savings * 100),
          total: Math.round(totals.total * 100)
        },
        items: cartItems.map(item => {
          const product = latestProducts[item.id];
          const price = product?.pricing?.discounted_price || product?.pricing?.price_sell || item.price;
          return {
            productId: item.id,
            quantity: item.quantity,
            price: price,
          };
        }),
        aeroPayTransaction: {
            id: uuid,
            status: result.status || 'processing',
            created: result.created || new Date().toISOString()
        },
        paymentStatus: "PROCESSING",
        paymentMethod: "AEROPAY",
        isTestOrder: true,
        store_data: cartItems[0]?.store_data || {},
      };

        // Important: still await the order creation, but no need to save to state
        try {
          const orderId = await dispatch(createOrder(orderData, result.success));
          addOrder(orderId, orderData);
        } catch (orderError) {
          setError(`Failed to create order: ${orderError.message || 'Unknown error'}`);
          return;
        }
        
        for (const item of cartItems) {
          await dispatch(removeFromCart(user.userId, item.id));
        }

        setMode('history');
        // onPurchaseComplete?.();
      } else {
        setTransactionStatus('failed');
        setError(result.error || 'Transaction failed. Please try again.');
      }
    } catch (error) {
      setError(error.message || 'Failed to process transaction');
      setTransactionStatus('failed');
    } finally {
      setIsLoading(false);
    }
  };

  const renderTransactionStatus = (transactionStatus, error, handleRetry) => {
    switch (transactionStatus) {
      case 'completed':
        return (
          <div className="transaction-status success">
            <IonIcon icon={checkmarkCircleOutline} />
            Transaction completed successfully!
          </div>
        );
      case 'failed':
        return (
          <div className="transaction-status error">
            <IonIcon icon={closeCircleOutline} />
            {error}
            <button className="retry-button" onClick={handleRetry}>
              Retry Transaction
            </button>
          </div>
        );
      case 'cancelled':
        return (
          <div className="transaction-status warning">
            <IonIcon icon={alertCircleOutline} />
            {error}
            <button className="retry-button" onClick={handleRetry}>
              Try Again
            </button>
          </div>
        );
      case 'timeout':
        return (
          <div className="transaction-status warning">
            <IonIcon icon={timeOutline} />
            {error}
            <p className="help-text">You can check your order status in your account page.</p>
          </div>
        );
      default:
        return null;
    }
  };

  const renderLoadingState = (isLoading, transactionId) => {
    if (!isLoading) return null;
    
    return (
      <div className="loading-status">
        <IonSpinner name="crescent" />
        {transactionId ? 'Processing your payment...' : 'Initializing transaction...'}
      </div>
    );
  };

  const handleVerifyAccount = (formData) => {
    // For now, just log the form data
    // TODO: Implement the actual verification logic
    handleVerificationSuccess();
  };

  const handleVerificationSuccess = () => {
    setPurchaseStatus(true);
    setShowVerificationForm(false);
  };

  const handleShowVerificationForm = () => {
    setShowVerificationForm(true);
  };

  const renderNavIcons = () => (
    <div className="nav-icons-container">
      <div style={{ width: '40px' }}>
        <IonIcon
          icon={timeOutline}
          className={`nav-icon ${mode === 'history' ? 'active' : ''}`}
          onClick={() => setMode('history')}
        />
      </div>
      <div style={{ width: '40px' }}>
        <IonIcon
          icon={bagOutline}
          className={`nav-icon ${mode === 'stash' ? 'active' : ''}`}
          onClick={() => setMode('stash')}
        />
      </div>
    </div>
  );

  const OrderProgress = ({ status }) => {
    const stages = [
      'VERIFICATION_PENDING',
      'AWAITING_PROCESSING',
      'IN_PROCESS',
      'PACKED_READY',
      'COMPLETED'
    ];

    const getStageIndex = (currentStatus) => {
      switch (currentStatus) {
        case 'VERIFICATION_PENDING':
          return 0;
        case 'AWAITING_PROCESSING':
          return 1;
        case 'IN_PROCESS':
          return 2;
        case 'PACKED_READY':
          return 3;
        case 'COMPLETED':
          return 4;
        case 'CANCELED':
          return -1;
        default:
          return -1;
      }
    };

    const currentIndex = getStageIndex(status);

    const getStatusLabel = (status) => {
      switch (status) {
        case 'VERIFICATION_PENDING':
          return 'Verification Needed';
        case 'AWAITING_PROCESSING':
          return 'Awaiting Confirmation';
        case 'IN_PROCESS':
          return 'Preparing Order';
        case 'PACKED_READY':
          return 'Ready for Pickup';
        case 'OUT_FOR_DELIVERY':
          return 'Out for Delivery';
        case 'COMPLETED':
          return 'Completed';
        case 'CANCELED':
          return 'Canceled';
        default:
          return 'Processing';
      }
    };

    // For canceled orders, show only the status label
    if (status === 'CANCELED') {
      return (
        <div className="order-progress">
          <div className="status-label canceled">
            {getStatusLabel(status)}
          </div>
        </div>
      );
    }

    // For completed orders, show all bars filled
    if (status === 'COMPLETED') {
      return (
        <div className="order-progress">
          <div className="progress-bars">
            {stages.slice(0, -1).map((stage, index) => (
              <React.Fragment key={stage}>
                <div className="progress-step-container">
                  <div className="progress-step completed" />
                </div>
                <div className="progress-bar-container">
                  <div className="progress-bar completed" />
                </div>
              </React.Fragment>
            ))}
            <div className="progress-step-container">
              <div className="progress-step completed" />
            </div>
          </div>
          <div className="status-label completed">
            {getStatusLabel(status)}
          </div>
        </div>
      );
    }

    return (
      <div className="order-progress">
        <div className="progress-bars">
          {stages.slice(0, -1).map((stage, index) => {
            const isCompleted = currentIndex > index;
            const isActive = currentIndex === index;
            return (
              <React.Fragment key={stage}>
                <div className="progress-step-container">
                  <div className={`progress-step ${isCompleted ? 'completed' : ''} ${isActive ? 'active' : ''}`} />
                </div>
                <div className="progress-bar-container">
                  <div className={`progress-bar ${isCompleted ? 'completed' : ''} ${isActive ? 'active' : ''}`} />
                </div>
              </React.Fragment>
            );
          })}
          <div className="progress-step-container">
            <div className={`progress-step ${status === 'COMPLETED' ? 'completed' : ''}`} />
          </div>
        </div>
        <div className={`status-label ${status === 'COMPLETED' ? 'completed' : 'active'}`}>
          {getStatusLabel(status)}
        </div>
      </div>
    );
  };

  const OrderItemWithProduct = ({ item, products }) => {
    const product = products.find(p => p.id === item.productId);
    
    // Get the pricing information and convert from cents to dollars
    const pricePerUnit = (item.pricePerUnit?.amount || item.price) / 100;
    const regularPrice = item.pricePerUnit?.regularAmount ? item.pricePerUnit.regularAmount / 100 : pricePerUnit;
    const hasSavings = regularPrice > pricePerUnit;
    
    if (!product) {
      return <div className="order-item-error">Product not found</div>;
    }

    return (
      <div className="order-stash-item-wrapper">
        <StashItem
          key={item.productId}
          name={product.name}
          brand={product.brand}
          category={product.category}
          classification={product.classification || "Unknown Classification"}
          price={regularPrice}
          discountedPrice={hasSavings ? pricePerUnit : undefined}
          imageUrl={product.imageUrl}
          quantity={item.quantity}
          readOnly={true}
        />
      </div>
    );
  };

  const OrderBox = ({ order, products }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [storeData, setStoreData] = useState(null);

    useEffect(() => {
      const fetchStoreData = async () => {
        // Check all possible locations for the store ID
        const storeId = order.store_data?.store_id || 
                        order.ticketData?.store_id || 
                        order.storeId || 
                        "AkNtGp9779aEIdDRbVmA"; // Fallback to Swish store ID
        
        if (storeId) {
          try {
            const storeDoc = await getDoc(doc(db, 'stores', storeId));
            if (storeDoc.exists()) {
              const data = storeDoc.data();
              setStoreData(data);
            } else {
            }
          } catch (error) {
            console.error('Error fetching store data:', error);
            setError('Error fetching store data');
          }
        }
      };
      
      fetchStoreData();
    }, [order]);
    
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    const items = order.items ? Object.values(order.items) : [];
    const isCompletedOrCanceled = order.orderStatus === 'COMPLETED' || order.orderStatus === 'CANCELED';
    
    // Get total amount from either root level or ticketData
    const totalAmount = order.totals?.total || (order.ticketData && order.ticketData.totalAmount) || 0;
    
    // Get date from either orderTime or scheduled_date
    const orderDate = order.orderTime || (order.ticketData && order.ticketData.scheduled_date);
    
    return (
      <div className={`order-box ${isCompletedOrCanceled ? 'completed-or-canceled' : ''}`}>
        <div className="order-header">
          <div className="header-content">
            <div className="store-info-ob">
              {storeData?.imageUrl && (
                <img 
                  src={storeData.imageUrl} 
                  alt={`${storeData.name} logo`}
                  className="store-logo"
                />
              )}
              <div className="store-info-column">
                <span className="store-name-ob">{storeData?.name || 'The Woods'}</span>
                <div className="order-date">{formatDate(orderDate)}</div>
              </div>
            </div>
            
            <div className="order-total">${((order.totals?.total || 0) / 100).toFixed(2)}</div>
          </div>
        </div>
        <div className="order-summary">
          {isCompletedOrCanceled ? (
            <div className={`status-label ${order.orderStatus.toLowerCase()}`}>
              {order.orderStatus === 'COMPLETED' ? 'Completed' : 'Canceled'}
            </div>
          ) : (
            <OrderProgress status={order.orderStatus} />
          )}
        </div>
        <div className="expand-button-container">
          <button className={`expand-button ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpand}>
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
              <path d="M1 1L6 6L11 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </button>
        </div>
        {isExpanded && (
          <div className="order-details">
            {order.treezOrderNumber && <div className="order-id">Order #{order.treezOrderNumber}</div>}
            <div className="order-items">
              {items.map((item, index) => (
                <OrderItemWithProduct 
                  key={`${item.productId}-${index}`} 
                  item={item} 
                  products={products}
                />
              ))}
            </div>
            <section className="stashmodal-inner1">
              <div className="box-parent">
                <OrderDetails order={order} isHistorical={true} />
              </div>
            </section>
          </div>
        )}
      </div>
    );
  };

  const formatDate = (date) => {
    if (!date) return '';
    
    let dateObj;
    
    // Case 1: Firestore Timestamp object
    if (date && (date._seconds || date.seconds)) {
      dateObj = new Date((date._seconds || date.seconds) * 1000);
    }
    // Case 2: Raw timestamp format "Timestamp(seconds=X, nanoseconds=Y)"
    else if (typeof date === 'string' && date.startsWith('Timestamp')) {
      const seconds = date.match(/seconds=(\d+)/)?.[1];
      if (seconds) {
        dateObj = new Date(parseInt(seconds) * 1000);
      } else {
        dateObj = new Date(date);
      }
    }
    // Case 3: Regular date string
    else if (typeof date === 'string') {
      dateObj = new Date(date);
    }
    // Case 4: Already a Date object
    else if (date instanceof Date) {
      dateObj = date;
    }
    // Case 5: Fallback
    else {
      setError('Unknown date format');
      return '';
    }
    
    // Check if date is valid
    if (isNaN(dateObj.getTime())) {
      setError('Invalid date');
      return '';
    }

    // Format as M/D/YYYY without leading zeros
    const month = dateObj.getMonth() + 1; // getMonth() is 0-based
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const calculateOrderTotal = (items) => {
    if (!items || !Array.isArray(items)) return 0;
    return items.reduce((total, item) => {
      if (!item) return total;
      const itemPrice = item.discountedPrice || item.price || 0;
      const quantity = item.quantity || 0;
      return total + (itemPrice * quantity);
    }, 0);
  };

  const handleBankSelect = useCallback((bank) => {
    setSelectedPaymentMethod({
      bankAccountId: bank.bankAccountId,
      bankName: bank.bankName,
      accountLast4: bank.accountLast4
    });
  }, []);

  const renderStashContent = () => {
    const total = orderTotal;
    return (
      <>
        <ModalHeader title="Your Stash" onClose={onClose} />
        {!showVerificationForm ? (
          <>
            <div className="stashmodal-child" />
            {renderNavIcons()}
            {cartItems.length === 0 ? (
              <div className="empty-cart-message">
                Your stash is empty. Add some items to make a purchase!
              </div>
            ) : (
              cartItems.map((item) => {
                const latestProduct = latestProducts[item.id];
                const product = latestProduct || products.find(p => p.id === item.id);
                
                return (
                  <StashItem
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    brand={item.brand}
                    category={product?.category_type || item.category}
                    classification={item.classification}
                    price={item.price}
                    discountedPrice={item.discountedPrice}
                    quantity={item.quantity}
                    imageUrl={item.imageUrl || product?.imageUrl}
                    sellableQuantity={
                      isLoadingProducts 
                        ? Infinity 
                        : (
                          // If we have store_id on the cart item, get quantity from that store
                          item.store_id && product?.stores && product.stores[item.store_id]?.sellable_quantity
                          // If we have store-specific quantity directly on the cart item
                          || item.sellableQuantity
                          // Fall back to latest product data
                          || latestProduct?.sellableQuantity 
                          // Fall back to general product data
                          || product?.sellableQuantity 
                          // Default to 0
                          || 0
                        )
                    }
                    onRemove={() => handleRemoveFromCart(item.id)}
                  />
                );
              })
            )}
            <div className="stashmodal-inner" />
              <section className="stashmodal-inner1">
                <div className="box-parent">
                  <OrderDetails 
                    cartItems={cartItems} 
                    products={products} 
                    storeId={"AkNtGp9779aEIdDRbVmA"}
                    onTotalCalculated={(totals) => setOrderTotal(totals.total)}
                  />
                  <div className="location-payment-wrapper">
                    <PurchasePaymentMethod 
                      user={user}
                      onBankSelect={handleBankSelect}
                    />
                    <LocationFull />
                  </div>
                </div>
              </section>
            <div className="stashmodal-button-container">
              <div className="help-notice">
                <IonIcon icon={helpCircleOutline} /> Need help? Email hello@stashpandas.com
              </div>
              <div 
                className={`purchase-wrapper ${cartItems.length === 0 ? 'disabled' : ''}`} 
                onClick={() => {
                  if (cartItems.length === 0) return;
                  return user.purchaseStatus ? handlePurchase() : setShowVerificationForm(true);
                }}
              >
                <div className="purchase">
                  {isLoading ? 'Processing...' : 
                   cartItems.length === 0 ? 'Add items to purchase' :
                   user.purchaseStatus ? `Complete Purchase • $${orderTotal}` : 'Verify Account to Purchase'}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="verification-form-wrapper">
            <VerificationForm onSubmit={handleVerifyAccount} onVerificationSuccess={handleVerificationSuccess} />
          </div>
        )}
      </>
    );
  };

  const renderOrderContent = () => {
    if (showVerificationForm) {
      return <VerificationForm onSubmit={handleVerifyAccount} onVerificationSuccess={handleVerificationSuccess} />;
    }

    return (
      <>
        <section className="frame-parent9">
          <div className="frame-parent10">
            <OrderDetails 
              cartItems={cartItems} 
              products={products} 
              storeId={"AkNtGp9779aEIdDRbVmA"}
              onTotalCalculated={(totals) => setOrderTotal(totals.total)}
            />
            <LocationFull />
          </div>
        </section>
        <div className="stashmodal-inner2">
        <div className="payment-notice">
          <IonIcon icon={cardOutline} /> Payment made at store
        </div>
        <PurchasePaymentMethod 
          user={user}
          onBankSelect={handleBankSelect}
        />
          {/* {renderPaymentMethods(user, selectedPaymentMethod, setSelectedPaymentMethod, setShowAddPayment)} */}
          <div className="purchase-wrapper" onClick={() => user.purchaseStatus ? handlePurchase() : setShowVerificationForm(true)}>
            <div className="purchase">
              {user.purchaseStatus ? `Complete Purchase • $${orderTotal}` : 'Verify Account to Purchase'}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderHistoryContent = () => {
    // Show loading when switching modes or when orders are loading
    if (!orders || ordersLoading || isHistoryLoading) {
      return (
        <>
          <ModalHeader title="Order History" onClose={onClose} />
          <div className="stashmodal-child" />
          {renderNavIcons()}
          <div className="order-history-container">
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          </div>
        </>
      );
    }

    if (ordersError) {
      return (
        <>
          <ModalHeader title="Order History" onClose={onClose} />
          <div className="order-history-container">
            <div className="error-message">Error loading orders: {ordersError.message}</div>
          </div>
        </>
      );
    }

    if (!orders || orders.length === 0) {
      return (
        <>
          <ModalHeader title="Order History" onClose={onClose} />
          <div className="stashmodal-child" />
          {renderNavIcons()}
          <div className="order-history-container">
            <div className="no-orders-message">No orders found</div>
          </div>
        </>
      );
    }

    const sortedOrders = [...orders].sort((a, b) => {
      const getTimestamp = (order) => {
        if (order.orderTime?.seconds) {
          return order.orderTime.seconds * 1000;
        } else if (order.orderTime?._seconds) {
          return order.orderTime._seconds * 1000;
        } else if (order.ticketData?.scheduled_date) {
          return new Date(order.ticketData.scheduled_date).getTime();
        }
        return 0;
      };
      
      return getTimestamp(b) - getTimestamp(a);
    });

    return (
      <>
        <ModalHeader title="Order History" onClose={onClose} />
        <div className="stashmodal-child" />
        {renderNavIcons()}
        <div className="order-history-container">
          {sortedOrders.map((order) => {
            if (!order) return null;
            
            const orderDate = order.orderTime ? new Date(order.orderTime) : new Date();
            const totalAmount = order.totals?.total || 0;
            
            // Use the real-time status if available, otherwise fall back to the order's status
            const currentStatus = order.orderStatus;
            
            return (
              <OrderBox key={order.id || order.treezTicketId || Math.random()} order={order} products={products} />
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="modal-overlay" onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}>
      <div className="stashmodal">
        {mode === 'stash' ? renderStashContent() : 
         mode === 'order' ? renderOrderContent() : 
         renderHistoryContent()}
        {error && !transactionStatus && (
          <div className="error-message">
            <IonIcon icon={alertCircleOutline} />
            {error}
          </div>
        )}
        {renderTransactionStatus(transactionStatus, error, handlePreviewPurchase)}
        {renderLoadingState(isLoading, transactionId)}
      </div>
    </div>
  );
});

export default StashModal;
