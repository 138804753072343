import React from 'react';
import './StoreChangeConfirmation.css';

const StoreChangeConfirmation = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  productStoreName, 
  currentStoreName,
  productStoreId,
  currentStoreId
}) => {
  if (!isOpen) return null;

  return (
    <div className="store-change-confirmation-overlay">
      <div className="store-change-confirmation-content">
        <h2>Change Stores?</h2>
        <p>
          This product is from <strong>{productStoreName || 'a different store'}</strong>, 
          but your cart already has items from <strong>{currentStoreName || 'another store'}</strong>.
        </p>
        <p>
          Would you like to clear your current cart and start a new one with this product instead?
        </p>
        <div className="store-change-confirmation-buttons">
          <button 
            className="store-change-cancel-button" 
            onClick={onClose}
          >
            Cancel
          </button>
          <button 
            className="store-change-confirm-button" 
            onClick={onConfirm}
          >
            Clear Cart & Add Item
          </button>
        </div>
      </div>
    </div>
  );
};

export default StoreChangeConfirmation;
