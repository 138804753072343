import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { addProductToCart, updateCartItemQuantity, setBuyNowProduct, clearBuyNow } from "../../redux/actions/orderActions";

import ProductImageBig from "./ProductImageBig";
import LocationFull from "./LocationFull";
import ItemDescription from "./ItemDescription";
import ItemInfo from "./ItemInfo";
import QuantityCounter from "./QuantityCounter";
import BuyNowButton from "./BuyNowButton";
import AddToStashButton from "./AddToStashButton";
import BuyNowModal from "./BuyNowModal";
import PortalPopup from "./PortalPopup";
import StoreChangeConfirmation from "./StoreChangeConfirmation";
import "./ItemDetailsModal.css";

const formatPrice = (price) => {
  if (typeof price !== 'number') return '$0.00';
  return `$${price.toFixed(2)}`;
};

const ItemDetailsModal = ({ product, onClose, currentPrice, originalPrice }) => {
  const [user, setUser] = useState(null);
  const [addedToStash, setAddedToStash] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [isBuyNowModalOpen, setBuyNowModalOpen] = useState(false);
  const [storeChangeConfirmation, setStoreChangeConfirmation] = useState({
    isOpen: false,
    productStoreId: null,
    currentStoreId: null,
    confirmCallback: null
  });
  
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.order.cart || []);
  const storeData = useSelector(state => state.store.cachedStores || {});
  const buyNowState = useSelector(state => state.order.buyNow || {});

  // Set initial state based on cart
  useEffect(() => {
    if (product?.id) {
      const cartItem = cartItems.find(item => item.id === product.id);
      if (cartItem) {
        setAddedToStash(true);
        setQuantity(cartItem.quantity);
      } else {
        setAddedToStash(false);
        setQuantity(0);
      }
    }
  }, [cartItems, product?.id]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleQuantityChange = (newQuantity) => {
    dispatch(updateCartItemQuantity(null, product.id, newQuantity));
    setQuantity(newQuantity);
  };

  const handleAddToStash = () => {
    const result = dispatch(addProductToCart(product));
    
    // Check if we need to show store change confirmation
    if (result && result.needsConfirmation) {
      // Open confirmation modal
      setStoreChangeConfirmation({
        isOpen: true,
        productStoreId: result.validation.productStoreId,
        currentStoreId: result.validation.currentStoreId,
        confirmCallback: result.confirmCallback
      });
    } else {
      setAddedToStash(true);
    }
  };

  const openBuyNowModal = () => {
    // Create an enhanced product with necessary data
    const enhancedProduct = {
      ...product,
      // Ensure proper pricing structure is available
      price: currentPrice || product.price || (product.pricing?.price_sell) || 0,
      discountedPrice: currentPrice || product.discountedPrice || (product.pricing?.discounted_price) || 0,
      // Make sure store data is included
      store_data: product.store_data || {},
      // Ensure pricing object exists for backward compatibility
      pricing: {
        ...(product.pricing || {}),
        price_sell: product.price || (product.pricing?.price_sell) || 0,
        discounted_price: currentPrice || product.discountedPrice || (product.pricing?.discounted_price) || 0
      }
    };
    
    // Add the enhanced product to the Buy Now Redux state with a quantity of 1
    dispatch(setBuyNowProduct(enhancedProduct, 1));
    setBuyNowModalOpen(true);
  };
  
  const closeBuyNowModal = () => {
    // Clear the Buy Now state when closing the modal
    dispatch(clearBuyNow());
    setBuyNowModalOpen(false);
  };

  if (!product?.id) return null;

  const effects = product?.effects || [];
  const activities = product?.activities || [];
  const doses = product?.doses || [];
  const weights = product?.weights || [];
  const labResults = product?.lab_results || {};

  return (
    <div className="item-details-modal">
      <section className="container-component-wrapper">
        <div className="container-component">
          <ItemInfo product={product} />
          <div className="product-purchase-box">
            <LocationFull />
            <div className="centered-content">
              <div className="frame-div">
                <div className="minus-circle-icon-wrapper">
                  <div className="minus-circle-icon">
                    <div className="add-circle-icon">{formatPrice(currentPrice)}</div>
                    <div className="wrapper">
                      <div className="div">{formatPrice(originalPrice)}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="action-buttons">
                {user ? (
                  <>
                    <BuyNowButton buttonText="Buy Now" action={openBuyNowModal} />
                    {addedToStash ? (
                      <QuantityCounter 
                        inventoryCount={product.sellableQuantity} 
                        onQuantityChange={handleQuantityChange}
                        initialQuantity={quantity}
                        productId={product.id}
                      />
                    ) : (
                      <AddToStashButton 
                        title="Add to Stash" 
                        action={handleAddToStash}
                      />
                    )}
                  </>
                ) : (
                  <AddToStashButton 
                    title="Login to Buy" 
                    action={() => window.location.href = '/login'}
                  />
                )}    
              </div>
            </div>
          </div>
        </div>
      </section>
      <img
        className="vuesaxlinearclose-circle-icon"
        loading="lazy"
        alt=""
        src="/vuesaxlinearclosecircle.svg"
        onClick={onClose}
      />
      
      {/* Add the store change confirmation modal */}
      <StoreChangeConfirmation
        isOpen={storeChangeConfirmation.isOpen}
        onClose={() => setStoreChangeConfirmation({ ...storeChangeConfirmation, isOpen: false })}
        onConfirm={() => {
          if (storeChangeConfirmation.confirmCallback) {
            storeChangeConfirmation.confirmCallback();
          }
          setStoreChangeConfirmation({ ...storeChangeConfirmation, isOpen: false });
          setAddedToStash(true);
        }}
        productStoreName={storeData[storeChangeConfirmation.productStoreId]?.name || 'a new store'}
        currentStoreName={storeData[storeChangeConfirmation.currentStoreId]?.name || 'your current store'}
        productStoreId={storeChangeConfirmation.productStoreId}
        currentStoreId={storeChangeConfirmation.currentStoreId}
      />
      
      {isBuyNowModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeBuyNowModal}
        >
          <BuyNowModal 
            product={buyNowState?.product || product}
            currentPrice={currentPrice}
            originalPrice={originalPrice}
            onClose={closeBuyNowModal}
            onOpenItemDetails={() => {}}
            initialQuantity={quantity}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default ItemDetailsModal;