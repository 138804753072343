/**
 * Validates if a product can be added to the cart based on store compatibility
 * @param {Object} product - The product being added to cart
 * @param {Array} currentCart - The current cart items
 * @param {Object} storeState - The current store state from Redux
 * @returns {Object} Validation result with isValid and message
 */
export const validateStoreCompatibility = (product, currentCart, storeState) => {
  // No items in cart yet, always valid
  if (!currentCart || currentCart.length === 0) {
    return {
      isValid: true,
      productStoreId: product.store_id || (product.store_data?.store_id),
      currentStoreId: null,
      message: "Cart is empty, product can be added"
    };
  }

  // Get store ID from the product
  const productStoreId = product.store_id || (product.store_data?.store_id);
  
  // Get current cart store ID from first item
  const currentStoreId = currentCart[0].store_id || 
                        (currentCart[0].store_data?.store_id);
  
  // If product has no store ID, allow it (edge case)
  if (!productStoreId) {
    return {
      isValid: true,
      productStoreId: null,
      currentStoreId,
      message: "Product has no store ID"
    };
  }
  
  // Check if store IDs match
  const isValid = productStoreId === currentStoreId;
  
  return {
    isValid,
    productStoreId,
    currentStoreId,
    message: isValid ? 
      "Product store matches cart store" : 
      "Product is from a different store than items in your cart"
  };
};

/**
 * Gets store information from the cart
 * @param {Array} cart - Current cart items
 * @returns {Object|null} Store information or null if cart is empty
 */
export const getCartStoreInfo = (cart) => {
  if (!cart || cart.length === 0) {
    return null;
  }
  
  // Get store ID from the first cart item
  const firstItem = cart[0];
  const storeId = firstItem.store_id || (firstItem.store_data?.store_id);
  
  if (!storeId) {
    return null;
  }
  
  return {
    storeId,
    itemCount: cart.length,
    totalQuantity: cart.reduce((sum, item) => sum + (item.quantity || 1), 0)
  };
};
