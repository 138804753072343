import React, { useState, useEffect, useRef, useCallback } from "react";
import BaseCard from "./BaseCard";
import StoreCard from "./StoreCard";
import SeeAllButton from "./SeeAllButton";
import "./ItemGallery.css";

const ItemGallery = ({
  products,
  stores,
  onSeeAllClick,
  onStoreSelect,
  seeAll = false,
  onLoginClick,
  onPurchaseComplete,
  onLoadMore,
  hasMore,
  isLoading: externalLoading,
  userLocation,
}) => {
  const [isImagesLoading, setIsImagesLoading] = useState(true);
  const loadedImages = useRef(new Set());
  const observer = useRef();
  
  // Efficient image loading for products
  useEffect(() => {
    if (!products?.length) return;
    
    // Only load images that haven't been loaded before
    const unloadedProducts = products.filter(product => {
      const imageUrl = product.sp?.imageUrl || product.imageUrl;
      return imageUrl && !loadedImages.current.has(imageUrl);
    });
    
    if (unloadedProducts.length === 0) {
      setIsImagesLoading(false);
      return;
    }
    
    setIsImagesLoading(true);
    const imagePromises = unloadedProducts.map(product => {
      const imageUrl = product.sp?.imageUrl || product.imageUrl;
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          loadedImages.current.add(imageUrl);
          resolve();
        };
        img.onerror = () => {
          loadedImages.current.add(imageUrl);
          resolve();
        };
        img.src = imageUrl;
      });
    });

    Promise.all(imagePromises).then(() => {
      setIsImagesLoading(false);
    });
  }, [products]);

  // Single intersection observer for both products and stores
  const lastItemRef = useCallback(node => {
    if (externalLoading) return;
    if (observer.current) observer.current.disconnect();
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        onLoadMore?.();
      }
    });
    
    if (node) observer.current.observe(node);
  }, [externalLoading, hasMore, onLoadMore]);

  const renderProducts = () => {
    if (!products?.length) return null;
    
    return products.map((product, index) => {
      const productId = product.id || product.product_id;
      const spFields = product.sp || {};
      const storeFields = product.product_configurable_fields || {};
      
      // Get field values with SP priority
      const name = spFields.name || storeFields.name || product.name;
      const brand = spFields.brand || storeFields.brand || product.brand;
      const imageUrl = spFields.imageUrl || product.imageUrl;
      const description = spFields.description || product.description;
      const type = spFields.type || product.type;
      const effects = spFields.effects || product.effects || [];

      return (
        <div
          key={productId}
          ref={index === products.length - 1 ? lastItemRef : null}
          className="product-card-container"
        >
          <BaseCard
            product={product}
            cardType="product"
            id={productId}
            name={name}
            brand={brand}
            price={product.pricing?.price_sell}
            discountedPrice={product.pricing?.discounted_price}
            category_type={product.category_type}
            classification={product.classification}
            lab_results={product.lab_results}
            sellableQuantity={product.sellable_quantity}
            stockStatus={product.stockStatus}
            imageUrl={imageUrl}
            description={description}
            type={type}
            effects={effects}
            cartItemId={product.cartItemId}
            isLoading={externalLoading}
            onLoginClick={onLoginClick}
            onPurchaseComplete={onPurchaseComplete}
            bestPriceStoreId={product.bestPriceStoreId}
            productStores={product.stores || {}}
            store={product.store}
            initialSelectedStoreId={product.selectedStoreId || product.bestPriceStoreId}
            userLocation={userLocation}
            onStoreSelect={onStoreSelect}
          />
        </div>
      );
    });
  };

  const renderStores = () => {
    return stores?.map((store, index) => {
      return (
        <div
          key={store.id}
          ref={index === stores.length - 1 ? lastItemRef : null}
          className="store-card-container"
        >
          <BaseCard
            cardType="store"
            {...store}
            userLocation={userLocation}
            distance={store.distance}
            isLoading={externalLoading}
            onStoreSelect={() => onStoreSelect(store, true)}
          />
        </div>
      );
    });
  };

  return (
    <div className={` ${seeAll ? 'see-all-container' : 'frame-wrapper6'}`}>
      <div className={` ${seeAll ? '' : 'frame-parent15'}`}>
        <div className={` ${isImagesLoading || externalLoading ? 'loading' : ''} ${seeAll ? '' : 'product-gallery'}`}>
          <div className={` ${seeAll ? 'see-all-gallery' : 'product-grid'}`}>
            {products && renderProducts()}
            {!seeAll && stores && renderStores()}
            {externalLoading && (
              <div className="loading-indicator">
                Loading more {products && stores ? 'products and stores' : products ? 'products' : 'stores'}...
              </div>
            )}
          </div>
        </div>
      </div>
      {!seeAll && onSeeAllClick && (
        <div className="centered-button">
          <SeeAllButton onClick={onSeeAllClick} />
        </div>
      )}
    </div>
  );
};

export default ItemGallery;
