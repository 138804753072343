import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ItemGallery from './ItemGallery';
import BuyNowModal from './BuyNowModal';

const TestAeroPay = () => {
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const user = useSelector(state => state.user);

  // Test store data matching our StoreCard props
  const testStore = {
    id: 'test-store-1',
    name: 'Test Cannabis Store',
    address: '123 Test St, Test City, CA 90210',
    imageUrl: 'https://via.placeholder.com/300',
    hours: {
      monday: { isOpen: true, open: '09:00', close: '21:00' },
      tuesday: { isOpen: true, open: '09:00', close: '21:00' },
      wednesday: { isOpen: true, open: '09:00', close: '21:00' },
      thursday: { isOpen: true, open: '09:00', close: '21:00' },
      friday: { isOpen: true, open: '09:00', close: '21:00' },
      saturday: { isOpen: true, open: '09:00', close: '21:00' },
      sunday: { isOpen: true, open: '09:00', close: '21:00' }
    },
    timezone: 'America/Los_Angeles',
    settings: {
      delivery: {
        enabled: true,
        minimumOrder: 50
      },
      pickup: {
        enabled: true
      }
    },
    metrics: {
      rating: 5.0,
      reviewCount: 100
    },
    location: {
      latitude: 34.0522,
      longitude: -118.2437
    }
  };

  // Test product data matching BaseCard props
  const testProduct = {
    id: 'test-product-1',
    name: 'Test Cannabis Product',
    pricing: {
      price_sell: 1.00,
      discounted_price: 1.00
    },
    category_type: 'FLOWER',
    classification: 'hybrid',
    brand: 'Test Brand',
    sellable_quantity: 100,
    imageUrl: 'https://via.placeholder.com/300',
    description: 'A test product for AeroPay integration testing. This product is used to verify payment processing.',
    type: 'flower',
    effects: ['relaxed', 'happy'],
    store: testStore
  };

  const handleBuyNow = (product) => {
    setSelectedProduct(product);
    setShowBuyModal(true);
  };

  const handleCloseModal = () => {
    setShowBuyModal(false);
    setSelectedProduct(null);
  };

  const handlePurchaseComplete = () => {
    console.log('Purchase completed!');
    setShowBuyModal(false);
    setSelectedProduct(null);
  };

  return (
    <div className="test-aeropay-container" style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h2 style={{ marginBottom: '20px' }}>AeroPay Test Store</h2>
      
      <ItemGallery
        stores={[testStore]}
        products={[testProduct]}
        onPurchaseComplete={handlePurchaseComplete}
        onLoginClick={() => {}} // Add your login handler if needed
        isLoading={false}
        hasMore={false}
      />

      {showBuyModal && selectedProduct && (
        <BuyNowModal
          onClose={handleCloseModal}
          product={selectedProduct}
          currentPrice={selectedProduct.pricing.price_sell}
          originalPrice={selectedProduct.pricing.price_sell}
          onPurchaseComplete={handlePurchaseComplete}
        />
      )}
    </div>
  );
};

export default TestAeroPay;