import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { collection, query, where, orderBy, startAfter, getDocs, doc, getDoc } from 'firebase/firestore';
import { limit as firestoreLimit } from 'firebase/firestore';
import { db } from '../../firebase';

// Cache keys
const STORE_KEYS = {
  all: ['stores'],
  active: () => [...STORE_KEYS.all, 'active'],
  byLocation: (lat, lng) => [...STORE_KEYS.all, 'location', lat, lng],
};

/**
 * Fetch active stores with optional location filtering
 * @param {Object} options
 * @param {number} options.page - Page number (1-based)
 * @param {number} options.limit - Items per page
 * @param {number} [options.latitude] - Optional latitude for location-based filtering
 * @param {number} [options.longitude] - Optional longitude for location-based filtering
 * @param {number} [options.radius] - Search radius in miles (default: 50)
 * @returns {Promise} Promise that resolves to paginated store data
 */
const fetchActiveStores = async ({ page = 1, limit = 8, latitude, longitude, radius }) => {
  const getActiveStores = httpsCallable(functions, 'getActiveStores');
  // For single store case, we'll always return it but include distance info if location is provided
  const params = {
    page,
    limit,
    ignoreRadius: true, // Always return the store regardless of distance
    // Temporarily disabled location parameters
    // ...(latitude && longitude ? { latitude, longitude } : {})
  };
  const response = await getActiveStores(params);
  return response.data;
};

/**
 * Fetch stores using direct Firestore queries with optional test stores
 * @param {Object} options
 * @param {number} options.page - Page number (1-based)
 * @param {number} options.limit - Items per page
 * @param {boolean} [options.includeTest=false] - Whether to include test stores
 * @param {string} [options.lastDocId] - ID of the last document for pagination
 * @returns {Promise} Promise that resolves to paginated store data in the same format as fetchActiveStores
 */
export const fetchStoresDirect = async ({ page = 1, limit = 8, includeTest = false, lastDocId = null }) => {
  try {
    
    const storesRef = collection(db, 'stores');
    
    // Create simple query to get all stores
    let storesQuery = query(storesRef);
    
    // Add pagination
    if (lastDocId) {
      const lastDocRef = doc(db, 'stores', lastDocId);
      const lastDocSnap = await getDoc(lastDocRef);
      
      if (lastDocSnap.exists()) {
        storesQuery = query(storesQuery, startAfter(lastDocSnap));
      } else {
        console.warn(`Last document with ID ${lastDocId} not found for pagination`);
      }
    }
    
    // Apply limit - renamed to firestoreLimit to avoid naming conflict
    storesQuery = query(storesQuery, firestoreLimit(limit));
    
    // Execute query
    const snapshot = await getDocs(storesQuery);
    
    // Process results
    let stores = [];
    snapshot.forEach(doc => {
      const data = doc.data();

      
      // Filter stores based on criteria
      if (data.active === true || (includeTest && data.status === 'test')) {
        stores.push({
          id: doc.id,
          ...data
        });
      } else {
      }
    });
    
    
    // Calculate if there are more results (simplified approach)
    const hasMore = stores.length === limit;
    const lastId = stores.length > 0 ? stores[stores.length - 1].id : null;
    
    // Return in the same format as fetchActiveStores
    return {
      stores,
      pagination: {
        currentPage: page,
        totalPages: hasMore ? page + 1 : page, // Estimate
        hasMore,
        lastId
      }
    };
  } catch (error) {
    console.error('Error fetching stores directly:', error);
    throw error;
  }
};

/**
 * React Query hook to fetch active stores
 * @param {Object} options - Query options
 * @returns {Object} Query result object
 */
export const useActiveStores = (options = {}) => {
  // Temporarily disabled location parameters
  // const { latitude, longitude, radius, limit = 8 } = options;
  const { limit = 8 } = options;
  
  return useQuery({
    // Temporarily disabled location-based query key
    // queryKey: latitude && longitude 
    //   ? [...STORE_KEYS.byLocation(latitude, longitude), radius]
    //   : STORE_KEYS.active(),
    queryKey: STORE_KEYS.active(),
    // queryFn: () => fetchActiveStores({ page: 1, limit, latitude, longitude, radius }),
    queryFn: () => fetchActiveStores({ page: 1, limit }),
    staleTime: 5 * 60 * 1000, // Consider data stale after 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
  });
};

/**
 * React Query hook for infinite scrolling of stores
 * @param {Object} options - Query options
 * @returns {Object} Infinite query result object
 */
export const useInfiniteStores = (options = {}) => {
  // Temporarily disabled location parameters
  // const { latitude, longitude, radius, limit = 8 } = options;
  // const locationKey = latitude && longitude ? `${latitude},${longitude}` : 'no-location';
  const { limit = 8 } = options;

  return useInfiniteQuery({
    // Temporarily disabled location-based query key
    // queryKey: [...STORE_KEYS.all, 'infinite', locationKey, radius],
    queryKey: [...STORE_KEYS.all, 'infinite'],
    queryFn: ({ pageParam = 1 }) => 
      fetchActiveStores({ 
        page: pageParam, 
        limit,
        // Temporarily disabled location parameters
        // latitude, 
        // longitude, 
        // radius 
      }),
    getNextPageParam: (lastPage) => 
      lastPage.pagination.hasMore ? lastPage.pagination.currentPage + 1 : undefined,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

/**
 * React Query hook to fetch active stores using direct Firestore queries
 * @param {Object} options - Query options
 * @returns {Object} Query result object
 */
export const useActiveStoresDirect = (options = {}) => {
  const { limit = 8, includeTest = false } = options;
  
  return useQuery({
    queryKey: [...STORE_KEYS.active(), includeTest ? 'withTest' : 'activeOnly'],
    queryFn: () => fetchStoresDirect({ page: 1, limit, includeTest }),
    staleTime: 5 * 60 * 1000, // Consider data stale after 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
  });
};

/**
 * React Query hook for infinite scrolling of stores using direct Firestore queries
 * @param {Object} options - Query options
 * @returns {Object} Infinite query result object
 */
export const useInfiniteStoresDirect = (options = {}) => {
  const { limit = 8, includeTest = false } = options;

  return useInfiniteQuery({
    queryKey: [...STORE_KEYS.all, 'infiniteDirect', includeTest ? 'withTest' : 'activeOnly'],
    queryFn: ({ pageParam }) => {
      // pageParam will either be the lastId (string) or undefined for first page
      return fetchStoresDirect({ 
        page: pageParam ? 2 : 1, // Simple page tracking
        limit,
        includeTest, 
        lastDocId: pageParam 
      });
    },
    getNextPageParam: (lastPage) => 
      lastPage.pagination.hasMore ? lastPage.pagination.lastId : undefined,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

/**
 * Finds the store with the lowest price for a given product
 * @param {string} productId - ID of the product to check
 * @param {Object} cachedStores - Object containing cached store data
 * @returns {Promise<Object>} Object containing the store_id, price, and whether this is the best price
 */
export const findBestPriceStore = async (productId) => {
  try {
    // Get all stores that have this product
    const productDoc = doc(db, 'products', productId);
    const productSnapshot = await getDoc(productDoc);
    
    if (!productSnapshot.exists()) {
      return { store_id: null, price: null, isBestPrice: false };
    }
    
    const productData = productSnapshot.data();
    
    // If no stores field or it's empty
    if (!productData.stores || Object.keys(productData.stores).length === 0) {
      return { store_id: null, price: null, isBestPrice: false };
    }
    
    let bestStore = null;
    let bestPrice = Number.MAX_VALUE;
    
    // Loop through all stores for this product
    for (const [storeId, storeData] of Object.entries(productData.stores)) {
      // Check if pricing exists and has a valid price
      if (storeData.pricing && 
          storeData.pricing.discounted_price !== undefined && 
          storeData.pricing.discounted_price !== null) {
        
        const currentPrice = storeData.pricing.discounted_price;
        
        // Check if this store has inventory
        const hasSellableQuantity = storeData.sellable_quantity && storeData.sellable_quantity > 0;
        
        // Only consider stores with inventory
        if (hasSellableQuantity && currentPrice < bestPrice) {
          bestPrice = currentPrice;
          bestStore = storeId;
        }
      }
    }
    
    return { 
      store_id: bestStore, 
      price: bestPrice !== Number.MAX_VALUE ? bestPrice : null,
      isBestPrice: true
    };
  } catch (error) {
    console.error('Error finding best price store:', error);
    return { store_id: null, price: null, isBestPrice: false };
  }
};

/**
 * Format store hours for display
 * @param {Object} hours - Store hours object
 * @param {string} timezone - Store timezone
 * @returns {string} Formatted hours string
 */
export const formatStoreHours = (hours, timezone) => {
  if (!hours) return 'Hours not available';
  
  const now = new Date();
  const zonedDate = new Date(now.toLocaleString('en-US', { timeZone: timezone }));
  const dayOfWeek = zonedDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
  
  const todayHours = hours[dayOfWeek];
  if (!todayHours?.open || !todayHours?.close) return 'Hours not available';
  
  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));
    return date.toLocaleString('en-US', { 
      hour: 'numeric', 
      minute: '2-digit', 
      hour12: true 
    });
  };
  
  return `Today: ${formatTime(todayHours.open)} - ${formatTime(todayHours.close)}`;
};

/**
 * Calculate if store is currently open
 * @param {Object} hours - Store hours object
 * @param {string} timezone - Store timezone
 * @returns {boolean} Whether store is currently open
 */
export const isStoreOpen = (hours, timezone) => {
  if (!hours) return false;
  
  const now = new Date();
  const zonedDate = new Date(now.toLocaleString('en-US', { timeZone: timezone }));
  const dayOfWeek = zonedDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
  
  const todayHours = hours[dayOfWeek];
  if (!todayHours?.isOpen || !todayHours?.open || !todayHours?.close) return false;
  
  const currentTime = zonedDate.toLocaleTimeString('en-US', { 
    hour12: false, 
    hour: '2-digit', 
    minute: '2-digit' 
  });
  
  return currentTime >= todayHours.open && currentTime <= todayHours.close;
};

/**
 * Format store address for display
 * @param {string} address - Full address string
 * @returns {string} Formatted address (City, State)
 */
export const formatStoreAddress = (address) => {
  if (!address) return '';
  const parts = address.split(',');
  if (parts.length >= 2) {
    return `${parts[1].trim()}, ${parts[2].trim()}`;
  }
  return address;
};
