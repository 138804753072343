import { useState, useEffect, useCallback } from 'react';
import { collection, query, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

export function useOrders(userId) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderRefs, setOrderRefs] = useState(new Map()); // Store order references

  // Function to manually add a new order to state
  const addOrder = useCallback((orderId, orderData) => {
    // If we already have this order, don't add it again
    if (orders.some(order => order.id === orderId)) return;
    
    // Add the new order to the beginning of the list
    setOrders(prevOrders => [{
      id: orderId,
      ...orderData
    }, ...prevOrders]);
  }, [orders]);

  useEffect(() => {
    if (!userId) {
      setOrders([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    
    // Listen to the user's orders subcollection
    const userOrdersRef = collection(db, 'users', userId, 'orders');
    const ordersQuery = query(userOrdersRef);
    
    // Store unsubscribe functions
    const unsubscribes = [];

    // Listen to user's order references
    const unsubscribeRefs = onSnapshot(
      ordersQuery,
      async (snapshot) => {
        try {
          
          // Clear previous order listeners
          unsubscribes.forEach(unsub => unsub());
          unsubscribes.length = 0;

          // Create a map to store order data
          const orderMap = new Map();
          
          // Set up listeners for each order
          snapshot.docs.forEach(docSnap => {
            const orderId = docSnap.data().orderId;
            
            const orderRef = doc(db, 'orders', orderId);
            const unsubscribeOrder = onSnapshot(
              orderRef,
              (orderDoc) => {
                if (orderDoc.exists()) {                  const orderData = orderDoc.data();
                  orderMap.set(orderId, {
                    id: orderDoc.id,
                    ...orderData
                  });
                  
                  // Convert map to array and sort
                  const orderArray = Array.from(orderMap.values());
                  orderArray.sort((a, b) => new Date(b.orderTime) - new Date(a.orderTime));
                  
                  setOrders(orderArray);
                } else {
                  orderMap.delete(orderId);
                }
              },
              (err) => {
                console.error('Error listening to order:', orderId, err);
              }
            );
            
            unsubscribes.push(unsubscribeOrder);
          });

          setLoading(false);
        } catch (err) {
          console.error('Error processing orders:', err);
          setError(err);
          setLoading(false);
        }
      },
      (err) => {
        console.error('Error in order refs listener:', err);
        setError(err);
        setLoading(false);
      }
    );

    // Cleanup function
    return () => {
      unsubscribeRefs();
      unsubscribes.forEach(unsub => unsub());
    };
  }, [userId]);

  return { orders, loading, error, addOrder };
}
